import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/timeout'
import { whereBus, busLocation, lineLocation, routeStation, routePrice, randomData, RouteListByStopId, RouteScheduleByStop, ScheduleRouteAndStopParam, ScheduleRouteAndStopSequence, getStartPopup } from '../shared/classes/wherebus';
import { parameters } from '../shared/classes/Howtogo';
import { smartStations } from '../shared/classes/smartstop';

@Injectable({
  providedIn: 'root'
})
export class WhereMyBusService {
  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetRouteCodes() : Observable<whereBus> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var value = {"keyword": ""};
    return this.httpClient.post<whereBus>(this.localUrl + "static/route", value, { headers: headers });
  } 

  GetStartPupup(city_url: string): Observable<getStartPopup>{
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var value = {"packageName": city_url, "clientType":1};
    return this.httpClient.post<getStartPopup>(this.localUrl + "definition/getstartpopup", value, { headers: headers });
  } 

  GetRandomLineAndStops(param:parameters): Observable<randomData>{
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.httpClient.post<randomData>(this.localUrl + "static/activestations", param,{ headers: headers });
  }

  GetLikeLineAndStops(searchValue: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
       return this.httpClient.post<randomData>(this.localUrl + "static/routeandstation", {"keyword": searchValue}, { headers: headers });
  }

  GetLocations(routeCode: string) : Observable<busLocation> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<busLocation>(this.localUrl + "static/realtimedata", {"routeCode": routeCode}, { headers: headers });
  }

  GetRouteLocations(routeCode: string) : Observable<lineLocation> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<lineLocation>(this.localUrl + "static/routecoordinate", {"routeCode": routeCode.toString()}, { headers: headers });
  }

  GetRouteStops(routeCode: number) : Observable<routeStation> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<routeStation>(this.localUrl + "static/routestat", {"routeCode": routeCode},{ headers: headers });
  }

  GetRoutePrice(routeCode: string) : Observable<routePrice> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<routePrice>(this.localUrl + "static/routeprice", {"routeCode": routeCode}, { headers: headers });
  }

  GetSmartStationData(StopId: number) : Observable<smartStations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<smartStations>(this.localUrl + "static/stationremainingtime", {"stopId": StopId}, { headers: headers });
  }

  GetRouteListByStopId(StopId: number) : Observable<RouteListByStopId> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<RouteListByStopId>(this.localUrl + "static/RouteByStop", {"keyword": StopId}, { headers: headers });
  }

  GetRouteScheduleByStop(StopId: number, WeekDay: number) : Observable<RouteScheduleByStop> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<RouteScheduleByStop>(this.localUrl + "static/ScheduleByRoute", {"stopId": StopId, "weekDay": WeekDay}, { headers: headers });
  }

  GetScheduleRouteAndStopSequence(param: ScheduleRouteAndStopParam) : Observable<ScheduleRouteAndStopSequence> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<ScheduleRouteAndStopSequence>(this.localUrl + "static/schedulebystop", param,{ headers: headers });
  }
}
