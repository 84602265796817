import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { socialLoginResult } from 'src/app/shared/classes/SocialLogin';
import { activationResult } from 'src/app/shared/classes/Activation';
// import { ToasterService } from 'src/app/services/toaster.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent implements OnInit {

  token: string;
  activationResult: string;

  constructor(private route: ActivatedRoute, private authService: AuthServices, private loginService: LoginService,
             private router: Router) { }

  ngOnInit() {
    this.authService.changeMenu();
    this.route.queryParams
      .filter(params => params.token)
      .subscribe(params => {
        this.token = params.token;
      });
  }

  clickActivated() {
        this.loginService.ActivationRequest(this.token).subscribe((result) => {
          this.messageControl(result.message)
        });
  }

  messageControl(message: string) {
    if (message == "Success!") {
      Swal.fire('Başarılı', 'Aktivasyon İşlemi Başarılı.', 'success')
    }
    else if (message == "Activation not Success!") {
      Swal.fire('Başarısız', 'Aktivasyon işlemi başarısız.', 'error')
    }
    else if (message == "Error!") {
      Swal.fire('Başarısız', 'Aktivasyon işlemi başarısız.', 'error')
    }
    this.router.navigate(['/wheremybus']);
  }
}
