import { DatePipe } from '@angular/common';
export class Utilities {

    /**
     *
     */
    constructor() {
    }

    msToTime(ms) {

        var date = new Date(ms);
        var h = date.getHours().toString();
        h = ("0" + h).slice(-2);

        var m = date.getMinutes().toString();
        m = ("0" + m).slice(-2);

        return h + ":" + m;
    }

    splitCoordinateLat(coordinate) {
        var splitCoordinate = coordinate.split(',');
        var lat = splitCoordinate[0];
        var lot = splitCoordinate[1];
        return lat;
    }

    splitCoordinateLon(coordinate) {
        var splitCoordinate = coordinate.split(',');
        var lon = splitCoordinate[1];
        return lon;
    }

    splitDistance(distance) {
        var splitDistance = distance.split('1');
        var distanceMeter = splitDistance[0];
        return distanceMeter;
    }

    splitDistance2(distance: string){
        var splitDistance = distance.toString().split('.');
        var distanceMeter = splitDistance[0];
        return distanceMeter;
    }

    PriceFormat(price) {
        var splitPrice = price.toString().split('.');
        if (splitPrice[1]) {
            if (splitPrice[1].length > 2) {
                var newPrice = splitPrice[0] + "." + splitPrice[1].substring(0, 2);
                return newPrice;
            }
            else {
                return price;
            }
        }
    }

    replacePrice(price) {
        // if (price == '0') {
        //     return "Ücretsiz";
        // }
        // else {
        //     price == ''
        //     var re = /\./gi;
        //     var str = price;
        //     price = str.toString().replace(re, ",");
        //     var test = price.toString().split(",");
        //     if (price.length == 1) {
        //      price = price + ",00";
        //     }
        //     else {
        //         if (test[1].length == 1) {
        //             price = test[0] + "," + test[1] + "0";
        //         }
        //         else if(test[1].length == 2){
        //             price = price;
        //         }
        //     }
        //     return price + " ₺";
        // }
        var currency_symbol = "₺"

        var formattedOutput = new Intl.NumberFormat('tr-TR', {
            currency: 'TRY',
            minimumFractionDigits: 2,
        });
        return formattedOutput.format(price) + " ₺";
    }

    replaceAmount(price: string) {
        price == ''
        var re = /\./gi;
        var str = price;
        price = str.toString().replace(re, ",");
        return price;

    }

    replaceRouteName(routeName) {
        routeName == ''
        var re = /\Dönüş/gi;
        var str = routeName;
        routeName = str.toString().replace(re, "");
        var re = /\Gidiş/gi;
        var str = routeName;
        routeName = str.toString().replace(re, "");
        return routeName;
    }

    replaceDate(dateTime) {
        dateTime == ''
        var re = /\T/gi;
        var str = dateTime;
        dateTime = str.toString().replace(re, " ");
        return dateTime;
    }

    removeSpecialCharacters(characters: string) {
        for (var i = 0; i < characters.length; i++) {
            characters = characters.replace("-", "");
        }
        return characters;
    }

    replaceCoordinate(coordinate: string) {
        coordinate = coordinate.replace(",", ".");
        return coordinate;
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }
    
      getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
}