import { Component, OnInit } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { forgotPassValue, forgotPass } from 'src/app/shared/classes/ForgotPassword';
import { LoginService } from 'src/app/services/login.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute, private authService: AuthServices, private loginService: LoginService,
               private router: Router) { }

  frgtPass: Observable<forgotPass>;
  token: string;

  ngOnInit() {
    this.authService.changeMenu();
    this.route.queryParams
      .filter(params => params.token)
      .subscribe(params => {
        this.token = params.token;
      });
  }

  clickActivated() {
      
        this.loginService.ForgetPassword(this.token).subscribe((result) => {
          this.messageControl(result.message)
        });
     
  }

  messageControl(message: string) {
    if (message == "Success") {
      this.router.navigate(['/wheremybus']);
      Swal.fire('Başarılı', 'Şifreniz mail adresinize gönderilmiştir.', 'success')
      // this.toaster.show('success', 'Başarılı', 'Şifreniz mail adresinize gönderilmiştir');
    }
    else if (message == "Token Not Found") {
      this.router.navigate(['/wheremybus']);
      Swal.fire('Başarısız', 'Aktivasyon işlemi başarısız.', 'error')
    }
    else if (message == "Error!") {
      this.router.navigate(['/wheremybus']);
      Swal.fire('Başarısız', 'Aktivasyon işlemi başarısız.', 'error')
    }
  }
}
