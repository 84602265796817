import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { KeyValue } from 'src/app/shared/classes/KeyValue';
import { HowtoGoService } from 'src/app/services/howtogo.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
// import { ToasterService } from 'src/app/services/toaster.service';
import { PoiZoneTypesResult } from 'src/app/shared/classes/PoiZoneTypes';
import { type } from 'os';
import { timeInterval } from 'rxjs/operators';

@Component({
  selector: 'app-select-with-search-option',
  templateUrl: './select-with-search-option.component.html',
  styleUrls: ['./select-with-search-option.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class SelectWithSearchOptionComponent implements OnInit {

  @ViewChild('selectElem') el: ElementRef;
  @Input() data: KeyValue[];
  @Input() IconData: KeyValue[];
  @Input() selectCoordinate: string;
  @Input() placeholder: string;
  @Input() componentType: number;
  @Input() change: boolean;
  @Output() selected = new EventEmitter<KeyValue>();
  @Output() changed = new EventEmitter<string>();
  @Output() selectedStation = new EventEmitter<KeyValue>();
  @Output() onSearchChange = new EventEmitter<string>();
  @Output() deleteMarker = new EventEmitter<number>();


  stops: Observable<KeyValue[]>

  isSelected: boolean = false;
  selectedKey: string;
  startCoord: string;
  endCoord: string;
  zoneTypes: PoiZoneTypesResult[];
  path: string;
  query;

  constructor(@Inject(JQ_TOKEN) private $: any, private authService: AuthServices, private howtogoService: HowtoGoService, private _eref: ElementRef) { }

  ngOnInit() {
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)){
      this.$(this.el.nativeElement).find(".buslist").css("display", "none");
    }
  }

  searchChange(searchValue: string): void {
    this.onSearchChange.emit(searchValue);
  }

  onSelected(item: KeyValue) {
    this.isSelected = true;
    this.selectedKey = item.Key;
    this.selectCoordinate = item.Value;
    this.searchSelectClose(item.Value);
    this.selected.emit(item);
    this.selectedStation.emit(item);
  }

  searchSelectOpen() {
    this.$(this.el.nativeElement).find(".buslist").css("display", "block");
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".side-bar").css("height","60%");  
    } 
  }

  clickChange() {
    this.changed.emit();
  }

  unselect() {
    this.isSelected = false;
    this.$(this.el.nativeElement).find(".selectbox").val('');
    this.$(this.el.nativeElement).find(".selectbox").text('');
    this.deleteMarker.emit(this.componentType);
  }

  searchSelectClose(text: string) {
    this.$(this.el.nativeElement).find(".buslist").css("display", "none");
    this.$(this.el.nativeElement).find("input.dropdown").val(text);
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".side-bar").css("height","35%");
    }
  }

}
