export class createUser {
    version: number;
    statusCode: string;
    message: string;
    result: createUserResult[]
}

export class createUserResult {
  userId: number;
}

export class createUserValue {
    // tcKimlikNo : string;
    userCode: string;
    firstName: string;
    secondName: string;
    lastName: string;
    pass: string;
    email: string;
    mobile: string;
    mifareID: string;
    passRe: string;
}