import { DecimalPipe } from '@angular/common';

export class whereBus {
    version: number;
    statusCode: string;
    message: string;
    result: whereBusResult[]
}

export class getStartPopup {
    version: number;
    statusCode: string;
    message: string;
    result: startPopUpResult = {
        id:0,
        packageName: '',
        cityId: 0,
        clientType: 0,
        clientName: '',
        title: '',
        message: '',
        url: '',
        imageUrl: '',
        urlButtonText: ''
    }
}

export class startPopUpResult {
    id: number;
    packageName: string;
    cityId: number;
    clientType: number;
    clientName: string;
    title: string;
    message: string;
    url: string;
    imageUrl: string;
    urlButtonText: string;
}
export class whereBusResult {
    type: string;
    kod: string;
    aciklama: string;
    hatNo: number;
    stationId: number;
    stationName: string;
    longitude: number;
    latitude: number;
}

export class busLocation {
    version: number;
    statusCode: string;
    message: string;
    result: busLocationResult[]
}

export class busLocationResult {
    state: number;
    plaka: string;
    enlem: number;
    boylam: number;
    renk: string;
    hiz: number;
    mesafe: number;
    surucu: string;
    gunlukYolcu: number;
    seferYolcu: number;
    durakYolcu: number;
    maxHiz: number;
    yon: number;
    editDate: string;
    ImageUrl: string;
    klimaVarMi: number;
    engelliUygunMu: number;
    Hatkodu: string;
    validatorNo: number;
    istikamet: null;
}

export class lineLocation {
    version: number;
    statusCode: string;
    message: string;
    result: lineLocationResult[]
}

export class lineLocationResult {
    latitude: string;
    logitude: string;
    sequence: number;
    route: string;
    routeDirection: string;
}

export class ForwardData{
    latitude: string;
    logitude: string;
    sequence: number;
    route: string;
    routeDirection: string;   
}

export class BackwardData{
    latitude: string;
    logitude: string;
    sequence: number;
    route: string;
    routedirection: string;   
}

export class ForwardStation{
    latitude: string;
    logitude: string;
    sequence: number;
    stopName: string;
    stopId: number;
}

export class BackwardStation{
    latitude: string;
    logitude: string;
    sequence: number;
    stopname: string;
}

export class routeStation {
    version: number;
    statusCode: string;
    message: string;
    result: routeStationResult[]
}

export class routeStationResult{
    stopId: number;
    stopName: string;
    sequence: number;
    latitude: string;
    longitude: string;
    direction: string;
}

export class routePrice{
    version: number;
    statusCode: string;
    message: string;
    result: routePriceresult[];
}

export class routePriceresult{
    routeCode: string;
    description: string;
    cardType: string;
    price: DecimalPipe;
}

export class randomData {
    version: number;
    statusCode: string;
    message: string;
    result: randomDataResult[]
}

export class randomDataResult {
   type: string;
   kod: string;
   aciklama: string;
   hatNo: number;
   stationId: number;
   stationName: string;
   longitude: number;
   latitude: number;
}

// export class smartStation {
//     version: number;
//     statusCode: string;
//     message: string;
//     result: smartStationResultData[]
// }

// export class smartStationResultData{
//     // BusLineCode: string;
//     // BusLineNo: number;
//     // BusLineShortName: number;
//     // PanelId: number;
//     // RemainingTimeCurr: number;
//     // RemainingTimeNext: number;
//     // IsAccordingToTimeSchedule: string;
//     // BusStatusCurr: number;
//     // BusStatusNext: number;
//     routeId: number;
//     routeCode: string;
//     routeTitle: string;
//     passTime: string;
//     realTime: string;
// }

export class ActiveStationsRandom{
    version: number;
    statusCode: string;
    message: string;
    result: ActiveStationsRandomresult[]
}

export class ActiveStationsRandomresult{
    stationId: number;
    description: string;
    stationType: number;
    altitude: string;
    isActive: number;
    latitude: string;
    longitude: string;
}

export class RouteListByStopId{
    version: number;
    statusCode: string;
    message: string;
    result: RouteListByStopIdResult[]; 
}

export class RouteListByStopIdResult{
    routeNo: number;
    routeCode: string;
    routeName: string;
}

export class RouteScheduleByStop{
    version: number;
    statusCode: string;
    message: string;
    result: RouteScheduleByStopResult[]; 
}

export class RouteScheduleByStopResult{
    routeId: number;
    routeCode: string;
    stopTime: string;
    information: string;
}

export class ScheduleRouteAndStopParam{
    routeId: number;
    stopSequenceNo: number;
    direction: string;
    weekday: number;
}

export class ScheduleRouteAndStopSequence{
    version: number;
    statusCode: string;
    message: string;
    result: ScheduleRouteAndStopSequenceResult[]; 
}

export class ScheduleRouteAndStopSequenceResult{
    RouteId: number;
    RouteCode: string;
    RouteDay: number;
    StopTime: string;
}

export class Test{
    RouteId: number;
    RouteCode: string;
    RouteDay: number;
    StopTime: string;
    day: number;
}

export class MapDirective{
    directive:string;
    directiveNeeds: any;
}

