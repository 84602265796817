import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';
import { PostToken, Token, BankInterfaceToken } from '../shared/auth';
import { Observable } from 'rxjs';
import { BankInformation, BankId, BankResponse } from '../shared/classes/UserPayment';
import { StartTopupOrderParam, StartTopupOrder, StartTopupOrderParamDifferentCard, CommissionResult, TopupCommission } from '../shared/classes/CardOperations';

@Injectable({
  providedIn: 'root'
})
export class UserpaymentService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }


  GetBankService(parameter: string): Observable<BankResponse> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<BankResponse>(this.localUrl + "payment/bankservice", {"parameters": parameter.toString()}, { headers: headers });
  }

  GetStartTopupOrder(parameter: StartTopupOrderParam): Observable<StartTopupOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartTopupOrder>(this.localUrl + "card/getstarttopuporder", parameter, { headers: headers });
  }

  GetStartTopupOrderDifferentCard(parameter: StartTopupOrderParamDifferentCard): Observable<StartTopupOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartTopupOrder>(this.localUrl + "card/getstarttopuporderguest", parameter, { headers: headers });
  }

  GetCommission(): Observable<TopupCommission> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var value = {"keyword": ""};
    return this.httpClient.post<TopupCommission>(this.localUrl + "payment/commission", value, { headers: headers });
  }
}
