import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { social, SocialLoginModel, socialLoginResult, socialLogin } from '../shared/classes/SocialLogin';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string) { }

  // GetSocial(response: Token): Observable<social> {
  //   const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + response.access_token });
  //   return this.httpClient.post<social>(this.url + "SocialMedia", null, { headers: headers });
  // }

  // SocialLogin(response: Token, user: SocialLoginModel): Observable<socialLogin>{
  //   const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + response.access_token });
  //   return this.httpClient.post<socialLogin>(this.url + "Login", user, { headers: headers });
  // }
}