export class cardOperations {
    version: number;
    statusCode: string;
    message: string;
    result: cardOperationsResult
}

export class cardOperationsResult {
    cards: cardsResult[]
}

export class cardsResult {
    mifareId: string;
    name: string;
    cardType: string;
    currentAmount: number;
    registrationDate: any;
    expireDate: any;
    waitingAmount: number;
    waitingAmountDate: any;
    topUpEligibility: boolean;
    isFavorite: boolean;
    subscribeCardBalance: number;
    transactions: transactionResult
}

export class transactionResult {
    deduction: deductionResult[]
    topup: topupResult[]
}

export class List {
    transactionType: string;
    transactionPoint: string;
    date: Date;
    amount: number;
}

export class deductionResult {
    transactionPoint: string;
    date: any;
    amount: number;
}

export class topupResult {
    transactionPoint: string;
    date: any;
    amount: number;
}

export class cardAdd {
    version: number;
    statusCode: string;
    message: string;
    result: cardAddResult
}

export class cardAddResult {
    status: number;
    result: string;
    resultStatu: string;
}

export class addFavoriteCard {
    version: number;
    statusCode: string;
    message: string;
    result: addFavoriteResult
}

export class addFavoriteResult {
    userId: number;
    cardId: string;
    cardAlias: string
}

export class favoriteCard {
    version: number;
    statusCode: string;
    message: string;
    result: FavoriteResult
}

export class FavoriteResult {
    userId: number;
    cardId: string;
    cardAlias: string;
    amount: number;
    isLowBalance: boolean;
}

export class bankResponse {
    version: number;
    statusCode: string;
    message: string;
    result: bankResult
}

export class bankResult {
    resultCode: string;
    responseMessage: string;
}

export class StartTopupOrderParam {
    mifareId: string;
    amount: number;
    comission: number;
    sessionId: number;
    clientType: number;
    maskedCardNumber: string;
    mpMacroMerchantId: string;
    sessionTicket: string;
}

export class StartTopupOrderParamDifferentCard {
    // userId: string;
    nameSurname: string;
    phone:string;
    email: string;
    mifareId: string;
    amount: number;
    comission: number;
    sessionId: number;
    clientType: number;
    maskedCardNumber: string;
    mpMacroMerchantId: string;
    channelCd: string;
}

export class StartTopupOrder{
    version: number;
    statusCode: string;
    message: string;
    result: StartTopupOrderResult
}

export class StartTopupOrderResult{
    orderNumber: string;
    recId: number;
}

export class CheckVisaOrderDetail{
    version: number;
    statusCode: string;
    message: string;
    result: CheckVisaOrderDetailResult
}

export class CheckVisaOrderDetailResult{
    orderNo: string;
    cardDefinition: string;
    visaEndDate: any;
    visaNewDate: any;
    visaFee: number;
}

export class cardInfo {
    version: number;
    statusCode: string;
    message: string;
    result: cardInfoResult
}

export class cardInfoResult{
    kartTipi: number;
    kartTipiAciklama: string;
    uretimTarihi: any;
    sonIslemTarihi: any;
    guncelBakiye: number;
    adi: string;
    soyadi: string;
    gecerlilikBaslangicTarihi: any;
    gecerlilikBitisTarihi: any;
    kartDurumu: string;
    // tcKimlikNo: number;
    toplamBinisHakki: number;
    binisAdedi: number;
    kalanBinisHakki: number;
    topUpEligibility: boolean;
}

export class TopupCommission{
    version: number;
    statusCode: string;
    message: string;
    result: CommissionResult[]
}

export class CommissionResult{
    commission: number;
    minAmount: number;
    maxAmount: number;
}

export class SelectCityBankId{
    version: number;
    statusCode: string;
    message: string;
    result: SelectCityBankIdResult
}

export class SelectCityBankIdResult{
    bankName: string;
    macroMerchantId: string;
}

export class PaymetTransactionParameters{
    orderNo: string;
    cardNo: string;
    cardMonth: string;
    cardYear: string;
    cvv: string;
}

export class PaymentTransactionWeb{
    version: number;
    statusCode: string;
    message: string;
    result: PaymentTransactionWebResult
}

export class PaymentTransactionWebResult{
    postForm: string;
}

export class CommitVisaOrderParameters{
    orderNumber: string;
    paymentResult: boolean;
    provisionId: string;
    authCode: string;
    bankResultID: string;
    bankResult: string;
    sessionId: number;
    maskedCardNumber: string;
}

export class CommitVisaOrder{
    version: number;
    statusCode: string;
    message: string;
    result: boolean;
}

export class checkVisaParameters{
    mifareId: string;
    channel: string;
}