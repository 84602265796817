import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { FillingCenter } from 'src/app/shared/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MapsAPILoader } from '@agm/core';
import { Utilities } from 'src/app/shared/utitlities';
import { Router } from '@angular/router';
import { map } from 'rxjs-compat/operator/map';
declare var google;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges {
  title: string = 'Asis Map';
  lat: number;
  lng: number;
  zoom: number = 14;
  icon: string = '/assets/img/logo/house.png';
  openedWindow: number;
  postData: string[];
  currentLat: any;
  currentLng: any;
  markers = [];
  filteredMarkers = [];
  distance: number;
  distanceInM: number;
  enlemFStr;
  boylamFStr;

  origin = { lat: 29.8174782, lng: -95.6814757 }
  destination = { lat: 40.6976637, lng: -74.119764 }
  waypoints = [
     {location: { lat: 39.0921167, lng: -94.8559005 }},
     {location: { lat: 41.8339037, lng: -87.8720468 }}
  ]

  private utilities: Utilities;

  constructor(private httpService: HttpClient, private mapsAPILoader: MapsAPILoader, private router: Router) {
    this.utilities = new Utilities();
  }

  @Input() fillingcenter: FillingCenter;
  @Input() selectedCenter: any;

  ngOnInit() {
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.postData = data as string[];
        this.lat = this.postData[0]['lat'];
        this.lng = this.postData[0]['lng'];
      });
    navigator.geolocation.getCurrentPosition(a => {
      this.currentLat = a.coords.latitude;
      this.currentLng = a.coords.longitude;
    }, error => {
      this.currentLat = 0;
      this.currentLng = 0;
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    })
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.selectedCenter) {
      if (changes.selectedCenter.currentValue) {
        this.centeralize(changes.selectedCenter);
      }
    }
  }

  selectIcon(value){
    if(value == "B"){
      return '/assets/img/logo/card-shop-blue_pin_dealer.png';
    }
    else if(value == "K"){
       return '/assets/img/logo/KartMerkezleri.png';
    }
    else if(value == "V"){
      return '/assets/img/logo/card-shop-blue_pin_kiosk.png';
    }
    // else if(value == "S"){
    //   return '/assets/img/logo/KartMerkezleri.png';
    // }
  }
  

  centeralize(currentItem) {
    navigator.geolocation.getCurrentPosition(a => {
      this.zoom = 18;
      this.lng = +currentItem.currentValue.boylamFStr;
      this.lat = +currentItem.currentValue.enlemFStr;
    }, error => {
      this.zoom = 18;
      this.lng = +currentItem.currentValue.boylamFStr;
      this.lat = +currentItem.currentValue.enlemFStr;
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    })
  }

  openWindow(servisId, enlemFStr, boylamFStr) {
    this.zoom = 11;
    this.enlemFStr = enlemFStr;
    this.boylamFStr = boylamFStr;
    if (this.currentLat != 0) {
      this.markers = this.getLocations();
      this.mapsAPILoader.load().then(() => {
        const center = new google.maps.LatLng(enlemFStr, boylamFStr);
        this.filteredMarkers = this.markers.filter(m => {
          const markerLoc = new google.maps.LatLng(m.latitude, m.longitude);
          this.distanceInM = google.maps.geometry.spherical.computeDistanceBetween(markerLoc, center) / 1000;
          this.distance = this.yuvarla(this.distanceInM,2);
        });
      });
      this.openedWindow = servisId;
    }
    else {
      this.distance = 0;
      this.openedWindow = servisId;
    }
  }

  yuvarla(sayi,basamak)
    {
        basamak=Math.pow(10,basamak);
        return Math.round(sayi*basamak)/basamak;  
    }

  getLocations() {
    return [
      { 'latitude': this.currentLat, 'longitude': this.currentLng }
    ];
  }

  routePayment() {
    window.open("https://www.google.com.tr/maps/dir/"+ this.currentLat + ", " + this.currentLng + "/" + this.enlemFStr + ", " + this.boylamFStr, "_blank");
    // this.router.navigate(['/cardoperations/cardLoad']);
  }

  isInfoWindowOpen(servisId) {
    return this.openedWindow == servisId;
  }
}
