export class updateUserProfile{
    version: number;
    statusCode: number;
    message: string;
    result: updateUserProfileResult;
}

export class updateUserProfileResult{
    updateResult: number;
    decription: string;
}

export class updateUserRequest{
    userId: number;
    identity: string;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    sessionTicket: string;
}

export class updatePassword{
    version: number;
    statusCode: number;
    message: string;
    result: updatePasswordResult; 
}

export class updatePasswordResult{
    result: number;
}

