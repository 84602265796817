export class Token {
    access_token: string;
    token_type: string;
    expires_in: number;
}

export class BankInterfaceToken {
    accessToken: string;
    expiresIn: number;
}

export class PostToken {
    userName: string;
    password: string;
    grant_type: string;
    cityCode:string;
}

export class FillingCenter2 {
    version: number;
    statusCode: number;
    message: string;
    result: FillingCenter[]
}

export class FillingCenter {
    tip: string;
    bayiT2Kodu: string;
    enlemFStr: number;
    boylamFStr: number;
    aciklama: string;
    adres: string;
    telefon: string;
    servisId:number;
    path: string;
}

export class FillingCenterParameters{
    enlem: number;
    boylam: number;
}