import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable, from } from 'rxjs';
import {masterPassControl} from '../shared/classes/MasterPass';

import {StartTopupOrderCard,InputToken,StartTopupOrder,StartTopupOrderParam} from '../shared/classes/MasterPassCardOperation';

@Injectable({
  providedIn: 'root'
})
export class MasterPassService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string) { }

  // CheckMasterPassByCardNo(response: Token, parameter: InputToken) : Observable<masterPassControl> {
  //   const headers = new HttpHeaders({ 'Authorization': 'Bearer '+ response.access_token });
  //   return this.httpClient.post<masterPassControl>(this.url + "", parameter,{ headers: headers });
  // }

  // GetStartMasterPassOrderGuest(response: Token, parameter: StartTopupOrderCard): Observable<StartTopupOrder> {
  //   const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + response.access_token });
  //   return this.httpClient.post<StartTopupOrder>(this.url + "", parameter, { headers: headers });
  // }

  // GetStartMasterPassOrder(response: Token, parameter: StartTopupOrderParam): Observable<StartTopupOrder> {
  //   const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + response.access_token });
  //   return this.httpClient.post<StartTopupOrder>(this.url + "", parameter, { headers: headers });
  // }
}
