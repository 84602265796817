import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FillingcenterComponent } from './components/fillingcenter/fillingcenter.component';
import { SmartstopComponent } from './components/smartstop/smartstop.component';
import { WheremybusComponent } from './components/wheremybus/wheremybus.component';
import { HowtogoComponent } from './components/howtogo/howtogo.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { CreateuserComponent } from './components/createuser/createuser.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CardoperationsComponent } from './components/cardoperations/cardoperations.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LostpropertyComponent } from './components/lostproperty/lostproperty.component';
import { CardvisaComponent } from './components/cardvisa/cardvisa.component';
import { QrCodeComponent } from './components/qrcode/qrcode.component';
import { ActivationComponent } from './components/activation/activation.component';
import { SuccessurlComponent } from './components/successurl/successurl.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CarparkService } from './services/carpark.service';
import { GuestpaymentComponent } from './components/guestpayment/guestpayment.component';


const routes: Routes = [
  { path: '', redirectTo: 'wheremybus', pathMatch: 'full' },
  { path: 'howtogo', component: HowtogoComponent },
  { path: 'fillingcenter', component: FillingcenterComponent},
  { path: 'login/:params', component: LoginComponent },
  { path: 'createuser', component: CreateuserComponent },
  { path: 'ForgetPassword', component: ForgotpasswordComponent },
  { path: 'smartstop', component: SmartstopComponent },
  { path: 'wheremybus', component: WheremybusComponent },
  // { path: 'cardVisa', component: CardvisaComponent },
  { path: 'QrCode', component: QrCodeComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'cardoperations/:params', component: CardoperationsComponent},
  { path: 'lostproperty', component: LostpropertyComponent },
  { path: 'Activation', component: ActivationComponent },
  { path: 'SuccessUrl', component: SuccessurlComponent },
  { path: 'GuestPayment', component: GuestpaymentComponent },
  { path: 'settings', component: SettingsComponent , canActivate: [AuthGuardService]},
  // { path: 'CarPark', component: CarparkComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
