export class lineHours {
    version: number;
    statusCode: string;
    message: string;
    result: hoursResult[]
}

export class hoursResult {
    sequenceNumber: number;
    stationName: string;
    routeCode: string;
    time: any;
    plannedStationIn: any;
    hour: number;
    minute: number;
    direction: string;
    ring: boolean;
}

export class lineHoursParams {
    routeCode: number;
    dayType: number;
    isFirstStations: boolean;
    direction: string;
    hour: string;
}

export class TimesResult{
    constructor() {
        this.hourData = new Array();
    }
    hour: string;
    hourData: hourDataResult[];
}

export default class hourDataResult{
    sequenceNumber: number;
    stationName: string;
    routeCode: string;
    time: any;
    plannedStationIn: any;
    direction: string;
    ring: boolean;
}