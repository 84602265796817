import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { LoginComponent } from 'src/app/components/login/login.component';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'angular5-social-login';
import { CardoperationsService } from 'src/app/services/cardoperations.service';
import { CardoperationsComponent } from '../cardoperations/cardoperations.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(@Inject(JQ_TOKEN) private $: any, private modalService: NgbModal, @Inject(SESSION_STORAGE) private storage: WebStorageService, public authService: AuthServices,
    private router: Router, private cardOperationsService: CardoperationsService, private httpService: HttpClient,
    private socialAuthService: AuthService) { }
  public data: any = [];
  userName: string;
  jsonData: string[];
  logoHref: string;
  logoUrl: string;
  whereMyBus: string;
  cardOperations: string;
  login: string;
  balanceLoading: string;
  cardVisa: string;
  qrCode: string;
  howtogo: string;
  lostproperty: string;
  balanceinquiryVan: string;
  public authorized: boolean = false;
  balance: any;
  isLowBalance: boolean;
  userId: any;
  cityName: string;
  hatay: boolean = false;
  elazig: boolean = false;
  kutahya: boolean = false;
  van: boolean = false;
  sakarya: boolean = false;

  @ViewChild(CardoperationsComponent) cardOperation: CardoperationsComponent;

  ngOnInit() {
    // this.getCardInfo();
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.jsonData = data as string[];
        this.logoHref = this.jsonData[0]['logoHref'];
        this.logoUrl = this.jsonData[0]['logoUrl'];
        this.howtogo = this.jsonData[1]['Nasıl Giderim'];
        this.whereMyBus = this.jsonData[1]['Otobüsüm Nerede'];
        this.cardOperations = this.jsonData[1]['Kart İşlemleri'];
        this.balanceLoading = this.jsonData[1]['Bakiye Yükleme'];
        this.balanceinquiryVan = this.jsonData[1]['Bakiye Sorgulama'];
        this.qrCode = this.jsonData[1]['Qr Code İşlemleri'];
        this.cardVisa = this.jsonData[1]['Kart Vizeleme'];
        this.login = this.jsonData[1]["Giriş Yap"];
        this.cityName = this.jsonData[0]['cityName'];


        if (this.cityName == "Hatay") {
          this.hatay = true;
        }
        else if(this.cityName == "Elazığ"){
          this.elazig = true;
        }
        else if(this.cityName == "Kütahya"){
          this.kutahya = true;
        }
        else if(this.cityName == "Van"){
          this.van = true;
        }
        else if(this.cityName == "Sakarya"){
          this.sakarya = true;
        }
      });
    let Id = this.getFromLocal("userId");
    this.userId != null ? this.userId = Id : this.userId = 0;
    this.$(".card-operations-back").css("display", "none");
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  open() {
    const modalRef = this.modalService.open(LoginComponent);
  }

  logout() {
    this.authService.changeMenu();
    Swal.fire({
      title: 'Uyarı',
      text: 'Çıkış yapmak istediğinize emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'İptal',
      confirmButtonText: 'Evet'
    }).then((result) => {
      if (result.value) {
        this.saveInLocal("userMobile", "");
        this.saveInLocal("userName", "");
        this.saveInLocal("userLName", "");
        this.saveInLocal("controlLogon", "");
        this.saveInLocal("statusCode", "");
        this.saveInLocal("userId", 0);
        this.saveInLocal("sessionTicket", "");
        this.socialAuthService.signOut();
        this.authorized = false;
        this.router.navigate(['/login/login']);
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })
  }

  test() {
    this.$(this).toggleClass("open"), this.$("body").toggleClass("menu-open"), this.$(".black-layer").toggleClass("active");
    this.$(document.getElementById("nav-icon2").classList.toggle('open'));
  }


  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  getFromLocal(key): void {
    return this.data[key] = this.storage.get(key);
  }

  displayMenu() {
    this.$(".mobile-menu").fadeIn("slow");
    this.$(".cancel").css("display", "block");
    this.$(".open").css("display", "none");
  }

  hideMenu() {
    this.$(".mobile-menu").fadeOut("slow");
    this.$(".open").css("display", "block");
  }

  routeLinkHideMenu() {
    // this.toaster.hide("","","");
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".navbar-nav").fadeOut("fast");
      this.$(".cancel").css("display", "none");
      this.$(".open").css("display", "block");
      this.$(".map-over").css("z-index", "11")
      this.$(".routes").css("z-index", "11")
      this.$(".route-stops").css("z-index", "11")
    }
  }

  getCardInfo() {
    this.cardOperationsService.GetFavoriteCard(this.userId).subscribe(res => {
      if (res.statusCode == "200") {
        this.saveInLocal("amount", res.result.amount + "₺");
        this.saveInLocal("isLowBalance", res.result.isLowBalance);
      }
      else {
        this.saveInLocal("amount", "");
        this.saveInLocal("isLowBalance", "");
      }
    });
  }
  selectImage(isActive, value) {
    if (isActive == false) {
      return '/assets/img/layoutLogo/' + value + '-grey.png';
    }
    else {
      return '/assets/img/layoutLogo/' + value + '.png';
    }
  }
}
