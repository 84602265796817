import { Component, OnInit, Injectable, ChangeDetectionStrategy, ViewChild, Inject, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthServices } from 'src/app/services/auth.service';
import { FillingcenterService } from 'src/app/services/fillingcenter.service';
import { PostToken, Token, FillingCenter, FillingCenter2, FillingCenterParameters } from 'src/app/shared/auth';
import { Observable, Subject } from 'rxjs';
import { MapComponent } from '../map/map.component';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
// import { ToasterService } from 'src/app/services/toaster.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Utilities } from 'src/app/shared/utitlities';
import { MapCommunicationService } from '../../services/communication-services/map-communication.service';
import { nearStationsResult } from 'src/app/shared/classes/smartstop';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-fillingcenter',
  templateUrl: './fillingcenter.component.html',
  styleUrls: ['./fillingcenter.component.css']
})


export class FillingcenterComponent implements OnInit, OnDestroy {

  fillingcenterResponse$: Observable<FillingCenter2>;
  displayFillingCenter: FillingCenter[];
  fillingCenter: FillingCenter[];
  selectedCenter: FillingCenter;
  searchText;
  currentLat: number;
  currentLng: number;
  postData: string[];
  public data: any = [];
  centerlatitude: number;
  centerlongitude: number;
  array = new Array("K", "B", "V", "S");
  query;
  jsonData: string[];
  cityCode: string;
  lat: number;
  lng: number;
  destroyed$ = new Subject();
  private utilities: Utilities;

  tokenResponse$: Observable<Token>;

  @ViewChild(MapComponent) map: MapComponent;

  constructor(
    private fillingcenterService: FillingcenterService,
    private router: Router,
    private authService: AuthServices,
    @Inject(JQ_TOKEN) private $: any,
    private httpService: HttpClient,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private spinnerService: Ng4LoadingSpinnerService,
    private mapCommunicationService: MapCommunicationService
  ) {
    this.utilities = new Utilities();
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.authService.changeMenu();
    this.spinnerService.show();
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.jsonData = data as string[];
        this.lat = this.jsonData[0]['lat'];
        this.lng = this.jsonData[0]['lng'];
        this.cityCode = this.jsonData[0]['cityCode'];
        if (this.cityCode == "31") {
          if (this.utilities.getCookie("KartBayileri") != "true") {
            Swal.fire({
              title: 'Bilgilendirme',
              text: 'Bakiye yüklemek ya da HatayKart satın almak için gitmek istediğiniz size en yakın bayileri, kart kişiselleştirme merkezlerini ve Kiosk' + "'" + 'ları görebilir ve bu noktalara ulaşabilmek için yol tarifini görebilirsiniz.',
              icon: 'warning',
              input: 'checkbox',
              inputPlaceholder: 'Bir daha gösterme',
              showCancelButton: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (result.value) {
                  this.utilities.setCookie("KartBayileri", "true", 300);
                }
              }
            })
          }
        }
        this.mapCommunicationService.addCoordinates([this.lng, this.lat])
      });

    navigator.geolocation.getCurrentPosition(a => {
      this.currentLat = a.coords.latitude;
      this.currentLng = a.coords.longitude;
      this.Register();
    }, error => {
      this.currentLat = 0;
      this.currentLng = 0;
      this.Register();
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    })
    this.styles();


    this.mapCommunicationService.getDirective()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        let center: FillingCenter;
        switch (res.directive) {
          case 'onClickStopNavigation':
            center = this.fillingCenter.find(x => x.servisId == res.directiveNeeds);
            this.Direction(center);
            break;

          default:
            break;
        }
      })


  }

  getFromLocal(key): void {
    return this.data[key] = this.storage.get(key);
  }

  Register() {
    let fillingCenterParam = new FillingCenterParameters();
    let test = new FillingCenter();
    fillingCenterParam.enlem = this.currentLat;
    fillingCenterParam.boylam = this.currentLng;
    this.fillingcenterResponse$ = this.fillingcenterService.GetAll(fillingCenterParam);
    this.fillingcenterResponse$.subscribe((request) => {
      this.displayFillingCenter = request.result;
      this.fillingCenter = request.result;
      test.enlemFStr = request.result[0]["enlemFStr"];
      test.boylamFStr = request.result[0]["boylamFStr"];
      test.servisId = request.result[0]["servisId"];

      this.showCentersOnMap();
      // this.clickCenter(test);
      if (request.result.length == 0) {
        Swal.fire('Başarısız', 'Lütfen tekrar deneyiniz.', 'error')
      }
    })
  }
  showCentersOnMap() {
    this.mapCommunicationService.addDirective({
      directive: "showCentersOnMap",
      directiveNeeds: this.displayFillingCenter
    })
  }

  clickCenter(center: FillingCenter) {
    // if (window.matchMedia('(max-width: 1024px)').matches) {
    //   this.toggleButton('map');
    // }
    // this.selectedCenter = center;
    // this.map.openWindow(center.servisId, center.enlemFStr, center.boylamFStr);


    this.mapCommunicationService.addDirective({
      directive: 'panTo',
      directiveNeeds: center
    })


  }

  clickButton(value: string) {
    if (this.array.indexOf(value) === -1) {
      this.array.push(value);
      this.displayFillingCenter = this.fillingCenter.filter(item => (item.tip == this.array[0]) || (item.tip == this.array[1]) || (item.tip == this.array[2]) || (item.tip == this.array[3]));
    }
    else {
      this.array.splice(this.array.indexOf(value, 0), 1);
      this.displayFillingCenter = this.displayFillingCenter.filter(item => (item.tip == this.array[0]) || (item.tip == this.array[1]) || (item.tip == this.array[2]) || (item.tip == this.array[3]));
    }

    this.showCentersOnMap();
    return;
  }

  routePayment() {
    this.router.navigate(['/cardoperations']);
  }

  toggleButton(val) {
    this.$(".label-list").removeClass("active")
    this.$(".label-map").removeClass("active")

    this.$(".label-" + val).addClass("active")

    if (val != "map") {
      this.$(".map-viewer").css("display", "none")
      this.$(".col-md-8").css("height", "0")
      this.$(".col-md-4").css("height", "100%")
      this.$(".filter").css("display", "none")
      this.$(".select-bar").css("display", "block")
      this.$(".route-stops").css("display", "block")
      this.$(".label-list").css("background-color", "#060606")
      this.$(".label-list").css("color", "white")
      this.$(".label-map").css("background-color", "#f5f5f5")
      this.$(".label-map").css("color", "black")
    }
    else {
      this.$(".map-viewer").css("display", "block")
      this.$(".filter").css("display", "block")
      this.$(".col-md-4").css("height", "30px")
      this.$(".col-md-8").css("height", "100%")
      this.$(".label-map").css("background-color", "#060606")
      this.$(".label-map").css("color", "white")
      this.$(".label-list").css("background-color", "#f5f5f5")
      this.$(".label-list").css("color", "black")
    }
  }

  styles() {
    if (window.matchMedia('(max-width: 1024px)').matches) {
      this.$(".map-viewer").css("margin-top", "0px");
      this.$(".map-viewer").css("height", "100%");
    }
  }

  getUserLocation() {
    return [this.currentLng, this.currentLat]
  }

  Direction(center: FillingCenter) {
    navigator.geolocation.getCurrentPosition(a => {
      this.currentLat = a.coords.latitude;
      this.currentLng = a.coords.longitude;
      window.open("https://www.google.com.tr/maps/dir/" + a.coords.latitude + ", " + a.coords.longitude + "/" + center.enlemFStr + ", " + center.boylamFStr, "_blank");
    }, error => {
      this.currentLat = 0;
      this.currentLng = 0;
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    })
  }
}
