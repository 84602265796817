import { Component, AfterViewInit, Inject, OnInit, Input } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { cardsResult, transactionResult, cardAddResult, StartTopupOrderParamDifferentCard, SelectCityBankId, PaymetTransactionParameters, PaymentTransactionWeb, checkVisaParameters } from 'src/app/shared/classes/CardOperations';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Observable } from 'rxjs';
import { CardoperationsService } from 'src/app/services/cardoperations.service';
import {
  SwiperConfigInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { DatePipe } from '@angular/common';
import { ModalService } from 'src/app/services/modal.services';
// import { ConfirmationDialogService } from 'src/app/services/confirm.service';
// import { ToasterService } from 'src/app/services/toaster.service';
import { UserpaymentService } from 'src/app/services/userpayment.service';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Utilities } from 'src/app/shared/utitlities';
import { LayoutComponent } from '../layout/layout.component';
import { balance, balanceResult } from 'src/app/shared/classes/balance';
import { BalanceService } from 'src/app/services/balance.service';
import { ThrowStmt } from '@angular/compiler';
import { visaControl, visaControlResult } from 'src/app/shared/classes/Visa';
import { VisaService } from 'src/app/services/visa.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MasterPassService } from 'src/app/services/masterpass.service';
import { StartTopupOrderParam } from 'src/app/shared/classes/CardOperations';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { HttpClient } from '@angular/common/http';
import { namespaceHTML } from '@angular/core/src/render3';
@Component({
  selector: 'app-guestpayment',
  templateUrl: './guestpayment.component.html',
  styleUrls: ['./guestpayment.component.scss']
})
export class GuestpaymentComponent implements OnInit {

  //#region properties
  public data: any = [];
  userId: any;
  sessionTicket: any;
  cardInformations: cardsResult[];
  cardAdd: cardAddResult;
  dataRefresher: any;
  newCard: boolean = false;
  addNewCardLabel: boolean = true;
  i: number = 0;
  isOpen: boolean = false;
  cardName: string;
  cardNo: string;
  favoriteCardNo: string;
  jsonData: string[];
  status: string;
  errorMessage: string;
  result: any;
  example: string;
  ACSUrl: string;
  PAReq: any;
  TermUrl: string;
  MD: string;
  merchantId: string;
  SuccesUrl: string;
  FailUrl: string;
  MerchantPassword: string;
  CurrencyStr: string;
  BrandNameStr: string;
  private utilities: Utilities;
  randomString: string;
  maxCardLenght: boolean;
  modalCardName: string;
  marked = false;
  mifareID: string;
  spinnerMessage: string;
  buttonValue;
  totalbuttonValue;
  balance: any;
  isLowBalance: boolean;
  oldMifareId: string;
  balanceResponse: Observable<balance>;
  selectCitybankId: Observable<SelectCityBankId>;
  PaymentTransaction: Observable<PaymentTransactionWeb>;
  balanceData: balanceResult;
  isTrue: boolean = false;
  url: string;
  continueButton: boolean = true;
  continueButton2: boolean = false;
  transactionList: transactionResult;
  transactions: boolean = true;
  differentCard: boolean = false;
  movements: boolean = false;
  balanceInq: boolean = false;
  middleTab: boolean = false;
  visa: boolean = false;
  myCardsVisa: boolean = false;
  creditCardInformations: boolean = false;
  creditCardInformationsVisa: boolean = false;
  creditCardInformationsVisaMyCard: boolean = false;
  isFavorite: boolean = false;
  topUpEligibility: boolean;
  type: boolean = true;
  currentBalance: string;
  differentCardName: string;
  differentCardGsm: string;
  differentCardMifareId: string;
  mifareIdLabel;
  currentBalanceLabel;
  pendingBalanceLabel;
  expireDate;
  subscribeCardBalance;
  Amount;
  back: boolean = false;
  checkVisa: visaControlResult;
  isEnabled: boolean = true;
  continueDifferenetCardVisa: boolean = false;
  visaInputMifareId: string;
  visaDescription;
  lastVisaDate;
  placeholder: string = "Toplam Tutar";
  placeholder2: string = "Vize Ücreti";
  checkBalance: boolean = true;
  index: number = 0;
  mymodel;
  commission;
  cityCode: string;
  id: string;
  selectedYear: number;
  years: number[] = [];  

  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      hideOnClick: false,
    }
  };

  //#endregion

  constructor(public datepipe: DatePipe, private httpService: HttpClient, private authService: AuthServices, private cardOperations: CardoperationsService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService, @Inject(JQ_TOKEN) private $: any,
    private router: Router, private route: ActivatedRoute, private spinnerService: Ng4LoadingSpinnerService,
    private modalService: ModalService,
    private balanceService: BalanceService, private userpaymentService: UserpaymentService,
    private ngxXml2jsonService: NgxXml2jsonService, private visaService: VisaService, private masterPassService: MasterPassService) {
    this.utilities = new Utilities();

    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year <= this.selectedYear + 10; year++){
      this.years.push(year);
    }
  }

  ngOnInit() {
    this.GetMerchantId();
    this.type = true;
    this.route.params.subscribe(p => {
      this.url = p["params"];
    });
    this.route.queryParams
      .filter(params => params.Id)
      .subscribe(params => {
        this.id = params.Id;
      });
    if (!this.id)
      this.router.navigate(['/wheremybus']);
    else if (this.id != "EXPO")
      this.router.navigate(['/wheremybus']);
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.jsonData = data as string[];
        this.cityCode = this.jsonData[0]['cityCode'];
        if (this.cityCode == "31") {
          if (this.userId != null) {
            if (this.url == "cardLoad") {
              Swal.fire({
                title: 'Bilgilendirme',
                text: 'HatayKart' + "'" + 'a kaydettiğiniz kartlarınıza kredi/banka kartınızla yükleme yapabilirsiniz. Bekleyen bakiye kısmında görünecek yüklemeniz, otobüste ilk kart okutmada kartınıza otomatik olarak aktarılacaktır.',
                icon: 'warning',
                showCancelButton: false,
                allowOutsideClick: false,
              }).then((result) => {
              })
            }
            else if (this.url == "balanceInquiry") {
              Swal.fire({
                title: 'Bilgilendirme',
                text: 'Hataykart numaranızı girerek kartınızda kalan bakiyeyi öğrenebilirsiniz.',
                icon: 'warning',
                showCancelButton: false,
                allowOutsideClick: false,
              }).then((result) => {
              })
            }
          }
        }
      });
    window.scrollTo(0, 0);
    this.userId = this.getFromLocal("userId");
    this.sessionTicket = this.getFromLocal("sessionTicket");
    this.differentCardTab();

    if (this.userId == null) {
      this.differentCardTab();
    }
    else if (this.userId == undefined) {
      this.differentCardTab();
    }
    else if (this.userId == 0) {
      this.differentCardTab();
    }
    else {
      if (this.url == "balanceInquiry") {
        this.$('#data-tab2').trigger('click');
        this.$('#balance-inquiry').trigger('click');
        this.differentCard = true;
        this.balanceInquiry();
      }
      else {
        this.myCardsTab();
      }
    }
    this.route.params.subscribe(routeParams => {
      this.authService.changeMenu();
      if (this.url == "balanceInquiry") {
        this.$('#data-tab1').trigger('click');
        this.$('#balance-inquiry').trigger('click');
        this.balanceInquiry();
      }
      else if (this.url == "cardLoad") {
        if (this.userId == null) {
          Swal.fire({
            title: 'Uyarı',
            text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Anladım',
            confirmButtonText: 'Üye Girişi'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/login/myCards']);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.differentCardTab();
              this.router.navigate(['/smartstop']);
            }
          })
        }
        else if (this.userId == undefined) {
          this.router.navigate(['/wheremybus']);
          Swal.fire({
            title: 'Uyarı',
            text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Anladım',
            confirmButtonText: 'Üye Girişi'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/login/myCards']);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.differentCardTab();
            }
          })
        }
        else if (this.userId == 0) {
          this.router.navigate(['/wheremybus']);
          Swal.fire({
            title: 'Uyarı',
            text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Anladım',
            confirmButtonText: 'Üye Girişi'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/login/myCards']);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.differentCardTab();
            }
          })
        }
        else {
          this.$('#online-refill').trigger('click');
          this.onlineRefill();
        }
      }
      else if (this.url == "myCards") {
        if (this.userId == null) {

          Swal.fire({
            title: 'Uyarı',
            text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Anladım',
            confirmButtonText: 'Üye Girişi'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/login/myCards']);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.differentCardTab();
              this.router.navigate(['/smartstop']);
            }
          })
        }
        else if (this.userId == undefined) {
          this.router.navigate(['/wheremybus']);
          Swal.fire({
            title: 'Uyarı',
            text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Anladım',
            confirmButtonText: 'Üye Girişi'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/login/myCards']);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.differentCardTab();
            }
          })
        }
        else if (this.userId == 0) {
          this.router.navigate(['/wheremybus']);
          Swal.fire({
            title: 'Uyarı',
            text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            cancelButtonText: 'Anladım',
            confirmButtonText: 'Üye Girişi'
          }).then((result) => {
            if (result.value) {
              this.router.navigate(['/login/myCards']);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.differentCardTab();
            }
          })
        }
        else {
          this.myCardsTab();
          this.$('#movements').trigger('click');
          this.cardMovements();
        }
      }
      else if (this.url == "cardVisa") {
        if (this.userId != undefined) {
          this.$('#online-visa-tab').trigger('click');
        }
        else {
          this.$('#data-tab2').trigger('click');
          this.$('#different-online-visa').trigger('click');
        }
        this.onlineVisa();
      }
      else
        this.differentCardTab();
    });

  }



  myCardsTab() {
    if (this.userId > 0) {
      this.$('#data-tab1').trigger('click');
      this.buttonValue = 5;
      this.differentCard = false;
      this.middleTab = false;
      this.balanceInq = false;
      this.continueButton = true;
      this.continueButton2 = false;
      this.creditCardInformations = false;
      this.type = false;
      this.back = false;
      this.onlineRefill();
      this.getCards(0);
    }
    else {
      this.differentCardTab();
      this.$('#data-tab2').trigger('click');
      Swal.fire({
        title: 'Uyarı',
        text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: 'Anladım',
        confirmButtonText: 'Üye Girişi'
      }).then((result) => {
        if (result.value) {
          this.router.navigate(['/login/myCards']);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.differentCardTab();
        }
      })
    }
  }

  differentCardTab() {
    this.$('#data-tab2').trigger('click');
    this.lastVisaDate = null;
    this.buttonValue = 5;
    this.differentCard = true;
    this.middleTab = true;
    this.continueButton = false;
    this.continueButton2 = true;
    this.creditCardInformations = false;
    this.movements = false;
    this.type = true;
    // this.onlineRefill();
  }

  onlineRefill() {
    if (this.cityCode == "31") {
      Swal.fire({
        title: 'Bilgilendirme',
        text: 'HatayKart' + "'" + 'a kaydettiğiniz kartlarınıza kredi/banka kartınızla yükleme yapabilirsiniz. Bekleyen bakiye kısmında görünecek yüklemeniz, otobüste ilk kart okutmada kartınıza otomatik olarak aktarılacaktır.',
        icon: 'warning',
        showCancelButton: false,
        allowOutsideClick: false,
      }).then((result) => {
      })
    }
    if (this.differentCard == true) {
      this.middleTab = true;
      this.continueButton2 = true;
      this.lastVisaDate = null;
      this.creditCardInformations = false;
    }
    else {
      this.$('#online-refill').trigger('click');
      this.middleTab = false;
      this.continueButton2 = false;
      this.continueButton = true;
      this.creditCardInformations = false;
    }
    this.buttonValue = 5;
    this.visa = false;
    this.lastVisaDate = null;
    this.myCardsVisa = false;
    this.transactions = true;
    this.balanceInq = false;
    this.movements = false;
    this.creditCardInformationsVisaMyCard = false;
  }

  balanceInquiry() {
    if (this.cityCode == "31") {
      Swal.fire({
        title: 'Bilgilendirme',
        text: 'Hataykart numaranızı girerek kartınızda kalan bakiyeyi öğrenebilirsiniz.',
        icon: 'warning',
        showCancelButton: false,
        allowOutsideClick: false,
      }).then((result) => {
      })
    }
    this.transactions = false;
    this.lastVisaDate = null;
    this.visa = false;
    this.myCardsVisa = false;
    this.balanceInq = true;
    this.movements = false;
    this.middleTab = false;

  }

  onlineVisa() {
    if (this.differentCard == true) {
      this.visa = true;
      this.myCardsVisa = false;
      this.isEnabled = true;
      this.lastVisaDate = null;
    }
    else {
      this.myCardsVisa = true;
      this.visa = false;
      this.isEnabled = false;
      setTimeout(() => {
        this.$("#onlineVisaInput").val(this.mifareIdLabel);
      }, 100);

    }
    this.transactions = false;
    this.balanceInq = false;
    this.movements = false;
    this.creditCardInformationsVisa = false;
    this.creditCardInformationsVisaMyCard = false;
    this.continueDifferenetCardVisa = false;
  }

  cardMovements() {
    this.$(".mobile-menu").fadeOut("slow");
    this.$(".online-refill").css("background-color", "#EDEDED");
    this.transactions = false;
    this.balanceInq = false;
    this.movements = true;
    this.visa = false;
    this.myCardsVisa = false;
    this.creditCardInformationsVisa = false;
    this.creditCardInformationsVisaMyCard = false;
    this.continueDifferenetCardVisa = false;
    this.getCards(this.index);
  }

  clickButtonMyCard(e) {
    var id = (<HTMLInputElement>event.target).id;
    var className = (<HTMLInputElement>event.target).className;
    var test = (<HTMLInputElement>event.target).name;
    this.$("." + className + "").css("background-color", "white");
    this.$("#" + id + "").css("background-color", "#fdc616");
    this.buttonValue = e.target.value;
    this.$("#input-different-card-price").val("");
    this.$("#input-different-card-price").text("");
  }

  clickButtonDifferent(e) {
    var id = (<HTMLInputElement>e.target).id;
    var className = (<HTMLInputElement>e.target).className;
    console.log(id, className);
    this.$("." + className + "").css("background-color", "white");
    this.$("#" + id + "").css("background-color", "#fdc616");
    this.buttonValue = e.target.value;
    this.$("#card-price").val("");
    this.$("#card-price").text("");
  }

  setOther(value) {
    this.buttonValue = value;
    if (this.buttonValue == "") {
      this.$("#stepdifferent5").css("background-color", "rgb(253, 198, 22)");
      this.buttonValue = 5;
    }
    else {
      this.$(".step").css("background-color", "white");
    }
  }

  setCardId(value) {
    this.$(".card-number").val(value);
  }

  setCardIdModel(value) {
    this.$(".card-numbers").val(value);
  }

  continue() {
    if (this.buttonValue > 4 && this.buttonValue < 101) {
      if (this.buttonValue != null && this.buttonValue != undefined && this.buttonValue != 0 && this.buttonValue != "") {
        this.continueButton = false;
        if (this.differentCard == true) {
          this.middleTab = true;
          this.back = false;
          this.continueButton2 = true;
          this.continueButton = false;
          this.creditCardInformations = false;
          this.userpaymentService.GetCommission().subscribe((res => {
            if (this.buttonValue <= 10) {
              this.commission = res.result[0].commission;
            }
            else {
              this.commission = res.result[1].commission;
            }
            this.totalbuttonValue = Number(this.buttonValue) + Number(this.commission);
            this.placeholder = "Toplam Tutar: " + this.totalbuttonValue + " ₺";
          }));
        }
        else {
          if (this.topUpEligibility == true) {
            this.middleTab = false;
            this.back = false;
            this.continueButton2 = false;
            this.continueButton = false;
            this.creditCardInformations = true;
            this.userpaymentService.GetCommission().subscribe((res => {
              if (this.buttonValue <= 10) {
                this.commission = res.result[0].commission;
              }
              else {
                this.commission = res.result[1].commission;
              }
              this.totalbuttonValue = Number(this.buttonValue) + Number(this.commission);
              this.placeholder = "Toplam Tutar: " + this.totalbuttonValue + " ₺";
            }));
          }
          else {
            this.continueButton = true;
            Swal.fire('Uyarı', 'Bu kart uzaktan yükleme için uygun değildir.', 'warning')
          }
        }
      }
      else {
        Swal.fire('Uyarı', 'Yükleme yapacağınız para miktarını seçmelisiniz.', 'warning')
      }
    }
    else {
      Swal.fire('Uyarı', 'Sadece 5 ₺ ile 100 ₺ arasında yükleme yapabilirsiniz.', 'warning')
    }
  }

  continueDifferentCardVisaClick(mifareId, phone, name) {
    if (phone == "") {
      Swal.fire('Uyarı', 'Telefon Numarası Giriniz.', 'warning')
    }
    else if (name == "") {
      Swal.fire('Uyarı', 'Yükleme Yapacak Kişinin Adı ve Soyadını Giriniz.', 'warning')
    }
    else {
      this.differentCardMifareId = mifareId;
      this.differentCardName = name;
      this.differentCardGsm = phone;
      this.creditCardInformationsVisa = true;
      this.creditCardInformationsVisaMyCard = false;
      this.continueDifferenetCardVisa = false;
    }
  }

  continue2(MifareId: string, gsm: string) {
    if (this.buttonValue > 4 && this.buttonValue < 101) {
      if (this.buttonValue == null && this.buttonValue == undefined && this.buttonValue == 0) {
        Swal.fire('Uyarı', 'Yükleme yapacağınız para miktarını seçmelisiniz.', 'warning')
      }
      else if (MifareId == "") {
        Swal.fire('Uyarı', 'Yükleme yapacağınız Ulaşım Kart seri numarasını giriniz.', 'warning')
      }
      else if (MifareId.length < 8) {
        Swal.fire('Uyarı', 'Kart numarası 8 karakterden az olamaz.', 'warning')
      }
      else if (gsm == "") {
        Swal.fire('Uyarı', 'Telefon Numarasını Giriniz.', 'warning')
      }
      else if (gsm.replace("(", "").replace(")", "").replace("-", "").replace(" ", "").replace("_", "").length < 10) {
        Swal.fire('Uyarı', 'Cep telefonu alanını yanlış doldurdunuz.Lütfen kontrol ediniz.', 'warning')
      }
      // else if (name == "") {
      //   Swal.fire('Uyarı', 'Kart Sahibinin Adını Giriniz.', 'warning')
      // }
      else {
        this.userpaymentService.GetCommission().subscribe((res => {
          if (this.buttonValue <= 10) {
            this.commission = res.result[0].commission;
          }
          else {
            this.commission = res.result[1].commission;
          }
          this.totalbuttonValue = Number(this.buttonValue) + Number(this.commission);
          this.back = true;
          this.differentCardGsm = gsm;
          this.differentCardMifareId = MifareId;
          this.middleTab = false;
          this.creditCardInformations = true;
          this.continueButton2 = false;
          this.placeholder = "Toplam Tutar: " + this.totalbuttonValue + " ₺";
        }));

        // this.cardOperations.GetCardInfo(MifareId).subscribe((res => {
        //   if (res.result != null) {
        //     this.back = true;
        //     this.differentCardGsm = gsm;
        //     this.differentCardMifareId = MifareId;
        //     this.middleTab = false;
        //     this.creditCardInformations = true;
        //     this.continueButton2 = false;
        //     this.placeholder = "Toplam Tutar: " + this.totalbuttonValue + " ₺";
        //   }
        //   else {
        //     Swal.fire('Uyarı', 'Kart Seri Numarası hatalıdır.', 'warning')
        //   }
        // }))
      }
    }
    else {
      Swal.fire('Uyarı', 'Sadece 5 ₺ ile 100 ₺ arasında yükleme yapabilirsiniz.', 'warning')
    }

  }

  backClick() {
    this.back = false;
    this.continue();
  }

  completePayment(Name, MifareId, Amount, Phone, NameSurname: string, CardNo: string, Month: number, Year: string, cvc: string, loadingType: string) {
    let orderNumber;
    Amount = this.buttonValue;

    this.type == false ? MifareId = this.mifareIdLabel : MifareId = this.differentCardMifareId;

    if (this.type == true)
      Name = this.differentCardName;
    else
      Name = "";

    if (this.type == true)
      Phone = this.differentCardGsm;
    else
      Phone = "";

    if (NameSurname == "")
      Swal.fire('Uyarı', 'Kart üzerindeki Ad-Soyad alanını giriniz.', 'warning')
    else if (Amount == "" && (this.buttonValue == "" || this.buttonValue == undefined))
      Swal.fire('Uyarı', 'Tutar giriniz veya seçiniz.', 'warning')
    else if (CardNo == "")
      Swal.fire('Uyarı', 'Kredi kart numaranızı giriniz.', 'warning')
    else if (cvc == "")
      Swal.fire('Uyarı', 'Cvv Giriniz.', 'warning')
    else {
      this.spinnerService.show();
      this.differentCardName = NameSurname;
      this.spinnerMessage = "3D Sayfasına Yönlendirileceksiniz!";
      CardNo = this.removeSpecialCharacters(CardNo);
      if (this.type == true) {
        let startTopupOrderDifferentCard = new StartTopupOrderParamDifferentCard();
        // startTopupOrderDifferentCard.userId = "0";
        startTopupOrderDifferentCard.mifareId = MifareId;
        startTopupOrderDifferentCard.nameSurname = this.differentCardName;
        startTopupOrderDifferentCard.phone = this.differentCardGsm;
        startTopupOrderDifferentCard.email = "";
        startTopupOrderDifferentCard.amount = Number(Amount != "" ? Amount : this.buttonValue);
        startTopupOrderDifferentCard.comission = this.commission;
        startTopupOrderDifferentCard.sessionId = 1;
        startTopupOrderDifferentCard.clientType = 1;
        startTopupOrderDifferentCard.maskedCardNumber = CardNo;
        startTopupOrderDifferentCard.mpMacroMerchantId = this.merchantId;
        startTopupOrderDifferentCard.channelCd = "cABYS_" + this.id;
        if (loadingType == "visa") {
          this.visaService.GetStartVisaOrderVakifBankGuest(startTopupOrderDifferentCard).subscribe((res) => {
            orderNumber = res.result.orderNumber;
            if (orderNumber == null || orderNumber == undefined)
              Swal.fire('Uyarı', 'Tekrar deneyiniz.', 'warning')
            else
              this.Bank3DSecure(CardNo, Year, Month, Amount != "" ? Amount : this.buttonValue, cvc, orderNumber);
          });
        }
        else {
          this.userpaymentService.GetStartTopupOrderDifferentCard(startTopupOrderDifferentCard).subscribe((res) => {
            if (!res.result) {
              Swal.fire('Uyarı', res.message, 'warning')
              this.spinnerService.hide();
            }
            else {
              orderNumber = res.result.orderNumber;
              if (orderNumber == null || orderNumber == undefined)
                Swal.fire('Uyarı', 'Tekrar deneyiniz.', 'warning')
              else
                this.Bank3DSecure(CardNo, Year, Month, Amount != "" ? Amount : this.totalbuttonValue, cvc, orderNumber);
            }
          });
        }
      }
      else {
        let startTopupOrder = new StartTopupOrderParam();
        // startTopupOrder.userId = this.userId == undefined ? "0" : this.userId;
        startTopupOrder.sessionTicket = this.sessionTicket == undefined ? "0" : this.sessionTicket;
        startTopupOrder.mifareId = MifareId;
        startTopupOrder.amount = Amount != "" ? Amount : this.buttonValue;
        startTopupOrder.comission = this.commission;
        startTopupOrder.sessionId = 1;
        startTopupOrder.clientType = 1;
        startTopupOrder.maskedCardNumber = CardNo;
        startTopupOrder.mpMacroMerchantId = this.merchantId;
        if (loadingType == "visa") {
          this.visaService.GetStartVisaOrderVakifBank(startTopupOrder).subscribe((res) => {
            orderNumber = res.result.orderNumber;
            if (orderNumber == null || orderNumber == undefined)
              Swal.fire('Uyarı', 'Tekrar deneyiniz.', 'warning')
            else
              this.Bank3DSecure(CardNo, Year, Month, Amount != "" ? Amount : this.buttonValue, cvc, orderNumber);
          });
        }
        else {
          this.userpaymentService.GetStartTopupOrder(startTopupOrder).subscribe((res) => {
            if (res.result) {
              orderNumber = res.result.orderNumber;
              if (orderNumber == null || orderNumber == undefined)
                Swal.fire('Uyarı', 'Tekrar deneyiniz.', 'warning')
              else
                this.Bank3DSecure(CardNo, Year, Month, Amount != "" ? Amount : this.totalbuttonValue, cvc, orderNumber);
            }
            else if (res.message == "Bekleyen işleminiz var. Lütfen maksimum 10 dk içerisinde tekrar deneyiniz") {
              this.spinnerService.hide();
              Swal.fire('Uyarı', 'Bekleyen işleminiz var. Lütfen maksimum 10 dk içerisinde tekrar deneyiniz.', 'warning')
            }
            else {
              this.spinnerService.hide();
              Swal.fire('Uyarı', 'Hatalı işlem.', 'warning');
            }
          });
        }
      }
    }
  }

  Bank3DSecure(cardNo, year: string, month, amount, cvc, orderNumber) {
    this.spinnerService.show();
    let PanStr = "Pan%3D" + cardNo;
    let ExpireDateStr = "%26ExpiryDate%3D" + (year.substring(2) + month);
    let PurchaseAmountStr = this.commission == 0.5 ? "%26PurchaseAmount%3D" + this.totalbuttonValue + "0" : "%26PurchaseAmount%3D" + (this.totalbuttonValue).toFixed(2);
    let CurrencyStr = "%26Currency%3D949"
    let BrandNameStr = "%26BrandName%3D100"
    let VerifyEnrollmentRequestIdStr = "%26VerifyEnrollmentRequestId%3D" + orderNumber;
    let SessionInfoStr = "%26SessionInfo%3D" + cvc;
    let MerchantID = "%26MerchantID%3D000000000903534";
    let MerchantPassword = "%26MerchantPassword%3Dy8K2Hta9"
    let SuccessUrl = "%26SuccessUrl%3Dhttps://webservice.akillibiletim.com/AbysCoreApiv2/Order/CheckBankOrder"
    let FailureUrl = "%26FailureUrl%3Dhttps://webservice.akillibiletim.com/AbysCoreApiv2/Order/CheckBankOrder"
    let InstallmentCountStr = "%26InstallmentCount%3D"


    let all = PanStr + ExpireDateStr + PurchaseAmountStr + CurrencyStr + BrandNameStr + VerifyEnrollmentRequestIdStr + SessionInfoStr + MerchantID + MerchantPassword + SuccessUrl + FailureUrl + InstallmentCountStr

    var req = this.userpaymentService.GetBankService(all);
    this.userpaymentService.GetBankService(all).subscribe((res => {
      this.xmlParse(res.responseMessage);
      if (this.status == 'Y') {
        this.print();
      }
      else if (this.status == 'N') {
        Swal.fire('Uyarı', 'Hatalı işlem.', 'warning')
      }

      else {
        Swal.fire('Uyarı', 'İşlem başlatılırken hata alındı.', 'warning')
      }

    }));
  }

  print(): void {
    var postBackForm = `
    <html>
    <head>
    <title>GET724 MPI 3D-Secure Processing Page</title>
    </head>
    <body >
    <form name="downloadForm" action="%=ACSUrl%" method="POST">
    <noscript>
    <br>
    <br>
    <center>
    <h1>Processing your 3-D Secure Transaction</h1>
    <h2>
    JavaScript is currently disabled or is not supported
    by your browser.<br></h2>
    <h3>Please click Submit to continue
    the processing of your 3-D Secure
    transaction. Enrolled Returns true
    </h3>
    <input type="submit" value="Submit">
    </center>
    </noscript>
    <input type="hidden" name="PaReq" value="%=PAReq%">
    <input type="hidden" name="TermUrl" value="%=TermUrl%">
    <input type="hidden" name="MD" value="%=MD%">
    </form>
    <SCRIPT LANGUAGE="Javascript" >
    document.downloadForm.submit();
    </SCRIPT>
    </body>
    </html>`;

    postBackForm = postBackForm.replace("%=ACSUrl%", this.ACSUrl);
    postBackForm = postBackForm.replace("%=PAReq%", this.PAReq);
    postBackForm = postBackForm.replace("%=TermUrl%", this.TermUrl);
    postBackForm = postBackForm.replace("%=MD%", this.MD);
    this.spinnerService.hide();
    document.write(postBackForm);
    document.close();
  }

  xmlParse(xmlData: any) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlData, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.jsonData = obj as string[];
    this.status = this.jsonData["IPaySecure"]["Message"]["VERes"]["Status"];
    this.ACSUrl = this.jsonData["IPaySecure"]["Message"]["VERes"]["ACSUrl"];
    this.PAReq = this.jsonData["IPaySecure"]["Message"]["VERes"]["PaReq"];
    this.TermUrl = this.jsonData["IPaySecure"]["Message"]["VERes"]["TermUrl"];
    this.MD = this.jsonData["IPaySecure"]["Message"]["VERes"]["MD"];
    return obj;
  }

  GetMerchantId() {
    this.cardOperations.GetSelectCityBankId().subscribe(res => {
      this.merchantId = res.result.macroMerchantId;
    })
  }
  removeSpecialCharacters(characters: string) {
    for (var i = 0; i < characters.length; i++) {
      characters = characters.replace("-", "");
    }
    return characters;
  }

  generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  onIndexChange(index) {
    this.index = index;
    this.getCards(index);
    this.type = false;
    this.mifareIdLabel = this.cardInformations[index].mifareId;
    this.currentBalanceLabel = this.cardInformations[index].currentAmount + "₺";
    this.pendingBalanceLabel = this.cardInformations[index].waitingAmount + "₺";
    this.isFavorite = this.cardInformations[index].isFavorite;
    this.transactionList = this.cardInformations[index].transactions;
    this.expireDate = this.formatDate(this.cardInformations[index].expireDate);
    this.subscribeCardBalance = this.cardInformations[index].subscribeCardBalance;
    this.topUpEligibility = this.cardInformations[index].topUpEligibility;
    this.$("#onlineVisaInput").val(this.mifareIdLabel);
    this.middleTab = false;
    this.back = false;
    this.continueButton2 = false;
    this.continueButton = true;
    this.creditCardInformations = false;
    this.buttonValue = 5;
  }

  getCards(index) {
    this.cardOperations.GetCardInformation(this.sessionTicket).subscribe((res) => {
      if (res.result.cards.length > 0) {
        this.cardInformations = res.result.cards;
        this.mifareIdLabel = this.cardInformations[index].mifareId;
        this.currentBalanceLabel = this.cardInformations[index].currentAmount + "₺";
        this.pendingBalanceLabel = this.cardInformations[index].waitingAmount + "₺";
        this.isFavorite = this.cardInformations[index].isFavorite;
        this.transactionList = this.cardInformations[index].transactions;
        this.expireDate = this.formatDate(this.cardInformations[index].expireDate);
        this.subscribeCardBalance = this.cardInformations[index].subscribeCardBalance;
        this.topUpEligibility = this.cardInformations[index].topUpEligibility;
        this.$("#onlineVisaInput").val(this.mifareIdLabel);
        // this.getFavoriteCard();
        if (this.cardInformations.length >= 5)
          this.maxCardLenght = false;
        else
          this.maxCardLenght = true;
      }
      else {
        this.maxCardLenght = true;

      }
      this.spinnerService.hide();
    })

  }


  getFavoriteCard() {
    this.cardOperations.GetFavoriteCard(this.userId).subscribe((res) => {
      if (res.statusCode == "200") {
        this.favoriteCardNo = res.result.cardId;
        this.saveInLocal("amount", res.result.amount + "₺");
        this.saveInLocal("isLowBalance", res.result.isLowBalance);
      }
      else {
        this.saveInLocal("amount", "");
        this.saveInLocal("isLowBalance", "");
      }
    });
  }

  addFavorite() {
    this.cardOperations.AddFavoriteCard(this.mifareIdLabel, this.userId).subscribe((res) => {
      if (res.statusCode == "200") {
        this.getCards(0);
        // this.getFavoriteCard();
        Swal.fire('Başarılı', 'Kartınız favorilere eklenmiştir.', 'success')
      }
      else {
        Swal.fire('Uyarı', 'Bir hata oluştu.', 'warning')
      }
    });
  }

  buttonSend(MifareId: any) {
    this.spinnerService.show();
    if ((MifareId.length != 8) && (MifareId.length != 14)) {
      this.spinnerService.hide();
      Swal.fire('Uyarı', 'Girdiğiniz kart numarası hatalıdır.', 'warning')
      this.isTrue = false;
    }
    else {
      this.GetBalance(MifareId);
    }
  }

  GetBalance(QueryParam) {
    this.balanceResponse = this.balanceService.GetBalanceData(QueryParam);
    this.balanceResponse.subscribe((response) => {
      if (!response.result[0]) {
        this.isTrue = false;
        this.spinnerService.hide();
        Swal.fire('Uyarı', 'Girdiğiniz bilgilere ait kayıt bulunamamıştır.', 'warning')
      }
      else {
        this.currentBalance = response.result[0].guncelBakiye;
        this.currentBalance = this.currentBalance;
        this.spinnerService.hide();
        this.isTrue = true;
      }
    })
  }

  // checkVisaByCardNo(mifareId: string) {
  //   if (mifareId == "") {
  //     Swal.fire('Uyarı', 'Kart seri numarası alanını doldurunuz.', 'warning')
  //   }
  //   else if (mifareId.length != 8 && mifareId.length != 14) {
  //     Swal.fire('Uyarı', 'Kart numaranızı doğru girdiğinizden emin olun.', 'warning')
  //   }
  //   else {
  //     this.spinnerService.show();
  //     let parameters = new checkVisaParameters();
  //   parameters.mifareId = mifareId;
  //   parameters.channel = "VISAWEB";
  //     this.cardOperations.GetCardInfo(mifareId).subscribe((res => {
  //       if (res.result[0]) {
  //         this.visaService.CheckVisaByCardNo(parameters).subscribe((x) => {
  //           if (x.result[0]) {
  //             if (x.statusCode == 200) {
  //               this.$(".visa-input").val(mifareId);
  //               this.checkVisa = x.result;
  //               this.spinnerService.hide();
  //               this.visaInputMifareId = mifareId;
  //               if (this.differentCard == false) {
  //                 this.myCardsVisa = false;
  //                 this.buttonValue = this.checkVisa.visaFee;
  //                 this.placeholder2 = "Vize Ücreti: " + this.buttonValue + " ₺";
  //                 this.visaDescription = this.checkVisa.cardDescription;
  //                 this.lastVisaDate = this.checkVisa.visaEndDate;
  //                 this.creditCardInformationsVisaMyCard = true;
  //               }
  //               else {
  //                 this.isEnabled = false;
  //                 this.continueDifferenetCardVisa = true;
  //                 this.buttonValue = this.checkVisa.visaFee;
  //                 this.placeholder2 = "Vize Ücreti: " + this.buttonValue + " ₺";
  //               }
  //             }
  //             else {
  //               this.isEnabled = true;
  //               this.continueDifferenetCardVisa = false;
  //               this.spinnerService.hide();
  //               Swal.fire('Uyarı', 'Bu kart vizeleme için uygun değildir.', 'warning')
  //             }
  //           }
  //           else {
  //             this.checkVisaByCardNo(mifareId);
  //           }
  //         })
  //       }
  //       else {
  //         this.spinnerService.hide();
  //         Swal.fire('Uyarı', 'Kart numarasını doğru girdiğinizden emin olun.', 'warning')
  //       }
  //     }))


  //   }
  // }

  getFromLocal(key): void {
    return this.data[key] = this.storage.get(key);
  }

  openModal(id: string, mifareId, name) {
    this.cardNo = mifareId;
    this.modalCardName = name;
    // this.modalService.open(id);
  }

  CardAddModel(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  addNewCard(id, cardName, cardNumber) {
    this.$(".name-input").val("");
    this.$(".card-mifareId-model").val("");
    if (cardName == "")
      Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (cardNumber == "")
      Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else {
      this.save(cardNumber, cardName, 0);
    }
    this.closeModal(id);
  }

  save(cardNo: string, cardName: string, isUpdated: number) {
    this.spinnerService.show();
    this.cardOperations.GetAddCard(cardName, cardNo, this.userId, isUpdated, this.sessionTicket).subscribe((res) => {
      if (res.message == "Lütfen kart numaranızı kontrol ediniz !") {
        Swal.fire('Uyarı', 'Lütfen Kart Seri Numarasını kontrol ediniz.', 'warning')
      }
      else {
        if (res.result[0].resultStatu == "NEED_CONFIRM") {
          Swal.fire('Uyarı', 'Kartınızın onaylanması gerekmektedir.', 'warning')
        }
        else if (res.result[0].resultStatu == "ADDED") {
          this.getCards(0);
          this.addNewCardLabel = true;
          this.newCard = false;
          Swal.fire('Başarılı', 'Kartınız Eklendi.', 'success')
        }
        else if (res.result[0].resultStatu == "UPDATED") {
          this.getCards(0);
          Swal.fire('Başarılı', 'Kart bilgileriniz güncellenmiştir.', 'success')
        }
        else if (res.result[0].resultStatu == "EXIST") {
          this.getCards(0);
          Swal.fire('Uyarı', 'Bu kart daha önce eklenmiş.', 'warning')
        }
        else {
          Swal.fire('Uyarı', 'Bilgilerinizi kontrol ediniz.', 'warning')
        }
      }
    });
    this.spinnerService.hide();
  }


  saveChanges(id: string, cardName) {
    this.save(this.cardNo, cardName, 1);
    this.closeModal(id);
  }

  removeCard(id: string) {
    // this.modalService.close(id);

    this.cardOperations.GetDeleteCard(this.cardName, this.cardNo, this.userId, this.sessionTicket).subscribe((res) => {
      if (res.result) {
        this.getCards(0);
        Swal.fire('Başarılı', 'Kart Silindi.', 'success')
      }
    });

  }

  openPopup() {
    event.preventDefault();
    window.open(this.$("#popup").attr("href"), "popupWindow", "width=600,height=600,scrollbars=yes");
  }

  formatAmount(amount) {
    return this.utilities.replaceAmount((Math.round(amount * 100) / 100).toFixed(2)) + "  ₺";

  }

  formatDate(date) {
    return this.datepipe.transform(date, 'dd/MM/yyyy HH:mm')
  }

  formatDate2(date) {
    return this.datepipe.transform(date, 'dd.MM.yyyy')
  }

  public delCard(cardName: string, cardNo: string, id: string) {
    // this.modalService.open(id);
    this.cardName = cardName;
    this.cardNo = cardNo;
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  clickBackRefill() {
    this.creditCardInformations = false;
    this.middleTab = true;
    this.continueButton2 = true;
    this.buttonValue = 5;
    this.$(".card-number").val("");
  }

  clickBackVisa() {
    this.creditCardInformationsVisa = false;
    this.continueDifferenetCardVisa = false;
    this.isEnabled = true;
    this.$(".card-number").val("");
  }
}
