export class forgotPass {
    version: number;
    statusCode: string;
    message: string;
    result: forgotPassResult[]
}

export class forgotPassResult {
  userName: string
}

export class forgotPassValue {
  UserName:string;
}