export class social {
  Version: number;
  StatusCode: string;
  Message: string;
  Result: socialResult[]
}

export class socialResult {
  Id: number;
  SocialId: number;
  SocialName: string;
}

export class socialLogin {
  Version: number;
  StatusCode: string;
  Message: string;
  Result: socialLoginResult[]
}

export class socialLoginResult {
  UserName: string;
  Name: string;
  Surname: string;
  Id: number;
  SocialUserId: number;
}


export class SocialLoginModel {
  UserName: string;
  Name: string;
  Surname: string;
  SocialId: number;
  Token: string;
}