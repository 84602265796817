import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServices } from 'src/app/services/auth.service';
import { CardoperationsService } from 'src/app/services/cardoperations.service';
import { DatePipe } from '@angular/common';
import { VisaService } from 'src/app/services/visa.service';
import { CommitVisaOrderParameters } from 'src/app/shared/classes/CardOperations';

@Component({
  selector: 'app-successurl',
  templateUrl: './successurl.component.html',
  styleUrls: ['./successurl.component.scss']
})
export class SuccessurlComponent implements OnInit {
  // id: any;
  topupBack: boolean;
  visaBack: boolean;
  success: boolean = true;
  successVisa: boolean = true;
  firstCharacter: string;
  date: any;
  message: string;
  constructor(private route: ActivatedRoute, private authService: AuthServices, public datepipe: DatePipe,
    private router: Router, private cardOperations: CardoperationsService, private visaService: VisaService) { }

  ngOnInit() {
    this.authService.changeMenu();
    var id;
    this.route.queryParams
      .filter(params => params.Id)
      .subscribe(params => {
        id = params.Id;
      });
    this.firstCharacter = id.substring(0, 1);
    if (this.firstCharacter == "V") {
      this.visaBack = true;
      let parameters = new CommitVisaOrderParameters();
      parameters.orderNumber = id;
      parameters.authCode = "";
      parameters.bankResult = "";
      parameters.bankResultID = "";
      parameters.maskedCardNumber = "";
      parameters.sessionId = 0;
      parameters.paymentResult = true;
      parameters.provisionId = "";
      this.cardOperations.GetTransactionDetail(id).subscribe((res => {
        if (res.result.resultCode == "0000") {
          parameters.paymentResult = true;
          this.visaService.GetCommitVisaOrder(parameters).subscribe((res => {
            if (res.result) {
              this.successVisa = true;
              this.success = true;
              this.message = "Vizeleme işleminiz başarılı şekilde tamamlanmıştır. Kartınızı ilk okuttuğunuzda aktif olacaktır.";
            }
            else {
              this.message = "İşleminiz başarısız olmuştur.";
              this.successVisa = false;
              this.success = false;
            }
          }));
        }
        else {
          parameters.paymentResult = false;
          this.message = res.result.responseMessage;
          this.visaService.GetCommitVisaOrder(parameters).subscribe((res => {
            if (res.result) {
              this.successVisa = true;
              this.success = true;
              this.message = "İşleminiz başarısız olmuştur.";
            }
            else {
              this.message = "İşleminiz başarısız olmuştur.";
              this.successVisa = false;
              this.success = false;
            }
          }));
        }

      }))
    }
    else {
      this.visaBack = false;
      this.topupBack = true;
      this.cardOperations.GetTransactionDetail(id).subscribe((res => {
        if (res.result.resultCode == "0000") {
          this.success = true;
        }
        else {
          this.success = false;
          this.message = res.result.responseMessage;
        }
        this.successVisa = false;
      }))
    }

  }

  formatDate(date) {
    return this.datepipe.transform(date, 'dd.MM.yyyy')
  }
}
