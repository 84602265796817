import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import './_content/modal.less';
import { environment } from '../environments/environment';

declare let ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})

export class AppComponent {
  title = 'UlasimSihirbazi';
  constructor(public router: Router) {

    // subscribe to router events and send page views to Google Analytics
    // this.router.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');

    //   }

    // });
    
  }
  ngOnInit(){
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
      if(location.origin === 'https://hataykart.com'){
        window.location.href = location.href.replace('https://', 'https://www.');
      }
    }
  }
}
