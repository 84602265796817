import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { lineHoursParams, hoursResult, lineHours } from '../shared/classes/LineHours';

@Injectable({
  providedIn: 'root'
})
export class LinehoursService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }
  
  GetLineHours(Body: lineHoursParams): Observable<lineHours> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<lineHours>(this.localUrl + "linehours/routeschedule", Body, { headers: headers });
  }
}
