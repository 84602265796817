import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { WhereMyBusService } from 'src/app/services/wheremybus';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { whereBusResult, whereBus, busLocationResult, lineLocation, routeStation, routeStationResult, lineLocationResult, BackwardData, ForwardData, routePrice, ForwardStation, BackwardStation, randomDataResult, RouteListByStopIdResult, RouteScheduleByStopResult, ScheduleRouteAndStopParam, ScheduleRouteAndStopSequenceResult, Test, getStartPopup, startPopUpResult } from 'src/app/shared/classes/wherebus';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { LinehoursComponent } from '../linehours/linehours.component';
import { ModalService } from 'src/app/services/modal.services';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import hourDataResult, { lineHoursParams, TimesResult, hoursResult, lineHours } from 'src/app/shared/classes/LineHours';
import { LinehoursService } from 'src/app/services/linehours.service';
import { Utilities } from 'src/app/shared/utitlities';
// import { ToasterService } from 'src/app/services/toaster.service';
import { DatePipe } from '@angular/common';
import { finalize, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MapCommunicationService } from 'src/app/services/communication-services/map-communication.service';
declare var google;

@Component({
    selector: 'app-wheremybus',
    templateUrl: './wheremybus.component.html',
    styleUrls: ['./wheremybus.component.scss'],
    providers: [DatePipe]
})
export class WheremybusComponent implements OnInit {
    title: string = 'Asis Map';
    lat: number;
    lng: number;
    zoom: number = 11;
    map: any;
    cityUrl: string;

    strokeOpacity = "0.7";
    icon: string = '/assets/img/logo/bus32.png';
    stopIcon: string = '/assets/img/logo/durak.png';
    startIcon: string = '/assets/img/logo/start1.png';
    endIcon: string = '/assets/img/logo/bayrak.png';
    locationIcon: string = '/assets/img/logo/locationIcon.png';

    dataRefresher: any;
    dataRefresherSmartStation: any;
    dataRefresher2: any;
    myControl = new FormControl();
    routes: Observable<whereBus>;
    startPopUp: Observable<getStartPopup>;
    searchText: string;
    locations: busLocationResult[];
    lineLocations: Observable<lineLocation>;
    lineResult: lineLocationResult[];
    lineHoursControl: ScheduleRouteAndStopSequenceResult[];
    lineHoursPzt: Test[];
    lineHoursSa: Test[];
    lineHoursCrs: Test[];
    lineHoursPrs: Test[];
    lineHoursCu: Test[];
    lineHoursCrt: Test[];
    lineHoursPa: Test[];
    backwardResult: BackwardData[];
    forwardResult: ForwardData[];
    routeStops: Observable<routeStation>;
    routeStopsResult: routeStationResult[];
    forwardStation: ForwardStation[];
    backwardStation: BackwardStation[];
    selectedBusLocation: busLocationResult;
    device: any = false;
    openedWindow: number;
    jsonData: string[];
    selectRoute: any;
    routePrice: Observable<routePrice>;
    forward: boolean;
    backward: boolean;
    stopshow: boolean;
    stopLatitude: number;
    stopLongitude: number;
    lineHours: lineHours;
    routePriceButton: string;
    cityCode: string;
    placeholder: string = "Hat Seçin..";
    picture: string;
    currentLat: any;
    currentLng: any;
    routeStopsList: boolean = false;
    lineHoursList: boolean = false;
    stopHoursList: boolean = false;
    feeTariffs: boolean = false;
    hoursDataList: TimesResult[] = new Array<TimesResult>();
    lineHoursResult: hoursResult[];
    stopHoursResult: RouteScheduleByStopResult[]
    lineHourss: lineHours;
    count: Number = 0;
    changingHour: Number = 0;
    isSelected: boolean = false;
    private utilities: Utilities;
    dateNow: string;
    smartStations: boolean = false;
    // smartStationRes: smartStationResultData[];
    routeStation: whereBusResult[];
    popupResult: startPopUpResult;
    routeListByStopId: RouteListByStopIdResult[];
    smartStationStationId;
    routeListByStop: boolean = false;
    hatNo;
    mymodel;
    // previous;
    stopName;
    counter;
    ring;
    change: boolean;
    dayNo;
    selectedTab: 0;
    routeCode;
    changes: string = "G";
    description;
    direction = "Gidiş";
    visibleMap: boolean = false;
    imageName: string;

    destroyed$ = new Subject();

    constructor(
        private authService: AuthServices,
        private whereBusService: WhereMyBusService,
        private router: Router, private datePipe: DatePipe,
        private lineHoursService: LinehoursService,
        @Inject(JQ_TOKEN) private $: any,
        private httpService: HttpClient,
        private modalSer: NgbModal,
        private modalService: ModalService,
        private spinnerService: Ng4LoadingSpinnerService,
        private mapCommunicationService: MapCommunicationService,
    ) {
        this.backwardResult = new Array<BackwardData>();
        this.forwardResult = new Array<ForwardData>();
        this.backwardStation = new Array<BackwardStation>();
        this.forwardStation = new Array<ForwardStation>();
        this.lineHoursPzt = new Array<Test>();
        this.lineHoursSa = new Array<Test>();
        this.lineHoursCrs = new Array<Test>();
        this.lineHoursPrs = new Array<Test>();
        this.lineHoursCu = new Array<Test>();
        this.lineHoursCrt = new Array<Test>();
        this.lineHoursPa = new Array<Test>();
        this.utilities = new Utilities();
        this.locations = new Array<busLocationResult>();
    }

    @Input() public route;

    ngOnInit() {
        this.authService.changeMenu();
        this.httpService.get('../assets/GeneralInformation.json').subscribe(
            data => {
                this.jsonData = data as string[];
                this.lat = this.jsonData[0]['lat'];
                this.lng = this.jsonData[0]['lng'];
                this.picture = this.jsonData[0]['picture'];
                this.lineHours = this.jsonData[1]['Hat Hareket Saatleri'];
                this.routePriceButton = this.jsonData[1]['Ücret Tarifeleri'];
                this.cityCode = this.jsonData[0]['cityCode'];
                this.cityUrl = this.jsonData[0]['cityUrl'];

                if (this.cityCode == "31") {
                    this.imageName = "/assets/img/hatayMap.png";
                }
                else if (this.cityCode == "65") {
                    this.imageName = "/assets/img/vanMap.png";
                }
                else if (this.cityCode == "23") {
                    this.imageName = "/assets/img/elazigMap.png";
                }
                else if (this.cityCode == "43") {
                    this.imageName = "/assets/img/kutahyaMap.PNG";
                }
                else if (this.cityCode == "54") {
                    this.imageName = "/assets/img/sakarya.PNG";
                }

                this.GetStartPopup();
                this.mapCommunicationService.addCoordinates([this.lng, this.lat])
            });

        this.spinnerService.show();
        this.GetRoutes();
        this.spinnerService.hide();
        this.$(".bus-detail").css("display", "none");
        this.dateNow = this.datePipe.transform(new Date(), 'HH:mm');

        this.mapCommunicationService.addParent('whereMyBus');

        // Swal.fire('Toplu Taşımada Hes Kodu Kontrolü', 'İçişleri bakanlığının toplu taşımada HES kodu kontrolü genelgesi sebebi ile toplu ulaşımda HES kodu kontrollü binişler aktifleştirilmiştir.Toplu ulaşımı kullanmak için kartınıza HES kodunuzu tanımlamanız gerekmektedir.', 'warning')
    }

    myMouseClicked(event) {
        this.visibleMap = true;
    }

    clickDay(e, dayNumber) {
        var id = (<HTMLInputElement>event.target).id;
        var className = (<HTMLInputElement>event.target).className;
        this.$("." + className + "").css("background-color", "");
        this.$("#" + id + "").css("background-color", "#FFC300");
        this.$("#" + id + "").css("border-radius", "15px");
        this.$("#" + id + "").css("border", "1px solid #ededed");
        this.$("#" + className + "").css("border-radius", "0px");
        this.$("#" + id + "").css("border", "");
        this.clickDayFunction(dayNumber);
    }

    clickDayFunction(dayNumber) {
        this.stopHoursResult = null;
        this.whereBusService.GetRouteScheduleByStop(this.smartStationStationId, dayNumber).subscribe((res) => {
            this.stopHoursResult = res.result;
            if (this.stopHoursResult.length == 0) {
                this.stopHoursList = false;
                Swal.fire('Uyarı', 'Bu durağa ait aktif hareket saat bulunmamaktadır.', 'warning')
            }
        });


    }

    replacePrice(price: string) {
        return this.utilities.replacePrice(price);
    }

    selectStyle(dayNumber): object {
        if (dayNumber == this.dayNo)
            return { "background-color": "#FFC300", "border-radius": "15px" };
        else
            return { "background-color": "#ddd", "border-radius": "0px" };
    }


    selectStation(location: randomDataResult) {
        this.$(".route-stops").css("display", "block");
        this.spinnerService.show();
        this.$(".checkbox-list").css("display", "block");
        this.$(".checkbox-list-stops").css("display", "none");
        this.$(".bus-detail").css("display", "block");
        this.$(".d-none").css("display", "none");
        this.isSelected = true;
        this.$(".select-box").css("background-image", "none");
        this.backward = false;
        this.forward = true;
        this.selectRoute = location.kod;
        this.hatNo = location.hatNo;
        this.description = location.aciklama;
        clearInterval(this.dataRefresher);
        this.GetLocationsData(location.kod, "G");
        this.GetLineCoordinates(location.kod);
        this.GetRouteStops(location.kod);
        this.smartStations = false;
        this.routeListByStop = false;
        this.stopHoursList = false;
        this.routeCode = location.kod;
        this.refreshData(location.kod, "G");
        this.zoom = 14;
        this.clickLabelStops();
        this.stopshow = false;
        this.mapCommunicationService.addDirective({ directive: "clearMap", directiveNeeds: undefined })
        this.spinnerService.hide();
    }

    create(lineHours: lineHours) {
        let hoursData: TimesResult;
        let hourDataRes: hourDataResult;
        let hourDataResultList: hourDataResult[];

        lineHours.result.forEach((res, index, array) => {
            if (this.count == 0) {
                this.changingHour = res.hour;
                hoursData = new TimesResult();
                // hoursData.hour = res.Hour;
            }

            if (this.changingHour != res.hour) {
                this.hoursDataList.push(hoursData);
                hoursData = new TimesResult();
                // hoursData.hour = res.Hour;
            }

            if (index === (array.length - 1)) {
                hourDataRes = new hourDataResult();

                hourDataRes.sequenceNumber = res.sequenceNumber;
                hourDataRes.direction = res.direction;
                hourDataRes.plannedStationIn = res.plannedStationIn;

                hoursData.hourData.push(hourDataRes);
                this.count = 1;
                this.changingHour = res.hour;
                this.hoursDataList.push(hoursData);
                hoursData = new TimesResult();
            }

            hourDataRes = new hourDataResult();

            hourDataRes.sequenceNumber = res.sequenceNumber;
            hourDataRes.direction = res.direction;
            hourDataRes.plannedStationIn = res.plannedStationIn;

            hoursData.hourData.push(hourDataRes);
            this.count = 1;
            this.changingHour = res.hour;
        });
        this.count = 0;
    }

    // clickButton() {
    //     if (!this.selectRoute) {
    //         Swal.fire('Uyarı', 'Hat Seçmediniz.', 'warning')
    //     }
    //     else {
    //         const modalRef = this.modalSer.open(LinehoursComponent);
    //         modalRef.componentInstance.route = this.selectRoute;
    //     }
    // }

    clickStation(station: BackwardStation) {
        // this.zoom = 14;
        this.centeralize(station);
    }

    openWindow(sequence) {
        this.openedWindow = +sequence;
    }

    onChange(value) {
        if (value.checked == true) {
            if (this.routeStopsResult.length == 0) {
                Swal.fire('Uyarı', 'Seçtiğiniz hatta ait durak bilgisine rastlanamadı.', 'warning')
                this.device = false;
            }
            else {
                this.device = true;
            }
        } else {
            this.device = false;
        }
    }

    refreshData(routeCode, istikamet) {

        this.GetLocationsData(routeCode, istikamet);
        this.dataRefresher =
            setInterval(() => {
                this.GetLocationsData(routeCode, istikamet);
            }, 6000);
    }

    centeralize(station: BackwardStation) {
        navigator.geolocation.getCurrentPosition(a => {
            this.lng = +station.logitude;
            this.lat = +station.latitude;
        });
        return this.openedWindow = +station.sequence;
    }

    isInfoWindowOpen(servisId) {
        this.zoom = 14;

        this.openedWindow == servisId;
    }

    // clickedMarker(infowindow) {
    //     if (this.previous) {
    //         this.previous.close();
    //     }
    //     this.previous = infowindow;
    // }

    isInfoWindowOpenBus(servisId) {
        return this.openedWindow == servisId;
    }

    GetRoutes() {
        this.routes = this.whereBusService.GetRouteCodes();
        if (!this.routes)
            this.GetRoutes();
        this.routes.subscribe((x => {
            this.routeStation = x.result;
        }))
    }

    GetStartPopup() {

        this.startPopUp = this.whereBusService.GetStartPupup(this.cityUrl);
        if (!this.startPopUp)
            this.GetStartPopup();

        this.startPopUp.subscribe((x => {
            this.popupResult = x.result;


            if (this.popupResult) {
                let popupId = this.popupResult.id.toString();
                let checkSession = sessionStorage.getItem(popupId);
                let checkLocal = localStorage.getItem(popupId);

                if (!checkSession && !checkLocal) {
                    let url = this.popupResult.url
                    let confirmButtonText = this.popupResult.urlButtonText;
                    // let imgurl = "https://www.bursakart.com.tr/assets/img/popup.jpg";

                    sessionStorage.setItem(popupId, "yes");

                    Swal.fire({
                        title: this.popupResult.title,
                        text: this.popupResult.message,
                        // icon: ,
                        imageUrl: this.popupResult.imageUrl,
                        showCancelButton: true,
                        allowOutsideClick: false,
                        input: 'checkbox',
                        inputPlaceholder: 'Bir daha gösterme',
                        cancelButtonText: 'Kapat',
                        showConfirmButton: confirmButtonText,
                        confirmButtonText: confirmButtonText,
                        customClass: 'startpopup-width'
                    }).then((result) => {
                        let dontshow_checkbox = document.getElementById("swal2-checkbox") as HTMLInputElement;

                        if (dontshow_checkbox.checked) {
                            localStorage.setItem(popupId, "yes");
                        } else {
                            localStorage.removeItem(popupId)
                        }

                        if (result['isConfirmed']) {
                            window.open(url);
                        }
                    })
                }
            }

        }))
    }

    valuechange(value: string) {
        clearTimeout(this.dataRefresher2);
        this.dataRefresher2 =
            setTimeout(() => {
                if (!value || value.length < 1) {
                    this.zoom = 11;
                }
                this.test(value);
            }, 1000);
    }

    test(value) {
        if (value != undefined) {
            if (value.length < 1) {
                this.GetRoutes();
            }
            else
                this.routeStation = null;
            this.routes = this.whereBusService.GetRouteCodes();
            this.routes.subscribe((x => {
                this.routeStation = x.result;
            }))

        }
    }

    GetLocationsData(routeCode, istikamet) {//hatta bağlı otobüs lokasyonlarını almaya yarar
        if (this.locations) {
            for (var i = 0; i < this.locations.length; i++) {
                this.locations.splice(i, this.locations.length);
            }
        }

        this.whereBusService.GetLocations(routeCode)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((res => {
                // this.locations = null;
                // clearInterval(this.dataRefresher);
                if (res.result) {
                    res.result.forEach(element => {
                        let location = new busLocationResult();
                        if (element.istikamet == istikamet) {
                            location.Hatkodu = element.Hatkodu;
                            location.enlem = element.enlem;
                            location.boylam = element.boylam;
                            location.durakYolcu = element.durakYolcu;
                            location.engelliUygunMu = element.engelliUygunMu;
                            location.gunlukYolcu = element.gunlukYolcu;
                            location.klimaVarMi = element.klimaVarMi;
                            location.hiz = element.hiz;
                            location.plaka = element.plaka;
                            location.validatorNo = element.validatorNo;
                            location.yon = element.yon;
                            this.locations.push(location);
                        }
                    });
                }

                this.locations.map(x => {
                    x.ImageUrl = this.icon;
                })
                // Otobüs lokasyonları burada getiriliyor. 
                this.mapCommunicationService.addDirective({
                    directive: 'showLiveBus',
                    directiveNeeds: this.locations
                })

            }),
                (error => {
                    console.log("error", error)
                })
            );
    }

    tabChange(event) {
        this.selectedTab = event.index;
        if (this.selectedTab == 0) {
            let body = new lineHoursParams();
            body.routeCode = this.selectRoute;
            body.dayType = 0,
                body.isFirstStations = true;
            body.direction = this.changes;
            body.hour = "";
            this.getData(body);
        }
        else if (this.selectedTab == 1) {
            let body = new lineHoursParams();
            body.routeCode = this.selectRoute;
            body.dayType = 1,
                body.isFirstStations = true;
            body.direction = this.changes;
            body.hour = "";
            this.getData(body);
        }
        else if (this.selectedTab == 2) {
            let body = new lineHoursParams();
            body.routeCode = this.selectRoute;
            body.dayType = 2,
                body.isFirstStations = true;
            body.direction = this.changes;
            body.hour = "";
            this.getData(body);
        }
    }

    // refreshDataStation(stopId){
    //     this.dataRefresherSmartStation =
    //         setInterval(() => {
    //             this.GetStationData(stopId);
    //         }, 10000);
    // }

    clickForward() {
        this.forward = true;
        this.backward = false;
        if (this.locations) {
            for (var i = 0; i < this.locations.length; i++) {
                this.locations.splice(i, this.locations.length);
            }
        }
        this.GetLocationsData(this.routeCode, "G");
        clearTimeout(this.dataRefresher);
        this.refreshData(this.routeCode, "G");

        this.mapCommunicationService.addDirective({
            directive: 'setVisibleDirection',
            directiveNeeds: 'forward'
        })
    }

    clickBackward() {
        this.backward = true;
        this.forward = false;
        if (this.locations) {
            for (var i = 0; i < this.locations.length; i++) {
                this.locations.splice(i, this.locations.length);
            }
        }
        this.GetLocationsData(this.routeCode, "D");
        clearTimeout(this.dataRefresher);
        this.refreshData(this.routeCode, "D");
        this.mapCommunicationService.addDirective({
            directive: 'setVisibleDirection',
            directiveNeeds: 'backward'
        })
    }

    GetLineCoordinates(routeCode) {
        var lenghtF = this.forwardResult.length;
        this.forwardResult.splice(0, lenghtF);
        var lenghtB = this.backwardResult.length;
        this.backwardResult.splice(0, lenghtB);
        this.whereBusService.GetRouteLocations(routeCode)
            .pipe(finalize(() => {
                let _directiveNeeds: any[] = [];
                if (this.forwardResult.length != 0) {
                    _directiveNeeds.push({
                        direction: 'forward',
                        result: this.forwardResult,
                        color: '#4a8dff',
                        strokeOpacity: this.strokeOpacity,
                        strokeWeight: 6,
                        zoom: 12,
                    })
                }

                if (this.backwardResult.length != 0) {
                    _directiveNeeds.push({
                        direction: 'backward',
                        result: this.backwardResult,
                        color: '#E74C3C',
                        strokeOpacity: this.strokeOpacity,
                        strokeWeight: 6,
                        zoom: 12,
                    })
                }
                this.mapCommunicationService.addDirective({
                    directive: 'drawLine',
                    directiveNeeds: _directiveNeeds
                })

                this.clickForward();
            }))
            .subscribe((request) => {
                this.lineResult = request.result;
                this.ring = request.result.length != 0 ? request.result[0]["routeDirection"] : 0;

                this.lineResult.forEach(element => {
                    let backward = new BackwardData();
                    let forward = new ForwardData();

                    if (element.routeDirection == "B") {
                        backward.latitude = element.latitude;
                        backward.logitude = element.logitude;
                        this.backwardResult.push(backward);
                    }
                    else if (element.routeDirection == "F") {
                        forward.latitude = element.latitude;
                        forward.logitude = element.logitude;
                        this.forwardResult.push(forward);
                    }
                    else if (element.routeDirection == "R") {
                        forward.latitude = element.latitude;
                        forward.logitude = element.logitude;
                        this.forwardResult.push(forward);
                    }
                });
                if (request.result.length == 0) {
                    this.spinnerService.hide();
                    Swal.fire('Uyarı', 'Seçtiğiniz hatta ait koordinat bilgileri bulunmamamktadır.', 'warning')
                }
            })


    }

    GetRouteStops(routeCode) {
        var lenghtF = this.forwardStation.length;
        this.forwardStation.splice(0, lenghtF);
        var lenghtB = this.backwardStation.length;
        this.backwardStation.splice(0, lenghtB);
        this.whereBusService.GetRouteStops(routeCode).subscribe((res => {
            this.routeStopsResult = res.result;
            this.stopName = res.result.length != 0 ? res.result[0]["StopName"] : 0;
            this.routeStopsResult.forEach(element => {
                let backwardStation = new BackwardStation();
                let forwardStation = new ForwardStation();
                if (element.direction == "D") {
                    backwardStation.latitude = element.latitude;
                    backwardStation.logitude = element.longitude;
                    backwardStation.sequence = element.sequence;
                    backwardStation.stopname = element.stopName;
                    // backwardStation.StopId = element.StopId;
                    this.backwardStation.push(backwardStation);
                    if (this.forwardStation.length > 0)
                        this.device = true;
                }
                else if (element.direction == "G") {
                    forwardStation.latitude = element.latitude;
                    forwardStation.logitude = element.longitude;
                    forwardStation.sequence = element.sequence;
                    forwardStation.stopName = element.stopName;
                    forwardStation.stopId = element.stopId;
                    this.forwardStation.push(forwardStation);
                    if (this.forwardStation.length > 0)
                        this.device = true;
                }
                else {
                    forwardStation.latitude = element.latitude;
                    forwardStation.logitude = element.longitude;
                    forwardStation.sequence = element.sequence;
                    forwardStation.stopName = element.stopName;
                    forwardStation.stopId = element.stopId;
                    this.forwardStation.push(forwardStation);
                    if (this.forwardStation.length > 0)
                        this.device = true;
                }
            });

            let _directiveNeeds: any[] = [];
            if (this.forwardStation.length > 0) {
                _directiveNeeds.push({
                    direction: 'forward',
                    stations: this.forwardStation,
                    icon: this.stopIcon,
                })
            }
            if (this.backwardStation.length > 0) {
                _directiveNeeds.push({
                    direction: 'backward',
                    stations: this.backwardStation,
                    icon: this.stopIcon,
                })
            }
            this.mapCommunicationService.addDirective({
                directive: 'showRouteStops',
                directiveNeeds: _directiveNeeds

            })


        }));

    }

    GetRouteListByStopId(stationId) {
        this.routeListByStopId = null;
        this.whereBusService.GetRouteListByStopId(stationId).subscribe((x => {
            this.routeListByStopId = x.result;
        }))


    }

    hideMapOverBus() {
        this.$(".routes").animate({ width: 0 }, 200);
        if (window.matchMedia('(max-width: 767px)').matches) {
            this.$(".routes").css("display", "none");
        }
        if (window.matchMedia('(max-width: 1024px)').matches) {
            this.$(".routes").css("display", "none");
        }
        this.$(".map-over-slider-up-wheremybus").hide();
        this.$(".map-over-slider-down-wheremybus").show();
    }

    displayMapOverBus() {
        this.$(".routes").animate({ width: 450 }, 200);

        if (window.matchMedia('(max-width: 767px)').matches) {
            this.$(".routes").css("display", "block");
            this.$(".routes").animate({ width: "90%" }, 200);
        }
        if (window.matchMedia('(max-width: 1024px)').matches) {
            this.$(".routes").css("display", "block");
        }
        if (window.matchMedia('(max-width: 1900px)').matches) {
            this.$(".routes").animate({ width: 300 }, 200);
        }
        this.$(".map-over-slider-up-wheremybus").show();
        this.$(".map-over-slider-down-wheremybus").hide();
    }


    cancelText() {
        this.searchText = '';
        this.routeStops = null;

    }
    getRoutePrice() {
        if (!this.selectRoute) {
            Swal.fire('Uyarı', 'Hat Seçmediniz.', 'warning')
        }
        else {
            this.routePrice = this.whereBusService.GetRoutePrice(this.selectRoute);
            this.spinnerService.hide();
            this.routePrice.subscribe((res) => {
                if (res.result.length == 0) {
                    Swal.fire('Uyarı', 'Hatta ait ücret tarifesi bulunamadı.', 'warning')
                }
            })
        }

        this.spinnerService.hide();
    }

    GetRouteHours(direction) {
        var date = new Date();
        var days = [7, 1, 2, 3, 4, 5, 6];
        var hour = date.getHours();
        var newHour;
        if (hour! < 10)
            newHour = "0" + hour;
        else
            newHour = hour;
        var minute = date.getMinutes();
        var time = newHour + ":" + minute + ":00";

        var lenghtPzt = this.lineHoursPzt.length;
        this.lineHoursPzt.splice(0, lenghtPzt);
        var lenghtSa = this.lineHoursSa.length;
        this.lineHoursSa.splice(0, lenghtSa);
        var lenghtCrs = this.lineHoursCrs.length;
        this.lineHoursCrs.splice(0, lenghtCrs);
        var lenghtPrs = this.lineHoursPrs.length;
        this.lineHoursPrs.splice(0, lenghtPrs);
        var lenghtCu = this.lineHoursCu.length;
        this.lineHoursCu.splice(0, lenghtCu);
        var lenghtCrt = this.lineHoursCrt.length;
        this.lineHoursCrt.splice(0, lenghtCrt);
        var lenghtPa = this.lineHoursPa.length;
        this.lineHoursPa.splice(0, lenghtPa);
        this.lineHoursControl = null;

        if (!this.selectRoute) {
            Swal.fire('Uyarı', 'Hat Seçmediniz.', 'warning')
        }
        else {
            let param = new ScheduleRouteAndStopParam();
            param.direction = direction;
            param.routeId = this.hatNo;
            param.stopSequenceNo = 0;
            param.weekday = 0;
            this.whereBusService.GetScheduleRouteAndStopSequence(param).subscribe((res => {
                this.lineHoursControl = res.result;
                if (res.result == null) {
                    Swal.fire('Uyarı', 'Hatta ait aktif hareket saati bulunmamaktadır.', 'warning')
                }
                else {
                    let day = days[date.getDay()];
                    this.$("#lineHours-button-" + day + "").css("background-color", "#F9C215");
                    this.counter = 0;
                    res.result.forEach(element => {
                        let PztData = new Test();
                        let SaData = new Test();
                        let ÇrşData = new Test();
                        let PrşData = new Test();
                        let CuData = new Test();
                        let CrtData = new Test();
                        let PaData = new Test();
                        if (element.RouteDay == 1) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        PztData.day = 1;
                                    }
                                    else {
                                        PztData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    PztData.day = 1;
                                }
                            }
                            else
                                PztData.day = 0;
                            PztData.RouteCode = element.RouteCode;
                            PztData.RouteDay = element.RouteDay;
                            PztData.RouteId = element.RouteId;
                            PztData.StopTime = element.StopTime;
                            this.lineHoursPzt.push(PztData);
                        }
                        else if (element.RouteDay == 2) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        SaData.day = 1;
                                    }
                                    else {
                                        SaData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    SaData.day = 1;
                                }
                            }
                            else
                                SaData.day = 0;
                            SaData.RouteCode = element.RouteCode;
                            SaData.RouteDay = element.RouteDay;
                            SaData.RouteId = element.RouteId;
                            SaData.StopTime = element.StopTime;
                            this.lineHoursSa.push(SaData);
                        }
                        else if (element.RouteDay == 3) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        ÇrşData.day = 1;
                                    }
                                    else {
                                        ÇrşData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    ÇrşData.day = 1;
                                }
                            }
                            else
                                ÇrşData.day = 0;
                            ÇrşData.RouteCode = element.RouteCode;
                            ÇrşData.RouteDay = element.RouteDay;
                            ÇrşData.RouteId = element.RouteId;
                            ÇrşData.StopTime = element.StopTime;
                            this.lineHoursCrs.push(ÇrşData);
                        }
                        else if (element.RouteDay == 4) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        PrşData.day = 1;
                                    }
                                    else {
                                        PrşData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    PrşData.day = 1;
                                }
                            }
                            else
                                PrşData.day = 0;
                            PrşData.RouteCode = element.RouteCode;
                            PrşData.RouteDay = element.RouteDay;
                            PrşData.RouteId = element.RouteId;
                            PrşData.StopTime = element.StopTime;
                            this.lineHoursPrs.push(PrşData);
                        }
                        else if (element.RouteDay == 5) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        CuData.day = 1;
                                    }
                                    else {
                                        CuData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    CuData.day = 1;
                                }
                            }
                            else
                                CuData.day = 0;
                            CuData.RouteCode = element.RouteCode;
                            CuData.RouteDay = element.RouteDay;
                            CuData.RouteId = element.RouteId;
                            CuData.StopTime = element.StopTime;
                            this.lineHoursCu.push(CuData);
                        }
                        else if (element.RouteDay == 6) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        CrtData.day = 1;
                                    }
                                    else {
                                        CrtData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    CrtData.day = 1;
                                }
                            }
                            else
                                CrtData.day = 0;
                            CrtData.RouteCode = element.RouteCode;
                            CrtData.RouteDay = element.RouteDay;
                            CrtData.RouteId = element.RouteId;
                            CrtData.StopTime = element.StopTime;
                            this.lineHoursCrt.push(CrtData);
                        }
                        else if (element.RouteDay == 7) {
                            if (day == element.RouteDay) {
                                if (this.counter == 0) {
                                    if (element.StopTime < time) {
                                        PaData.day = 1;
                                    }
                                    else {
                                        PaData.day = 2;
                                        this.counter = 1;
                                    }
                                }
                                else {
                                    PaData.day = 1;
                                }
                            }
                            else
                                PaData.day = 0;
                            PaData.RouteCode = element.RouteCode;
                            PaData.RouteDay = element.RouteDay;
                            PaData.RouteId = element.RouteId;
                            PaData.StopTime = element.StopTime;
                            this.lineHoursPa.push(PaData);
                        }
                    })
                }
            }), error => {
                this.GetRouteHours(direction);
            })

        }
    }

    unselect() {
        this.$(".select-box").css("background-image", "url(/assets/images/downarrow.png)");
        this.$(".bseach-input").val('');
        this.$(".bus-detail").css("display", "none");
        this.mymodel = '';
        this.valuechange("");
        this.deleteMarkerAndCoordinates();
        this.isSelected = false;
        this.stopshow = false;
        this.zoom = 11;

        this.mapCommunicationService.addDirective({ directive: "clearMap", directiveNeeds: undefined })
    }

    deleteMarkerAndCoordinates() {
        clearInterval(this.dataRefresher);
        if (this.backwardResult) {
            for (var i = 0; i < this.backwardResult.length; i++) {
                this.backwardResult.splice(i, this.backwardResult.length);
            }
        }
        if (this.forwardResult) {
            for (var i = 0; i < this.forwardResult.length; i++) {
                this.forwardResult.splice(i, this.forwardResult.length);
            }
        }
        if (this.lineResult) {
            for (var i = 0; i < this.lineResult.length; i++) {
                this.lineResult.splice(i, this.lineResult.length);
            }
        }
        if (this.routeStopsResult) {
            for (var i = 0; i < this.routeStopsResult.length; i++) {
                this.routeStopsResult.splice(i, this.routeStopsResult.length);
            }
        }
        if (this.locations) {
            for (var i = 0; i < this.locations.length; i++) {
                this.locations.splice(i, this.locations.length);
            }
        }
        if (this.backwardStation) {
            for (var i = 0; i < this.backwardStation.length; i++) {
                this.backwardStation.splice(i, this.backwardStation.length);
            }
        }
        if (this.forwardStation) {
            for (var i = 0; i < this.forwardStation.length; i++) {
                this.forwardStation.splice(i, this.forwardStation.length);
            }
        }
        this.GetLocationsData("", "");
        // this.locations = null;
        this.$(".route-stops").css("display", "none");
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    clickLabelStops() {
        if (!this.selectRoute) {
            Swal.fire('Uyarı', 'Hat Seçmediniz.', 'warning')
        }
        this.routeStopsList = true;
        this.lineHoursList = false;
        this.stopHoursList = false;
        this.feeTariffs = false;
        this.smartStations = false;
        this.routeListByStop = false;
        this.changeStyle("label-stops", "label-line-hours", "label-fee-tariffs");
    }

    getData(body: lineHoursParams) {
        this.changeStyle("label-line-hours", "label-stops", "label-fee-tariffs");
        var lenght = this.hoursDataList.length;
        this.hoursDataList.splice(0, lenght);
        this.lineHoursService.GetLineHours(body).subscribe((res) => {
            this.lineHours = res;
            this.lineHoursResult = res.result;
            this.create(this.lineHours);
        });

    }

    clickLineHours() {
        if (this.cityCode == "31") {
            if (this.utilities.getCookie("OtobüsümNerede") != "true") {
                Swal.fire({
                    title: 'Bilgilendirme',
                    text: 'Seçtiğiniz hatta ait otobüslerin ilk duraktan kalkış saatlerini görebileceksiniz. Yön değiştir butonuna dokunarak gidiş ve dönüş yönündeki saatleri ayrı ayrı takip edebilir, seferlerinizi buna göre planlayabilirsiniz. Yaşanan yoğun pandemi koşulları sebebi ile hat hareket saatleri düzensizlik gösterebilir.',
                    icon: 'warning',
                    input: 'checkbox',
                    inputPlaceholder: 'Bir daha gösterme',
                    showCancelButton: false,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (result.value) {
                            this.utilities.setCookie("OtobüsümNerede", "true", 300);
                        }
                    }
                })
            }
        }
        this.lineHoursList = true;
        this.stopHoursList = false;
        this.routeStopsList = false;
        this.feeTariffs = false;
        this.smartStations = false;
        this.routeListByStop = false;
        let body = new lineHoursParams();
        body.routeCode = this.selectRoute;
        body.dayType = 0,
            body.isFirstStations = true;
        body.direction = this.direction == "Gidiş" ? "G" : "D";
        body.hour = "";
        this.getData(body);

    }

    clickFeeTariffs() {
        this.spinnerService.show();
        this.getRoutePrice();
        this.lineHoursList = false;
        this.stopHoursList = false;
        this.routeStopsList = false;
        this.feeTariffs = true;
        this.smartStations = false;
        this.routeListByStop = false;
        this.changeStyle("label-fee-tariffs", "label-line-hours", "label-stops");
    }

    changeStyle(class1, class2, class3) {
        this.$("." + class1).css("color", "white");
        this.$("." + class1).css("background-color", "black");
        this.$("." + class1).css("border", "7px solid black");
        this.$("." + class2).css("color", "#404040");
        this.$("." + class2).css("background-color", "#EBEBEB");
        this.$("." + class2).css("border", "7px solid #EBEBEB");
        this.$("." + class3).css("color", "#404040");
        this.$("." + class3).css("background-color", "#EBEBEB");
        this.$("." + class3).css("border", "7px solid #EBEBEB");
    }

    lineHoursColor(time) {
        let color;
        if (time < this.dateNow)
            color = "red";
        else
            color = "#F2F2F2";
        return color;
    }

    clickLabelOncomingBuses(latitude, longitude) {
        navigator.geolocation.getCurrentPosition(a => {
            this.lng = longitude;
            this.lat = latitude;
        });
        this.stopshow = true;
        this.stopLatitude = latitude;
        this.stopLongitude = longitude;
        this.changeStyle("label-stops", "label-line-hours", "label-stop-hours");
        this.smartStations = true;
        this.routeListByStop = false;
        this.stopHoursList = false;
        this.spinnerService.show();
    }

    clickLabelAllBuses() {
        this.changeStyle("label-line-hours", "label-stops", "label-stop-hours");
        this.smartStations = false;
        this.routeListByStop = true;
        this.stopHoursList = false;
        this.GetRouteListByStopId(this.smartStationStationId);
    }

    clickLabelStopHours() {
        var date = new Date();
        var days = [7, 1, 2, 3, 4, 5, 6];
        let day = days[date.getDay()];
        this.dayNo = day;
        this.changeStyle("label-stop-hours", "label-stops", "label-line-hours");
        this.smartStations = false;
        this.routeListByStop = false;
        this.stopHoursList = true;
        this.clickDayFunction(day);
    }

    stopDetail(stationId, stationName, latitude, longitude) {
        this.smartStationStationId = stationId;
        const center = new google.maps.LatLng(latitude, longitude);
        this.map.setCenter(center);
        this.map.setZoom(18);
        this.$(".checkbox-list-stops").css("display", "block");
        this.$(".checkbox-list").css("display", "none");
        this.$(".select-box").val(stationName);
        this.$('#test').attr('src', ('/assets/img/logo/dot2.png'));
        this.routeStopsList = false;
        this.clickLabelOncomingBuses(latitude, longitude);
    }

    convertTime(time: string) {
        var newTime = time.split(':');
        if (newTime[0] != "00") {
            return newTime[0] + " sa " + newTime[1] + " dk";
        }
        else {
            return newTime[1] + " dk";
        }

    }
    convertStopTime(time: string) {
        var newTime = time.split(':');
        return newTime[0] + ":" + newTime[1];
    }

    selectClass(value) {
        if (value == 2) {
            return 'li-black';
        }
        else if (value == 1) {
            return 'li-active';
        }
        else if (value == 0) {
            return 'li-passive';
        }
    }

    selectButtonClass(value) {
        var date = new Date();
        var days = [7, 1, 2, 3, 4, 5, 6];
        let day = days[date.getDay()];
        if (value == day) {
            return 'weekDayActive';
        }
        else {
            return 'weekDay';
        }
    }

    clickChange() {
        if (this.ring == "G")
            this.ring = "D";
        else if (this.ring == "D")
            this.ring = "G"
        else {
            Swal.fire('Uyarı', 'Seçtiğiniz hat Ring hattıdır. Yön değişimi yapılamaz.', 'warning')
            this.ring = "R";
        }
        this.GetRouteHours(this.ring);
    }

    selectPng(index) {
        if (index == 0)
            return "/assets/img/logo/StartIcon.png";
        else if (index == this.forwardStation.length - 1)
            return "/assets/img/logo/StopIcon.png";
        else
            return "/assets/img/logo/dot2.png";
    }

    mapReady(map) {
        this.map = map;
    }

    selectDay(value) {
        var date = new Date();
        var days = [7, 1, 2, 3, 4, 5, 6];
        let day = days[date.getDay()];
        if (value == day) {
            return 'day current'
        }
        else {
            return 'day';
        }
    }

    selectRotation(yon) {
        return { "transform": "rotate(" + (yon) + "deg)" };
    }
    cancelFromWhereIsMyBus() {
        this.$(".route-stops").css("display", "none");
        this.$(".show-details").css("display", "block");
    }
    showDetails() {
        this.$(".route-stops").css("display", "block");
        this.$(".show-details").css("display", "none");
    }

    ChangeCoordinate(direction) {
        if (direction == "Gidiş") {
            this.changes = "D"
            this.direction = "Dönüş";
        }
        else {
            this.direction = "Gidiş";
            this.changes = "G"
        }
        this.clickLineHours();
    }

}
