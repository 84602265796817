import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { updateUserProfileResult, updateUserRequest, updatePassword } from '../shared/classes/Settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetUserProfile(parameter: updateUserRequest) : Observable<updateUserProfileResult> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<updateUserProfileResult>(this.localUrl + "account/updateuserprofilecore", parameter,{ headers: headers });
  }

  GetChangeUserPassword(userId: number, newPassword: string, availablePass: string, sessionTicket: string) : Observable<updatePassword> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<updatePassword>(this.localUrl + "account/updatepasswordcore", {"userId": userId, "newPassword": newPassword, "oldPassword": availablePass, "sessionTicket":sessionTicket}, { headers: headers });
  }
}
