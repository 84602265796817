import { Component, OnInit, Inject } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { WhereMyBusService } from 'src/app/services/wheremybus';
import { Observable } from 'rxjs';
import { whereBus, whereBusResult } from 'src/app/shared/classes/wherebus';
import { lostPropertyParams, lostPropertyResult } from 'src/app/shared/classes/LostProperty';
import { param } from 'src/app/shared/classes/Howtogo';
import { LostpropertyService } from 'src/app/services/lostproperty.service';
import { MatSnackBar } from '@angular/material';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { FormControl } from '@angular/forms';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { SocialLoginModel } from 'src/app/shared/classes/SocialLogin';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-lostproperty',
  templateUrl: './lostproperty.component.html',
  styleUrls: ['./lostproperty.component.scss']
})
export class LostpropertyComponent implements OnInit {

  lostPropertyResult: lostPropertyResult[]
  routes: Observable<whereBus>;
  isReady: boolean = true;
  isSelected: boolean = false;
  myControl = new FormControl();
  searchText;
  line: any;
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private model: SocialLoginModel;

  constructor(private authService: AuthServices, private whereBusService: WhereMyBusService,
    private lostPropertyService: LostpropertyService, private _snackBar: MatSnackBar,
    private spinnerService: Ng4LoadingSpinnerService, @Inject(JQ_TOKEN) private $: any) {
    this.model = new SocialLoginModel();
  }

  ngOnInit() {
    this.authService.changeMenu();
    this.GetRoutes();
  }

  GetRoutes() {
        this.routes = this.whereBusService.GetRouteCodes();
  }

  butonSend(name: string, Surname: string, phoneNumber: string, email: string, endDate: any, leaveStation: string, property: string, note: string) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      Swal.fire('Uyarı', 'Lütfen geçerli E-Posta adresi giriniz.', 'warning')
    }
    else if(phoneNumber.length < 14){
      Swal.fire('Uyarı', 'Lütfen girmiş olduğunuz telefon numarınızı kontrol ediniz.', 'warning')
    }
    else {
      if (this.getErrorMessage(name, Surname, phoneNumber, email, property) == "success") {
        this.spinnerService.show();
        let params = new lostPropertyParams();
        params.IdentityNumber = "";
        params.NameSurname = name + " " + Surname;
        params.Phone = phoneNumber;
        params.Email = email;
        params.LineNo = this.line == undefined ? "" : this.line;
         params.GetOnDate = endDate + "Z";
        params.LeaveStation = leaveStation;
        params.DefinedStuff = property;
        params.Notes = note;
            this.lostPropertyService.GetLineHours(params).subscribe((res) => {
              this.spinnerService.hide();
              this.lostPropertyResult = res.result;
              if (res.message == "Success") {
                Swal.fire('Başarılı', 'Kayıp eşya bildiriminiz yetkililere bildirildi. En kısa sürede verdiğiniz bilgiler üzerinden sizinle iletişime geçilecektir.', 'success')
              }
              else {
                Swal.fire('Başarısız', 'Bir hata oluştu.', 'error')
              }
            });
      }
    }
  }

  unselect() {
    this.$(".select-lost-property").css("background-image", "url(/assets/images/downarrow.png)");
    this.$(".select-lost-property").val('');
    this.searchText = '';
    this.isSelected = false;
  }

  selectStation(location: whereBusResult) {
    this.isSelected = true;
    this.line = "'" +location.hatNo + "'";
    this.$(".select-lost-property").css("background-image", "none");
  };

  getErrorMessage(name, surname, phone, email, property) {
    if (name == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (surname == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (phone == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (email == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (this.line == undefined)
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (property == "")
      Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
      
    else
      return "success";
  }
}
