import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { PostToken } from 'src/app/shared/auth';
import { AuthServices } from 'src/app/services/auth.service';
import { HowtoGoService } from 'src/app/services/howtogo.service';
import { Observable } from 'rxjs';
import { Plan, PlanResponse, Itinerary, Leg, LegParam, RouteCoordinate, Coordinates, WalkData, Stops, RootObject, param, From, To, StopsResult, LegBusItem, parameters, CardTypesResult, TramParam, MetroParam, LegWalkItem, LegTramItem, LegMetroItem } from 'src/app/shared/classes/Howtogo';
import { Utilities } from 'src/app/shared/utitlities';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { KeyValue } from 'src/app/shared/classes/KeyValue';
import { request } from 'http';
import { DatePipe } from '@angular/common';
import { SelectWithSearchOptionComponent } from '../select-with-search-option/select-with-search-option.component';
import { MapComponent } from '../map/map.component';
import { start } from 'repl';
import { PoiZoneTypesResult } from 'src/app/shared/classes/PoiZoneTypes';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MapCommunicationService } from '../../services/communication-services/map-communication.service';
declare var google;

@Component({
  selector: 'app-howtogo',
  templateUrl: './howtogo.component.html',
  styleUrls: ['./howtogo.component.scss'],
  providers: [DatePipe]
})
export class HowtogoComponent implements OnInit {
  @ViewChild('tabContent') el: ElementRef;

  title: string = 'Asis Map';
  lat: number;
  lng: number;

  zoom: number = 14;
  icon: string = '/assets/img/logo/bus.png';
  startIcon: string = '/assets/img/logo/start1.png';
  endIcon: string = '/assets/img/logo/bayrak.png';
  stopIcon: string = '/assets/img/logo/stop16.png';
  walkIcon: string = '/assets/img/logo/walk.png';
  lineStartPoint: string = '/assets/img/logo/dot2.png';

  myControl = new FormControl();
  Coordinate: string;
  dataRefresher: any;
  routes: Observable<PlanResponse>;
  legParams: LegParam[]
  legBusItems: Array<LegBusItem>
  legWalkItems: Array<LegWalkItem>
  legTramItems: Array<LegTramItem>
  legMetroItems: Array<LegMetroItem>
  walkDatas: Array<WalkData>
  TramvayData: Array<TramParam>
  MetroData: Array<MetroParam>
  markerCoordinate: Coordinates[]
  howtogoResponse: Observable<RouteCoordinate>;
  legs: Leg[];
  stops: Observable<KeyValue[]>;
  selectedTab = 0;
  selectStartPoint: string;
  selectEndPoint: string;
  selectedPoint: string;
  startCoordinate: string;
  endCoordinate: string;
  startCoord: string;
  endCoord: string;
  groupList: param;
  jsonData: string[];
  startData: Coordinates[];
  endData: Coordinates[];
  startCoordinates: string;
  endCoordinates: string;
  activate: boolean = false;
  firtsplaceholder: string = "Nereden?";
  lastplaceholder: string = "Nereye gitmek istiyorsunuz?";
  firstChange: boolean = true;//Daha sonra açılacak
  lastChange: boolean = false;
  picture: string;
  dateNow: string;
  isSelected: boolean = false;
  openedWindow: number;
  spinnerMessage: string;
  dateTime: boolean = false;
  selectCardType: boolean = false;
  changeDate: string;
  currentLat: number;
  currentLng: number;
  oldId: string;
  firstInputText: string;
  secondInputText: string;
  counter = 0;
  private utilities: Utilities;
  timeoutId = 0;
  seeMoreCheck: boolean = true;
  bestRoute;
  itinerarie;
  legsDetail: boolean = false;
  index: number;
  date: any;
  walk: any;
  options: any;
  time;
  isArriveBy: boolean = false;
  isChecked: any;
  cityCode: string;
  @ViewChild(SelectWithSearchOptionComponent) searchOption: SelectWithSearchOptionComponent;

  ngOnInit() {
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.jsonData = data as string[];
        this.lat = this.jsonData[0]['lat'];
        this.lng = this.jsonData[0]['lng'];
        this.picture = this.jsonData[0]['picture'];
        this.cityCode = this.jsonData[0]['cityCode'];
        if (this.cityCode == "31") {
          (this.utilities.getCookie("NasılGiderim") != "true")
          Swal.fire({
            title: 'Bilgilendirme',
            text: 'Harita üzerinde otobüse binmek ve inmek isteyeceğiniz konumlara sağ tıklayarak seçebilir ve o noktalardan geçen otobüsleri görebilirsiniz. Ayrıca durak isimleri yazarak da bu işlemi gerçekleştirebilirsiniz.',
            icon: 'warning',
            input: 'checkbox',
            inputPlaceholder: 'Bir daha gösterme',
            showCancelButton: false,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              if (result.value) {
                this.utilities.setCookie("NasılGiderim", "true", 300);
              }
            }
          })
        }
        this.mapCommunicationService.addCoordinates([this.lng, this.lat])
      });
    navigator.geolocation.getCurrentPosition(a => {
      this.currentLat = a.coords.latitude;
      this.currentLng = a.coords.longitude;
    }, error => {
      this.currentLat = 0;
      this.currentLng = 0;
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    });

    (<HTMLInputElement>document.getElementById("time")).defaultValue = this.datePipe.transform(new Date(), 'HH:mm');
    // (<HTMLInputElement>document.getElementById("datetime")).defaultValue = this.datePipe.transform(new Date());
    var dateControl = <HTMLInputElement>document.querySelector('input[type="date"]');
    dateControl.value = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.dateNow = this.datePipe.transform(new Date(), 'yyyy-MM-dd ') + (<HTMLInputElement>document.getElementById("time")).value;
    this.GetStops();

    this.mapCommunicationService.addParent('howToGo');

    this.mapCommunicationService.getDirective().subscribe(res => {
      switch (res.directive) {
        case "rightClickStart":
          this.mapRightClickMap(res.directiveNeeds);
          this.startPoint();
          break;
        case "rightClickEnd":
          this.mapRightClickMap(res.directiveNeeds);
          this.endPoint();
          break;
      }
    })
  }

  changeTime() {
    this.dateNow = this.datePipe.transform(new Date(), 'yyyy-MM-dd ') + (<HTMLInputElement>document.getElementById("time")).value;
    this.time = (<HTMLInputElement>document.getElementById("time")).value;
  }

  onChange(value) {
    if (value.checked == true) {
      this.$(".stop-hide").css("display", "none");
    }
  };

  checkValue(event: any) {
    if (event == "A") {
      this.$("#departure").text("Varış");
    }
    else {
      this.$("#departure").text("Kalkış");
    }
  }

  constructor
    (private authService: AuthServices,
      private howtogoService: HowtoGoService,
      @Inject(JQ_TOKEN) private $: any,
      private httpService: HttpClient,
      private spinnerService: Ng4LoadingSpinnerService,
      private mapCommunicationService: MapCommunicationService,
      private datePipe: DatePipe
    ) {
    this.legParams = new Array<LegParam>();
    this.legBusItems = new Array<LegBusItem>();
    this.legTramItems = new Array<LegTramItem>();
    this.legMetroItems = new Array<LegMetroItem>();
    this.walkDatas = new Array<WalkData>();
    this.TramvayData = new Array<TramParam>();
    this.MetroData = new Array<MetroParam>();
    this.markerCoordinate = new Array<Coordinates>();
    this.utilities = new Utilities();
  }

  GetStops() {
    let param = new parameters();
    param.enlem = this.currentLat;
    param.boylam = this.currentLng;

    this.stops = this.howtogoService.GetStops()
      .map(x => x.result.map(
        p => { return <KeyValue>{ Key: (p.latitude) + "," + (p.longitude), Value: p.description, StopId: p.stationId } }));
  }

  ChangeCoordinate(start, end) {
    this.$("#" + this.oldId).attr("src", "/assets/img/logo/" + this.oldId + ".png");
    this.selectCardType = false;
    this.dateTime = false;
    var oldStartCoordinate;
    var oldEndCoordinate;

    if ((start != undefined && start != "Harita Konumu") && (end != undefined && end != "Harita Konumu")) {
      this.counter++;
      if (this.counter % 2 == 1) {
        this.startCoord = this.firstInputText;
        this.endCoord = this.secondInputText;
      }
      else {
        this.startCoord = this.secondInputText;
        this.endCoord = this.firstInputText;
      }
    }
    else if ((start == "" || start == undefined || start == "Harita Konumu") && (end == "" || end == undefined || end == "Harita Konumu")) {
      this.counter = 0;
    }

    if ((start == "" || start == undefined) && (end == "" || end == undefined)) {
      this.startCoord = this.secondInputText;
      this.endCoord = this.firstInputText;
      oldStartCoordinate = this.startCoordinates;
      oldEndCoordinate = this.endCoordinates;
    }
    else if (start == "Harita Konumu" && (end == "" || end == undefined)) {
      this.startCoord = this.secondInputText;
      this.endCoord = "Harita Konumu";
      oldStartCoordinate = this.startCoordinate;
      oldEndCoordinate = this.endCoordinates;
    }
    else if ((start == "" || start == undefined) && end == "Harita Konumu") {
      this.startCoord = "Harita Konumu";
      this.endCoord = this.firstInputText;
      oldStartCoordinate = this.startCoordinates;
      oldEndCoordinate = this.endCoordinate;
    }
    else {
      oldStartCoordinate = this.startCoordinate;
      oldEndCoordinate = this.endCoordinate;
    }
    this.startCoordinate = oldEndCoordinate;
    this.endCoordinate = oldStartCoordinate;
    let startData = this.markerCoordinate.filter(item => item.startLat != "");
    this.markerCoordinate.splice(this.markerCoordinate.findIndex(item => item.startLat != ""), startData.length);
    let endData = this.markerCoordinate.filter(item => item.endLat != "");
    this.markerCoordinate.splice(this.markerCoordinate.findIndex(item => item.endLat != ""), endData.length);
    this.CreateMarker(this.startCoordinate, "");
    this.CreateMarker("", this.endCoordinate);
    if (this.startCoordinate == "" || this.endCoordinate == "") {
      return;
    }
    else {
      this.mapCommunicationService.addDirective({
        directive: 'changeHowToGoStops',
        directiveNeeds: undefined
      })
      // this.GetRoutes(this.startCoordinate, this.endCoordinate, 1, this.dateNow, 0);
    }
  }

  centeralize(coordinate: string) {
    navigator.geolocation.getCurrentPosition(a => {
      this.zoom = 10;
      this.lng = +this.utilities.splitCoordinateLon(coordinate);
      this.lat = +this.utilities.splitCoordinateLat(coordinate);
    });
  }

  onSelectionChangedStart(value: KeyValue) {
    this.firstInputText = value.Value;
    this.centeralize(value.Key);
    this.startCoordinates = value.Key;
    this.startData = this.markerCoordinate.filter(item => item.startLat != "");
    this.markerCoordinate.splice(this.markerCoordinate.findIndex(item => item.startLat != ""), this.startData.length);
    this.selectStartPoint = this.selectedPoint;
    this.CreateMarker(value.Key, "");

    let _coordinates = [+value.Key.split(',')[1], +value.Key.split(',')[0]];

    let _directiveNeeds = {
      icon: this.startIcon,
      coordinates: _coordinates,
      StopId: value.StopId,
      StopTitle: value.Value,
      zoom: 10,
      pointOrder: 'startPoint'
    };

    this.mapCommunicationService.addDirective({
      directive: "showStartPoint",
      directiveNeeds: _directiveNeeds
    })



    if (this.endCoordinates) {
      this.clickButtonRightClick(this.startCoordinates, this.endCoordinates);
    }
    else if (this.endCoordinate) {
      this.clickButtonRightClick(this.startCoordinates, this.endCoordinate);
    }
    else {
      return;
    }
  }

  searchChange(param) {

  }

  onSelectionChangedFinish(value: KeyValue) {
    this.secondInputText = value.Value;
    // this.centeralize(value.Key);
    this.endCoordinates = value.Key;
    this.endData = this.markerCoordinate.filter(item => item.endLat != "");
    this.markerCoordinate.splice(this.markerCoordinate.findIndex(item => item.endLat != ""), this.endData.length);
    this.selectEndPoint = this.selectedPoint;
    this.CreateMarker("", value.Key);

    let _coordinates = [+value.Key.split(',')[1], +value.Key.split(',')[0]];

    let _directiveNeeds = {
      icon: this.endIcon,
      coordinates: _coordinates,
      StopId: value.StopId,
      StopTitle: value.Value,
      zoom: 10,
      pointOrder: 'endPoint'
    };

    this.mapCommunicationService.addDirective({
      directive: "showEndPoint",
      directiveNeeds: _directiveNeeds
    })


    if (this.startCoordinates) {
      this.clickButtonRightClick(this.startCoordinates, this.endCoordinates);
    }
    else if (this.startCoordinate) {
      this.clickButtonRightClick(this.startCoordinate, this.endCoordinates);
    }
    else {
      return;
    }
  }

  startPoint() {
    let startData = this.markerCoordinate.filter(item => item.startLat != "");
    this.markerCoordinate.splice(this.markerCoordinate.findIndex(item => item.startLat != ""), startData.length);
    this.startCoord = "Harita Konumu";
    this.startCoordinate = this.selectedPoint;
    this.CreateMarker(this.startCoordinate, "");

    if (this.endCoord)
      this.clickButtonRightClick(this.startCoordinate, this.endCoord);
    else if (this.endCoordinates)
      this.clickButtonRightClick(this.startCoordinate, this.endCoordinates);
    else
      return;
  }

  endPoint() {
    if (this.selectedTab != 0) {
      // this.toaster.show('warning', 'Uyarı', 'Planlama alanına geri dönünüz!!');
      return;
    }
    let endData = this.markerCoordinate.filter(item => item.endLat != "");
    this.markerCoordinate.splice(this.markerCoordinate.findIndex(item => item.endLat != ""), endData.length);
    this.endCoord = "Harita Konumu";
    this.endCoordinate = this.selectedPoint;
    this.CreateMarker("", this.endCoordinate);
    if (this.startCoordinates)
      this.clickButtonRightClick(this.startCoordinates, this.endCoordinate);

    else if (this.startCoordinate)
      this.clickButtonRightClick(this.startCoordinate, this.endCoordinate);

    else
      return;


  }

  mapRightClickMap($event) {
    this.selectedPoint = $event.coords.lat + ", " + $event.coords.lng;
  }


  saveChanges(date, walk, options) {
    this.date = date;
    this.walk = walk;
    this.options = options;
    if (this.$('arrivedBy').prop('checked') == false) {
      this.isArriveBy = false;
    }
    else {
      this.isArriveBy = true;
    }
  }

  // onChangeOption(value, dateTimes, startCoor, endCoor, event) {
  //   if (startCoor == "Harita Konumu")
  //     startCoor = this.startCoordinate;
  //   else
  //     startCoor = this.startCoordinates;
  //   if (endCoor == "Harita Konumu")
  //     endCoor = this.endCoordinate;
  //   else
  //     endCoor = this.endCoordinates;
  //   this.$("#" + this.oldId).attr("src", "/assets/img/logo/" + this.oldId + ".png");
  //   if (startCoor == undefined || endCoor == undefined) {
  //   }
  //   else {
  //     this.$("#" + event.target.id).attr("src", "/assets/img/logo/" + event.target.id + "_black" + ".png");
  //     this.oldId = event.target.id;
  //     if (value == 5) {
  //       this.dateTime = true;
  //       this.selectCardType = false;
  //     }
  //     else if (value == 0) {
  //       this.dateTime = false;
  //       this.selectCardType = true;
  //     }
  //     else {
  //       this.spinnerService.show();
  //       this.spinnerMessage = "En İyi Rota Oluşturuluyor";
  //       this.dateTime = false;
  //       this.selectCardType = false;
  //       this.GetRoutes(startCoor, endCoor, value, this.dateNow, 0);
  //     }
  //   }
  // }

  GetRoutes(QueryParam1, QueryParam2, value, DateTime, cardTypeId) {
    this.zoom = 12;
    if (QueryParam1 == "Harita Konumu")
      QueryParam1 = this.startCoordinate;
    else if (QueryParam1 != "")
      QueryParam1 = QueryParam1;
    else
      QueryParam1 = this.startCoordinates;
    if (QueryParam2 == "Harita Konumu")
      QueryParam2 = this.endCoordinate;
    else if (QueryParam2 != "")
      QueryParam2 = QueryParam2;
    else
      QueryParam2 = this.endCoordinates;
    this.Coordinate = QueryParam1 + "&to=" + QueryParam2;
    let parameters = new param();
    let from = new From();
    let to = new To();

    parameters.IsArriveBy = this.isArriveBy;
    parameters.NumItineraries = 5;
    parameters.Mode = "TRANSIT,WALK";
    if (this.date == "")
      parameters.Time = this.dateNow + (<HTMLInputElement>document.getElementById("time")).value;
    else
      parameters.Time = this.date != undefined ? this.utilities.replaceDate(this.date) + " " + (<HTMLInputElement>document.getElementById("time")).value : this.dateNow;
    parameters.CardTypeId = 150;
    parameters.MaxWalkDistance = this.walk != undefined ? this.walk : 2000.0;
    parameters.From.Latitude = this.utilities.splitCoordinateLat(QueryParam1);
    parameters.From.Longitude = this.utilities.splitCoordinateLon(QueryParam1);
    parameters.To.Latitude = this.utilities.splitCoordinateLat(QueryParam2);
    parameters.To.Longitude = to.Longitude = this.utilities.splitCoordinateLon(QueryParam2);
    parameters.IsOrderBy = this.options != undefined ? this.options : 3;
    this.routes = this.howtogoService.GetData(parameters);

    this.routes.subscribe(request => {
      if (request == null) {
        this.activate = true;
        this.selectedTab = 0;
        this.spinnerService.hide();
        Swal.fire('Uyarı', 'Seçtiğiniz noktalar arasında bir güzergah bulunamadı.', 'warning')
      }
      else if (request.plan != null) {
        this.bestRoute = request.plan.itineraries.length;
        this.bestRoute > 1 ? this.seeMoreCheck = false : this.seeMoreCheck = true;
        if (!request.plan.itineraries[0]) {
          Swal.fire('Uyarı', 'Seçtiğiniz noktalar arasında bir güzergah bulunamadı.', 'warning')
        }
        else {
          this.clickRoute(request.plan.itineraries[0], 0, false);
          if (window.matchMedia('(max-width: 767px)').matches) {
            this.$(".side-bar").css("height", "110%");
          }
        }
        // this.seeMoreCheck = false;
      }
      else {
        this.selectedTab = 0;
        this.spinnerService.hide();
        Swal.fire('Uyarı', 'Seçtiğiniz noktalar arasında bir güzergah bulunamadı.', 'warning')
      }
    })
  }

  isInfoWindowOpen(servisId) {
    return this.openedWindow == servisId;
  }

  clickRoute(itinerarie: Itinerary, index, detail: boolean) {
    this.index = index;
    this.itinerarie = itinerarie;
    if (detail == true) {
      this.legsDetail = true;
      this.$(".route-detail").css("display", "none");
    }
    else {
      this.legsDetail = false;
      this.$(".route-detail").css("display", "block");
    }
    if (this.$('.cancelFromWhereısMyBus').css('display') == 'block') {
      this.cancelFromWhereIsMyBus();
    }
    this.$(".solution-item").css("background-color", "#EDEDED");
    this.$("#solution-item-t" + index).css("background-color", "#ccc");
    this.spinnerService.show();
    let lenght = this.legParams.length;
    let walkDataLenght = this.walkDatas.length;
    let tramvayDataLenght = this.TramvayData.length;
    let metroDataLenght = this.MetroData.length;
    this.legs = itinerarie.legs != undefined ? itinerarie.legs : null;
    if (this.legs == undefined)
      Swal.fire('Uyarı', 'Seçtiğiniz noktalar arasında bir güzergah bulunamadı.', 'warning')
    for (var i = 0; i < lenght; i++) {
      this.legParams.splice(i, lenght);
    }

    for (var i = 0; i < walkDataLenght; i++) {
      this.walkDatas.splice(i, walkDataLenght);
    }

    for (var i = 0; i < tramvayDataLenght; i++) {
      this.TramvayData.splice(i, tramvayDataLenght);
    }

    for (var i = 0; i < metroDataLenght; i++) {
      this.MetroData.splice(i, metroDataLenght);
    }

    for (var i = 0; i < this.legBusItems.length; i++) {
      this.legBusItems.splice(i, this.legBusItems.length);
    }
    itinerarie.legs.forEach(leg => {
      let legParam = new LegParam();
      let walkData = new WalkData();
      let legBusItem = new LegBusItem();
      let legWalkItem = new LegWalkItem();
      let legTramItem = new LegTramItem();
      let legMetroItem = new LegMetroItem();
      const decodePolyline = require('decode-google-map-polyline');
      if (leg.mode == "BUS") {
        var polyline = leg.geometry.points;
        legBusItem.routeCode = leg.route;
        legBusItem.fromstationName = leg.from.name;
        legBusItem.fromlat = leg.from.latitude;
        legBusItem.fromlng = leg.from.longitude;
        legBusItem.tostationName = leg.to.name;
        legBusItem.tolat = leg.to.latitude;
        legBusItem.tolng = leg.to.longitude;
        this.legBusItems.push(legBusItem);
        this.legParams.push(decodePolyline(polyline));
      }
      else if (leg.mode == "WALK") {
        var polyline = leg.geometry.points;

        this.walkDatas.push(decodePolyline(polyline));
      }
      else if (leg.mode == "TRAM") {
        var polyline = leg.geometry.points;
        legTramItem.routeCode = leg.route;
        legTramItem.stationName = leg.from.name;
        legTramItem.lat = decodePolyline(polyline)[0].lat;
        legTramItem.lng = decodePolyline(polyline)[0].lng;
        this.legTramItems.push(legTramItem);
        this.TramvayData.push(decodePolyline(polyline));
      }
      else if (leg.mode == "SUBWAY") {
        var polyline = leg.geometry.points;
        legMetroItem.routeCode = leg.route;
        legMetroItem.stationName = leg.from.name;
        legMetroItem.lat = decodePolyline(polyline)[0].lat;
        legMetroItem.lng = decodePolyline(polyline)[0].lng;
        this.legMetroItems.push(legMetroItem);
        this.MetroData.push(decodePolyline(polyline));
      }
    });


    this.mapCommunicationService.addDirective({
      directive: 'showRoutesHowToGo',
      directiveNeeds: itinerarie
    })



    this.spinnerService.hide();
  }

  clickButton() {
    this.spinnerService.show();
    this.spinnerMessage = "En İyi Rota Oluşturuluyor";
    this.$("#" + this.oldId).attr("src", "/assets/img/logo/" + this.oldId + ".png");

    this.GetRoutes(this.startCoordinates, this.endCoordinates, 1, this.dateNow, 0);
  };

  clickButtonRightClick(startCoordinates: string, endCoordinates: string) {
    // this.spinnerService.show();
    // this.spinnerMessage = "En İyi Rota Oluşturuluyor";
    this.$("#" + this.oldId).attr("src", "/assets/img/logo/" + this.oldId + ".png");
    this.startCoordinates = startCoordinates;
    this.endCoordinates = endCoordinates;
    // this.GetRoutes(startCoordinates, endCoordinates, 1, this.dateNow, 0);
  };

  CreateMarker(QueryParam1, QueryParam2) {
    let coordinates = new Coordinates();

    coordinates.startLat = this.utilities.splitCoordinateLat(QueryParam1);
    coordinates.startLon = this.utilities.splitCoordinateLon(QueryParam1);

    coordinates.endLat = this.utilities.splitCoordinateLat(QueryParam2);
    coordinates.endLon = this.utilities.splitCoordinateLon(QueryParam2);

    this.markerCoordinate.push(coordinates);
  }

  DeleteMarker(componentType: number) {
    if (componentType == 2) {
      this.secondInputText = "";
      this.mapCommunicationService.addDirective({
        directive: 'clearHowToGoPoint',
        directiveNeeds: 'endPoint'
      })
    }
    else if (componentType == 1) {


      this.mapCommunicationService.addDirective({
        directive: 'clearHowToGoPoint',
        directiveNeeds: 'startPoint'
      })

      this.firstInputText = "";
      for (var i = 0; i < this.legParams.length; i++) {
        this.legParams.splice(i, this.legParams.length);
      }

      for (var i = 0; i < this.walkDatas.length; i++) {
        this.walkDatas.splice(i, this.walkDatas.length);
      }

      for (var i = 0; i < this.TramvayData.length; i++) {
        this.TramvayData.splice(i, this.TramvayData.length);
      }

      for (var i = 0; i < this.MetroData.length; i++) {
        this.MetroData.splice(i, this.MetroData.length);
      }

      this.routes = null;
    }

    if (componentType == 3) {
      for (var i = 0; i < this.markerCoordinate.length; i++) {
        this.markerCoordinate.splice(i, this.markerCoordinate.length);
      }
    }
    this.legsDetail = false;
    this.deleteCoordinates(componentType);
  }

  deleteCoordinates(componentType: number) {

    if (componentType == 1) {
      this.startCoord = "";
      this.startCoordinates = "";
      this.markerCoordinate.forEach(element => {
        element.startLat = "";
        element.startLon = "";
      });
    }
    else if (componentType == 2) {
      this.endCoord = "";
      this.endCoordinates = "";
      this.markerCoordinate.forEach(element => {
        element.endLat = "";
        element.endLon = "";
      });
    }
    else {
      this.startCoord = "";
      this.startCoordinates = "";
      this.markerCoordinate.forEach(element => {
        element.startLat = "";
        element.startLon = "";
      });
      this.endCoord = "";
      this.endCoordinates = "";
      this.markerCoordinate.forEach(element => {
        element.endLat = "";
        element.endLon = "";
      });
    }
  }

  routeTypes(index) {
    if (this.bestRoute > 1) {
      if (index == 0)
        return "EN HIZLI ROTA";
      else if (index == 1)
        return "ALTERNATİF ROTA";
      else
        return "";
    }
    else {
      return "EN İYİ ROTA";
    }
  }

  convertMilisecondToTime(ms2, ms1) {
    var minute2 = Math.floor((ms2 / 1000) / 60);
    var minute1 = Math.floor((ms1 / 1000) / 60);
    var minute = minute2 - minute1;
    var hours;
    var minutes;
    hours = Math.floor(minute / 60);
    minutes = minute % 60;
    if (hours == 0) {
      return minute + " dk";
    }
    else {
      return hours + " Saat " + minutes + " dk";
    }
  }

  convertSecondToTime(duration1, duration2) {
    var totalDuration = duration1 + duration2;
    var time = Math.floor((totalDuration / 60));
    if (time > 60) {
      var newTime = Math.floor((time / 60));
      return newTime + " sa" + time + " dk";
    }
    else if (time < 1) {
      return totalDuration + " sn";
    }
    else {
      return time + " dk";
    }
  }

  convertMsToTime(ms) {
    return this.utilities.msToTime(ms);
  }

  convertMilisecondToMinute(ms) {
    return Math.floor((ms / 1000) / 60);
  }

  convertMinuteToTime(minute) {

  }

  distance(mode, distance, route) {
    if (mode == "WALK") {
      return this.utilities.splitDistance2(distance) + " m";
    }
    else
      return route;
  }

  distance2(mode, distance, route, starttime, endtime) {
    if (mode == "WALK") {
      return this.utilities.splitDistance2(distance) + " m";
    }
    else
      return this.convertMilisecondToTime(starttime, endtime);
  }

  convertSecondToMinute(s) {
    return Math.floor((s) / 60);
  }

  convertMilliSecondsToMinute(s) {
    return Math.floor((s / (1000 * 60)) % 60);
  }

  replaceRouteName(routeName: string) {
    return this.utilities.replaceRouteName(routeName);
  }

  ConvertPrice(price) {
    return this.utilities.PriceFormat(price);
  }

  selectIconPath(mode) {
    if (mode == "BUS")
      return 'icon icon-bus';
    else if (mode == "TRAM")
      return 'icon icon-metro';
    else if (mode == "SUBWAY")
      return 'icon icon-metro';
    else if (mode == "WALK")
      return 'icon icon-pedestrian-walking';
  }

  selectIconPath2(mode) {
    if (mode == "BUS")
      return 'icon icon-bus font-size-24';
    else if (mode == "TRAM")
      return 'icon icon-metro font-size-24';
    else if (mode == "SUBWAY")
      return 'icon icon-metro font-size-24';
    else if (mode == "WALK")
      return 'icon icon-pedestrian-walking font-size-24';
  }

  selectClass(mode) {
    if (mode == "BUS")
      return 'solution-detail-vehicle';
    else if (mode == "TRAM")
      return 'solution-detail-vehicle';
    else if (mode == "SUBWAY")
      return 'solution-detail-vehicle';
    else if (mode == "WALK")
      return 'solution-detail-walk';
  }

  selectClassMode(mode) {
    if (mode == "BUS")
      return 'step flex bus';
    else if (mode == "TRAM")
      return 'step flex metro';
    else if (mode == "SUBWAY")
      return 'step flex metro';
    else if (mode == "WALK")
      return 'step flex walk';
  }

  displayMapOverBus() {
    this.$(".routes").animate({ width: 450 }, 200);
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".routes").css("display", "block");
      this.$(".routes").animate({ width: "90%" }, 200);
    }
    if (window.matchMedia('(max-width: 1024px)').matches) {
      this.$(".routes").css("display", "block");
    }
    this.$(".map-over-slider-up-howtogo").show();
    this.$(".map-over-slider-down-howtogo").hide();
  }

  hideMapOverBus() {
    this.$(".routes").animate({ width: 0 }, 200);
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".routes").css("display", "none");
    }
    if (window.matchMedia('(max-width: 1024px)').matches) {
      this.$(".routes").css("display", "none");
    }
    this.$(".map-over-slider-up-howtogo").hide();
    this.$(".map-over-slider-down-howtogo").show();
  }

  cancelFromWhereIsMyBus() {
    this.$(".route-stops").css("display", "none");
    this.$(".side-bar").css("height", "30%");
    this.$(".show-routes").css("display", "block");
  }

  showRoutes() {
    this.$(".route-stops").css("display", "block");
    this.$(".side-bar").css("height", "100%");
    this.$(".show-routes").css("display", "none");
  }

  routeDescription(route: Leg) {
    if (route.mode == "WALK") {
      if (route.to.name == "Destination")
        return 'Varış noktasına yürüyün.';
      else
        return route.to.name + ' durağına yürüyün.';
    }
    else if (route.mode == "BUS")
      return route.from.name + ' durağından ' + route.route + ' otobüsüne binin.' + route.to.name + ' durağında inin.'
    else if (route.mode == "SUBWAY")
      return route.from.name + ' durağından ' + route.route + ' binin.' + route.to.name + ' durağında inin.'
    else if (route.mode == "TRAM")
      return route.from.name + ' durağından ' + route.route + ' binin.' + route.to.name + ' durağında inin.'
  }

  clickCancel() {
    this.$(".route-detail").css("display", "block");
    this.legsDetail = false;
  }

  showOtherRoutes() {
    var index = this.index == 0 ? 1 : 0;
    this.spinnerService.show();
    this.spinnerMessage = "";
    this.routes.subscribe((res => {
      this.clickRoute(res.plan.itineraries[index], index, true);
      this.spinnerService.hide();
    }))
  }

  selectStepClass(mode) {
    if (mode == "BUS")
      return 'step flex bus';
    else if (mode == "TRAM")
      return 'step flex metro';
    else if (mode == "SUBWAY")
      return 'step flex metro';
    else if (mode == "WALK")
      return 'step flex';
  }

  routeTypes2() {
    if (this.bestRoute > 1) {
      if (this.index == 0)
        return "EN HIZLI ROTA YOLCULUK TARİFİ";
      else if (this.index == 1)
        return "ALTERNATİF ROTA YOLCULUK TARİFİ";
      else
        return "";
    }
    else {
      return "EN İYİ ROTA";
    }
  }

}
