export class QrCodeParam{
    IssuedTerminal: number;
    Type: number;
    regionCode: number;
    channelCode: string;
}

export class GetQrCode{
    version: number;
    statusCode: number;
    message: string;
    result: CodeResult;
}

export class CodeResult{
    QrCodeId: string;
    QrCodePrice: number;
    QrInformation: InfResult[];
}

export class InfResult{
    IssueData: any;
    AliasNo: number;
    IssuedTerminal: number;
    Type: number;
    RegionCode: number;
    Version: number; 
}


export class QrCodeTicketTypes{
    version: number;
    statusCode: number;
    message: string;
    result: QrCodeTicketTypesResult[]; 
}

export class QrCodeTicketTypesResult{
    id: number;
    typeId: number;
    typeName: string;
    regionId: number;
    regionName: string;
    price: number;
    commission: number;
}

export class StartQrOrderParam{
    userId: number;
    amount: number;
    comission: number;
    sessionId: number;
    clientType: number;
    maskedCardNumber: string;
    ticketTypeId: number;
    ticketRegionId: number;
    ticketCount: number;
    mpMacroMerchantId: string;
    sessionTicket: string;
}

export class StartQrOrderParamV2{
    deviceId: string;
    amount: number;
    comission: number;
    sessionId: number;
    clientType: number;
    maskedCardNumber: string;
    ticketTypeId: number;
    ticketRegionId: number;
    ticketCount: number;
    email: string;
    phone: string;
}

export class StartQrOrder{
    Version: number;
    StatusCode: number;
    Message: string;
    result: StartQrOrderResult; 
}

export class StartQrOrderResult{
    orderNumber: string;
    recId: number;
}

export class UserQrList{
    version: number;
    statusCode: number;
    message: string;
    result: UserQrListResult[]; 
}

export class UserQrListResult{
    qrTicketId: number;
    qrCode: string;
    qrCodePrice: number;
    activeFrom: Date;
    activeTo: Date;
    ticketTypeId: number;
    ticketTypeName: string;
    ticketRegionId: number;
    ticketRegionName: string;
    validStatus: number;
    isLogin: boolean;
}