import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthServices } from './auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../components/login/login.component';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public router: Router, public auth: AuthServices, private modalService: NgbModal, private _snackBar: MatSnackBar) { }

  canActivate (route: ActivatedRouteSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
       const modalRef = this.modalService.open(LoginComponent);
      this.router.navigate([''], {queryParams : { routeUrl:route.url}});
       return false;
    }
    return true;
  }

}
