import { Component, OnInit } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { createUser, createUserValue } from 'src/app/shared/classes/CreateUser';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.scss']
})
export class CreateuserComponent implements OnInit {

  crtuser: Observable<createUser>;


  constructor(private authService: AuthServices, private loginService: LoginService) { }

  ngOnInit() {
    this.authService.changeMenu();
  }

  

  clickButton(EPosta: string, Ad: string, Soyad: string, TelNo: any, Pass: any) {
    
        let createUserParam = new createUserValue();

        // createUserParam.tcKimlikNo = TcNo;
        createUserParam.userCode = EPosta;
        createUserParam.firstName = Ad;
        createUserParam.secondName = "";
        createUserParam.lastName = Soyad;
        createUserParam.pass = Pass;
        createUserParam.email = EPosta;
        createUserParam.mobile = TelNo;
        createUserParam.mifareID = "";

        this.crtuser = this.loginService.GetCreateUser(createUserParam);

        this.crtuser.subscribe((request) => {
          var result = request.result;
          if (result[0].userId > 0) {
            Swal.fire('Başarılı', 'Kullanıcı oluşturulmuştur.', 'success')
          }
          else if (result[0].userId = -1) {
            Swal.fire('Başarısız', 'Girdiğiniz Bilgilere Sahip Başka Bir Kullanıcı Mevcut.', 'error')
          }
        })
  }

}
