import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { visaControl } from '../shared/classes/Visa';
import { StartTopupOrderParamDifferentCard, StartTopupOrder, StartTopupOrderParam, checkVisaParameters, CommitVisaOrderParameters, CommitVisaOrder } from '../shared/classes/CardOperations';

@Injectable({
  providedIn: 'root'
})
export class VisaService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  CheckVisaByCardNo(parameters: checkVisaParameters): Observable<visaControl> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<visaControl>(this.localUrl + "visa/checkvisabycardno", parameters, { headers: headers });
  }

  GetStartVisaOrderVakifBankGuest(parameter: StartTopupOrderParamDifferentCard): Observable<StartTopupOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartTopupOrder>(this.localUrl + "Payment/StartVisaOrderVakifBankGuest", parameter, { headers: headers });
  }

  GetStartVisaOrder(parameter: StartTopupOrderParam): Observable<StartTopupOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartTopupOrder>(this.localUrl + "visa/startvisaorder", parameter, { headers: headers });
  }

  GetStartVisaOrderVakifBank(parameter: StartTopupOrderParam): Observable<StartTopupOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartTopupOrder>(this.localUrl + "Payment/StartVisaOrderVakifBank", parameter, { headers: headers });
  }

  GetCommitVisaOrder(parameters: CommitVisaOrderParameters) : Observable<CommitVisaOrder>  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<CommitVisaOrder>(this.localUrl + "visa/commitvisaorder", parameters,{ headers: headers });
  }
}
