import { Component, OnInit, ViewChild, SimpleChanges, OnChanges, Inject, OnDestroy } from '@angular/core';
import { PostToken, Token } from 'src/app/shared/auth';
import { FormControl } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { station, stationResult, smartStations, smartStationResult, stationRoutes, stationRoutesResult, nearStationsParameters, nearStationsResult } from 'src/app/shared/classes/smartstop';
import { Observable } from 'rxjs/internal/Observable';
import { SmartstopService } from 'src/app/services/smartstop.service';
import { TimeoutError, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval } from 'rxjs/observable/interval';
import { trigger, transition, style, animate } from '@angular/animations';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { HowtoGoService } from 'src/app/services/howtogo.service';
import { KeyValue } from 'src/app/shared/classes/KeyValue';
import { Utilities } from 'src/app/shared/utitlities';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatSnackBar } from '@angular/material';
import { Stops, StopsResult } from 'src/app/shared/classes/Howtogo';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { WhereMyBusService } from 'src/app/services/wheremybus';
import { MapCommunicationService } from '../../services/communication-services/map-communication.service';
import { busLocationResult, ForwardStation, randomDataResult } from 'src/app/shared/classes/wherebus';
import { finalize, takeUntil } from 'rxjs/operators';
declare var google;


@Component({
  selector: 'app-smartstop',
  templateUrl: './smartstop.component.html',
  styleUrls: ['./smartstop.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class SmartstopComponent implements OnInit, OnDestroy {
  title: string = 'Asis Map';
  lat: number;
  lng: number;
  zoom: number = 14;
  icon: string = '/assets/img/logo/durak.png';
  busIcon: string = '/assets/img/logo/bus32.png';
  searchText;
  myControl = new FormControl();
  selectedSmartStation: stationResult;
  selectedSmartStationData: smartStationResult;
  smartstopResponse: Observable<station>;
  smartStationResponse: Observable<smartStations>;
  stationRoutes: stationRoutesResult[];
  dataRefresher: any;
  jsonData: string[];
  length: number;
  openedWindow: number;
  smartStationRes: smartStationResult[];
  stops: Stops;
  iconLat: number;
  iconLon: number;
  placeholder: string = "Durak Seçin..";
  display: boolean = false;
  picture: string;
  selectStationId;
  selectedStationName;
  map: any;
  currentLat;
  currentLng;
  query;
  cityCode: string;
  destroyed$ = new Subject();

  private utilities: Utilities;

  constructor(
    private authService: AuthServices,
    private whereBusService: WhereMyBusService,
    private smartStopService: SmartstopService,
    @Inject(JQ_TOKEN) private $: any,
    private httpService: HttpClient,
    private howtogoService: HowtoGoService,
    private spinnerService: Ng4LoadingSpinnerService,
    private mapCommunicationService: MapCommunicationService,
    private _snackBar: MatSnackBar
  ) {
    this.utilities = new Utilities();
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.authService.changeMenu();
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.jsonData = data as string[];
        this.lat = this.jsonData[0]['lat'];
        this.lng = this.jsonData[0]['lng'];
        this.picture = this.jsonData[0]['picture'];
        this.cityCode = this.jsonData[0]['cityCode'];
        if (this.cityCode == "31") {
          if (this.utilities.getCookie("AkıllıDurak") != "true") {
            Swal.fire({
              title: 'Bilgilendirme',
              text: 'Seçtiğiniz durağa dokunarak o durağa hangi otobüsün ne kadar süre sonra geleceğini takip edebilir, durağa git butonuna dokunarak o durağa gidiş için yol tarifini görebilirsiniz. ',
              icon: 'warning',
              input: 'checkbox',
              inputPlaceholder: 'Bir daha gösterme',
              showCancelButton: false,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (result.value) {
                  this.utilities.setCookie("AkıllıDurak", "true", 300);
                }
              }
            })
          }
        }
        this.mapCommunicationService.addCoordinates([this.lng, this.lat])
      });
    navigator.geolocation.getCurrentPosition(a => {
      this.currentLat = a.coords.latitude;
      this.currentLng = a.coords.longitude;
      this.GetStopsService();
    }, error => {
      this.currentLat = "36.345201";
      this.currentLng = "36.211386";
      this.GetStopsService();
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    })

    this.mapCommunicationService.getDirective()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        let stop;
        switch (res.directive) {
          case 'onClickStopDetail':
            stop = this.stops.result.find(x => x.stationId == res.directiveNeeds);
            this.clickCenter(stop);
            break;
          case 'onClickStopNavigation':
            stop = this.stops.result.find(x => x.stationId == res.directiveNeeds);
            this.Direction(stop);
            break;

          default:
            break;
        }
      })
  }

  valuechange(test) {

  }

  clear() {
    for (var i = 0; i < this.length; i++) {
      this.smartStationRes.splice(i, this.length);
    }
  }

  refreshData(stopId) {
    clearInterval(this.dataRefresher);
    // this.GetSmartStationData(stopId);
    this.dataRefresher =
      setInterval(() => {
        if (this.length > 0) {
          this.clear();
        }
        // this.GetSmartStationData(stopId);
      }, 8000);
  }

  GetStopsService() {
    let parameters = new nearStationsParameters();
    parameters.latitude = this.currentLat;
    parameters.longitude = this.currentLng;
    parameters.maxDistance = "5000";
    this.howtogoService.GetStops()
      .pipe(
        finalize(() => {
          let _directiveNeeds: any[] = [];
          let _locations: randomDataResult[] = this.stops.result.map(x => {
            return {
              stationId: x.stationId,
              latitude: +x.latitude,
              longitude: +x.longitude,
              stationName: x.description,
              aciklama: null,
              hatNo: null,
              kod: null,
              type: null
            }
          });

          _directiveNeeds.push({
            stations: _locations,
            icon: this.icon,
          })
          this.mapCommunicationService.addDirective({
            directive: 'showSmartStops',
            directiveNeeds: _directiveNeeds
          })
        })
      )
      .subscribe(res => {
        this.stops = res
      });
  }

  clickCenter(stops: StopsResult) {
    this.$(".bussearch-area").css("display", "none");
    this.$(".d-none").css("display", "block");
    this.GetStationData(stops.stationId);
    this.selectStationId = stops.stationId;
    this.selectedStationName = stops.description;
    this.mapCommunicationService.addDirective(
      {
        directive: 'panTo',
        directiveNeeds: stops
      }
    )
  }

  clickBack() {
    this.$(".bussearch-area").css("display", "block");
    this.$(".d-none").css("display", "none");
  }

  mapReady(map) {
    this.map = map;
  }

  Direction(route: nearStationsResult) {
    navigator.geolocation.getCurrentPosition(a => {
      this.currentLat = a.coords.latitude;
      this.currentLng = a.coords.longitude;
      window.open("https://www.google.com.tr/maps/dir/" + a.coords.latitude + ", " + a.coords.longitude + "/" + route.latitude + ", " + route.longitude, "_blank");
    }, error => {
      this.currentLat = 0;
      this.currentLng = 0;
    }, {
      timeout: 1000,
      maximumAge: 10000,
      enableHighAccuracy: true
    })
  }

  GetStationData(stopId) {
    this.smartStationResponse = this.whereBusService.GetSmartStationData(stopId);
    this.smartStationResponse
      .subscribe((request) => {
        this.smartStationRes = request.result;
        this.spinnerService.hide();
        if (this.smartStationRes.length == 0) {
          Swal.fire('Uyarı', 'Seçtiğiniz durağa ait otobüs bulunmamaktadır.', 'warning')
        }
        if (!request) {
          this.GetStationData(stopId);
        }
      });
  }

  clickLabelAllBuses() {
    this.smartStopService.GetSmartStationRoutes(this.selectStationId).subscribe((request) => {
      this.stationRoutes = request.result;
      this.spinnerService.hide();
      if (this.smartStationRes.length > 0)
        this.display = true;
    });
  }

  GetSmartStationData(stopId) {

    this.smartStationResponse = this.smartStopService.GetSmartStationDataService(stopId);
    this.smartStationResponse.subscribe((request) => {
      this.smartStationRes = request.result;
      this.spinnerService.hide();
      if (this.smartStationRes.length > 0)
        this.display = true;
    });

  }

  isInfoWindowOpen(servisId) {
    return this.openedWindow == servisId;
  }



}
