import { BrowserModule } from '@angular/platform-browser';
import { StorageServiceModule } from 'angular-webstorage-service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShContextMenuModule } from 'ng2-right-click-menu';
import 'rxjs/add/operator/map';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { ModalModule } from "ng2-modal";
import { SuiModule } from 'ng2-semantic-ui';

import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatDividerModule, } from '@angular/material';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MapComponent } from './components/map/map.component';
import { FillingcenterComponent } from './components/fillingcenter/fillingcenter.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { AgmOverlays } from "agm-overlays"
import { SmartstopComponent } from './components/smartstop/smartstop.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WheremybusComponent } from './components/wheremybus/wheremybus.component';
import { FooterComponent } from './components/footer/footer.component';
import { JQ_TOKEN } from './shared/jQuery.service';
import { HowtogoComponent } from './components/howtogo/howtogo.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
// import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CreateuserComponent } from './components/createuser/createuser.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CardoperationsComponent } from './components/cardoperations/cardoperations.component';
// import { SwiperModule } from 'angular2-useful-swiper';
import { ProfileComponent } from './components/profile/profile.component';
import { ModalService } from './services/modal.services';
import { ModalComponent } from './_directives';
import { LostpropertyComponent } from './components/lostproperty/lostproperty.component';
import { CardvisaComponent } from './components/cardvisa/cardvisa.component';
import { QrCodeComponent } from './components/qrcode/qrcode.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SelectWithSearchOptionComponent } from './components/select-with-search-option/select-with-search-option.component';
import { NgxMaskModule } from 'ngx-mask'
import { IConfig } from 'ngx-mask/lib/config';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { TextMaskModule } from 'angular2-text-mask';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// import { ToasterService } from './services/toaster.service';
import {
  SwiperModule, SwiperConfigInterface,
  SWIPER_CONFIG
} from 'ngx-swiper-wrapper';

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular5-social-login";
import { ActivationComponent } from './components/activation/activation.component';
import { DatePipe } from '@angular/common';
import { SearchPipe } from './search.pipe';
import { SuccessurlComponent } from './components/successurl/successurl.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SocketIoModule, SocketIoConfig } from 'ng-socket-io';
import { SocketService } from './services/socket.service';
import { Routes, RouterModule } from '@angular/router';
import { GuestpaymentComponent } from './components/guestpayment/guestpayment.component';
import { OlMapComponent } from './components/ol-map/ol-map.component';
// const config: SocketIoConfig = { url: 'http://localhost:4200', options: {} };


export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
    [
      // {
      //   id: FacebookLoginProvider.PROVIDER_ID,
      //   // provider: new FacebookLoginProvider("723893134782560")
      //   provider: new FacebookLoginProvider("1683677951800652")

      // },
      // {
      // id: GoogleLoginProvider.PROVIDER_ID,
      //provider: new GoogleLoginProvider("1016994148249-cvijokf575kprvsj6899n1ms5a213uu4.apps.googleusercontent.com")//local
      //  provider: new GoogleLoginProvider("769719159517-l9ghoi008fa4fvemldi0aksedm12v1nf.apps.googleusercontent.com")//prod
      //  provider: new GoogleLoginProvider("333852124075-rkvm6q0ium9gsi115berfd9slf84hv5p.apps.googleusercontent.com")//canlıBursa
      // },
    ]
  );
  return config;
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  navigation: false,
  centeredSlides: true
};

const jQuery = window['$'];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    FooterComponent,
    MapComponent,
    FillingcenterComponent,
    SmartstopComponent,
    WheremybusComponent,
    HowtogoComponent,
    LoginComponent,
    // UnauthorizedComponent,
    CreateuserComponent,
    ForgotpasswordComponent,
    CardoperationsComponent,
    ProfileComponent,
    ModalComponent,
    LostpropertyComponent,
    CardvisaComponent,
    QrCodeComponent,
    SelectWithSearchOptionComponent,
    ActivationComponent,

    // ToasterContainerComponent, 
    // ToasterComponent, 
    SearchPipe, SuccessurlComponent, SettingsComponent, GuestpaymentComponent, OlMapComponent,
  ],
  imports: [
    BrowserModule,
    StorageServiceModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    Ng2SearchPipeModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatTabsModule,
    BrowserAnimationsModule,
    ShContextMenuModule,
    MatDialogModule,
    SwiperModule,
    ModalModule,
    NgxMatSelectSearchModule,
    QRCodeModule,
    SuiModule,
    AgmOverlays,
    AngularDateTimePickerModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgbModule.forRoot(),
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyD-q27G9uS6Ro06AUMHAQgW_GTLvIeJoMw',
      //Elazığ
      // apiKey: 'AIzaSyCQU4nIfEWOOPIdW-MysHZom1RLcsLU3FU',
      //Hatay
      // apiKey: 'AIzaSyAdc0k0nHDHCOtTEu3VvaDOyVSh1ftkFnI',
      //Kütahya
      // apiKey: 'AIzaSyCUblXMOLmvSNFJB0jU-mNzTaNxwJJhOuQ',
      //Van
      // apiKey: 'AIzaSyABeUBX5RnM31UzCH-dEeEexa8hRF3h7xo',
      libraries: ['geometry']
    }),
    AgmDirectionModule,
    NgxMaskModule.forRoot(maskConfig),
    AgmJsMarkerClustererModule,
    SocialLoginModule,
    // RouterModule.forRoot(appRoutes)
  ],
  entryComponents: [
    LoginComponent
  ],
  providers: [
    { provide: 'url', useValue: "" },
    //-------------------------------------------------------------------------*Prod Ortamı*-------------------------------------------------------------------------
    { provide: 'local', useValue: "https://belvankart.van.bel.tr/api/" },
    //  { provide: 'local', useValue: "https://www.hataykart.com/api/" },
    // { provide: 'local', useValue: "http://10.240.52.31:4202/api/" },
    // { provide: 'local', useValue: "https://elazigkart.elazig.bel.tr/api/" },
    // { provide: 'local', useValue: "http://10.240.52.32:4202/api/" },
    // { provide: 'local', useValue: "https://kutahyakartapi.abys-web.com/api/" },
    // { provide: 'local', useValue: "http://10.240.1.144:4201/api/" },
    //-------------------------------------------------------------------------*Dev Ortamı*-------------------------------------------------------------------------
    //HatayKart
    // { provide: 'local', useValue: "http://10.240.1.13:4300/HatayKartGateway/" },
    //Kütahyakart
    // { provide: 'local', useValue: "http://10.240.1.13:4300/KütahyaKartGateway/" },
    //Elazığ
    // { provide: 'local', useValue: "http://10.240.1.13:4300/ElazigKartGateway/" },
    //Van
    // { provide: 'local', useValue: "http://10.240.1.13:4300/BelvanKartGateway/" },
    //Sakarya
    // { provide: 'local', useValue: "http://10.240.1.13:4300/SakaryaKartGateway/" },

    { provide: JQ_TOKEN, useValue: jQuery },
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
    AuthGuardService,
    // ConfirmationDialogService,
    SocketService,
    ModalService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    [DatePipe]
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}

