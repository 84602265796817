import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Token, FillingCenter2 } from '../shared/auth';
import { Observable } from 'rxjs';
import { cardOperations, cardAdd, addFavoriteCard, favoriteCard, bankResponse, CheckVisaOrderDetailResult, CheckVisaOrderDetail, cardInfo, SelectCityBankId, PaymetTransactionParameters, PaymentTransactionWeb, CommitVisaOrder, CommitVisaOrderParameters } from '../shared/classes/CardOperations';
import { parameters } from '../shared/classes/Howtogo';

@Injectable({
  providedIn: 'root'
})
export class CardoperationsService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetCardInformations() : Observable<cardOperations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<cardOperations>(this.localUrl + "card/usercardinfo", "{}",{ headers: headers });
  }

  GetCardInformation(sessionTicket: string) : Observable<cardOperations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<cardOperations>(this.localUrl + "card/cardinfocore", {"sessionTicket": sessionTicket},{ headers: headers });
  }

  GetAddCard(CardName: string, MifareId: string, UserId: number, isUpdated: number, sessionTicket: string) : Observable<cardAdd> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<cardAdd>(this.localUrl + "card/addusercard", {"mifareId": MifareId, "cardAlias": CardName, "userId": UserId, "isLock": false, "isUpdated": isUpdated, "sessionTicket":sessionTicket}, { headers: headers });
  }

  GetDeleteCard(CardName: string, MifareId: string, UserId: number, sessionTicket: string) : Observable<cardAdd> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<cardAdd>(this.localUrl + "card/addusercard", {"mifareId": MifareId, "cardAlias": CardName, "userId": UserId, "isLock": true,"sessionTicket":sessionTicket},{ headers: headers });
  }

  AddFavoriteCard(MifareId: string, UserId: number) : Observable<addFavoriteCard>{
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<addFavoriteCard>(this.localUrl + "card/addfavoritecard", {"mifareId": MifareId, "userId": UserId,},{ headers: headers });
  }
  
  GetFavoriteCard(UserId: number) : Observable<favoriteCard> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<favoriteCard>(this.localUrl + "card/favoritecardinfo", {"userId": UserId},{ headers: headers });
  }

  GetTransactionDetail(orderNo: string) : Observable<bankResponse>  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<bankResponse>(this.localUrl + "payment/ordertransaction", {"orderNumber": orderNo.toString()},{ headers: headers });
  }

  GetCheckVisaOrderDetail(orderNo: string) : Observable<CheckVisaOrderDetail>  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<CheckVisaOrderDetail>(this.localUrl + "payment/checkvisaorderdetail", {"orderNumber": orderNo.toString()},{ headers: headers });
  }

  GetCardInfo(MifareId: string) : Observable<cardInfo> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<cardInfo>(this.localUrl + "card/cardinfocore", {"keyword": MifareId},{ headers: headers });
  }

  GetSelectCityBankId(): Observable<SelectCityBankId> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var value = {"keyword": ""};
    return this.httpClient.post<SelectCityBankId>(this.localUrl + "purchase/selectcitybankid", value, { headers: headers });
  }

  GetPaymentTransaction(parameters: PaymetTransactionParameters): Observable<PaymentTransactionWeb> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<PaymentTransactionWeb>(this.localUrl + "purchase/paymenttransactionweb", parameters, { headers: headers });
  }

  
}
