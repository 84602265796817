import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { QrCodeParam, GetQrCode, CodeResult, QrCodeTicketTypesResult, StartQrOrder, StartQrOrderParam, UserQrListResult, UserQrList, StartQrOrderParamV2 } from 'src/app/shared/classes/QrCode';
import { Observable } from 'rxjs';
import { QrCodeService } from 'src/app/services/qrCode.service';
import {
  SwiperConfigInterface, SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UserpaymentService } from 'src/app/services/userpayment.service';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Utilities } from 'src/app/shared/utitlities';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Guid } from "guid-typescript";
import { updateUserRequest } from 'src/app/shared/classes/Settings';
import { SettingsService } from 'src/app/services/settings.service';
import { PaymetTransactionParameters } from 'src/app/shared/classes/CardOperations';
import { CardoperationsService } from 'src/app/services/cardoperations.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrCodeComponent implements OnInit {

  public data: any = [];
  public qrdata: string = null;
  getQrCode: Observable<GetQrCode>;
  codeResult: CodeResult;
  qrCodeTicketTypes: QrCodeTicketTypesResult[];
  qrCodeList: UserQrListResult[];
  qrCodeUserUsedList: UserQrListResult[];
  payment: boolean = false;
  qrCodeBack: boolean = true;
  qrCodeScreen: boolean = false;
  PaymentTicketType;
  PaymentPrice;
  totalPrice;
  userId: any;
  sessionTicket: any;
  name: any;
  surName: any;
  email: any;
  gsm: any;
  spinnerMessage: string;
  typeId;
  regionId;
  jsonData: string[];
  status: string;
  ACSUrl: string;
  PAReq: any;
  TermUrl: string;
  MD: string;
  oldTickets: boolean;
  newTickets: boolean
  swiperQrCode;
  swiperRegionName;
  swiperTicketType;
  swiperPrice;
  swiperDate;
  swiperTicketNumber;
  swiperQrCode3;
  swiperRegionName3;
  swiperTicketType3;
  swiperPrice3;
  swiperDate3;
  activeTo;
  swiperTicketNumber3;
  private utilities: Utilities;
  imageUrl;
  smallImageUrl;
  paymentImageText1;
  paymentImageText2;
  qrlist: UserQrListResult[];
  multipleQrCodeScreenMultiple: boolean = false;
  success: boolean;
  qrData;
  oldTicketWarning: boolean = false;
  newTicketWarning: boolean = false;
  ticketNumber: number;
  message: string = null;
  oldTicketMessage: string = null;
  isLogin: boolean;
  ticketTypes;
  ticketTypes2;
  merchantId: string;
  qrCodeAddress: string;
  selectedYear: number;
  years: number[] = [];  

  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 2,
    spaceBetween: 14,
    centeredSlides: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 14
      }
    },
    pagination: {
      el: '.ticket-slider .swiper-pagination',
      clickable: true
    }
  };


  public config2: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      hideOnClick: false,
    }
  };

  public config3: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    spaceBetween: 14,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      hideOnClick: false,
    }
  };

  // @HostListener('document:mouseover', ['$event'])
  //   mouseover(event) {
  //         this.$(this).attr("org_title", this.$(this).attr('title'));
  //         this.$(this).attr('title', '');
  //   }

  @ViewChild('Screen') myDiv: ElementRef;

  constructor(private authService: AuthServices, private qrCodeService: QrCodeService,
    @Inject(JQ_TOKEN) private $: any, @Inject(SESSION_STORAGE) private storage: WebStorageService,
     private spinnerService: Ng4LoadingSpinnerService, private httpService: HttpClient,
    private userpaymentService: UserpaymentService, public datepipe: DatePipe,
    private ngxXml2jsonService: NgxXml2jsonService, private route: ActivatedRoute, private settingsService: SettingsService,
    private router: Router, private cardOperations: CardoperationsService,) {
    this.utilities = new Utilities();
    
    this.selectedYear = new Date().getFullYear();
    for (let year = this.selectedYear; year <= this.selectedYear + 10; year++){
      this.years.push(year);
    }
  }

  ngOnInit() {
    this.httpService.get('../assets/GeneralInformation.json').subscribe(
      data => {
        this.jsonData = data as string[];
        this.qrCodeAddress = this.jsonData[0]['qrCodeAddress'];
      });
    this.authService.changeMenu();
    this.GetMerchantId();
    var id;
    this.ticketNumber = 1;
    this.route.queryParams
      .filter(params => params.Id)
      .subscribe(params => {
        id = params.Id;
      });
    this.userId = this.getFromLocal("userId");
    this.sessionTicket = this.getFromLocal("sessionTicket");
    this.name = this.getFromLocal("userName");
    this.surName = this.getFromLocal("userLName");
    this.email = this.getFromLocal("email");
    this.gsm = this.getFromLocal("gsm");
    this.userId = this.getFromLocal("userId");
    if (this.userId == 0 || this.userId == null) {
      this.userId = undefined;
        Swal.fire({
          title: 'Uyarı',
          text: 'Bu işlem için üye girişi yapmanız gerekmektedir.',
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'Anladım',
          confirmButtonText: 'Üye Girişi'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/login/QrCode']);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.router.navigate(['/smartstop']);
          }
        })
    }
    else {
      this.userId = this.userId;
    }

    if (id) {
      this.qrCodeScreen = false;
      this.payment = false;
      this.qrCodeBack = false;
      // this.multipleQrCodeScreenMultiple = true;
      this.QrByOrderNo(id);

    }
    this.getQrCodeTicketTypes();
    this.readyTickets();
    // else {
    //   if (this.userId > 0) {
    //     this.getQrCodeTicketTypes();
    //   } else {
    //     Swal.fire('Uyarı', 'Ekrana ulaşmak için giriş yapmalısınız.', 'warning')
    //     this.router.navigate(["login", "QrCode"]);
    //   }
    // }
  }

  GetMerchantId() {
      this.cardOperations.GetSelectCityBankId().subscribe(res => {
        this.merchantId = res.result.macroMerchantId;
      })
  }

  getQrCodeTicketTypes() {
        this.qrCodeService.GetQrCodeTicketTypes().subscribe((res) => {
          this.qrCodeTicketTypes = res.result;
          // this.qrCodeTicketTypes.sort(function (a, b) {
          //   if (a.RegionName > b.RegionName) { return -1; }
          //   if (a.RegionName < b.RegionName) { return 1; }
          //   return 0;
          // })
          this.qrData = this.getQrCodeTicketTypes;
          this.clickNewTicket();
        })
      
  }

  onIndexChange(index) {

  }

  onIndexChangeSwiper2(index) {
    if(this.qrCodeList){
    this.swiperQrCode = this.qrCodeList[index].qrCode;
    this.swiperRegionName = this.qrCodeList[index].ticketRegionName;
    this.swiperTicketType = this.qrCodeList[index].ticketTypeName;
    this.swiperPrice = this.formatAmount(this.qrCodeList[index].qrCodePrice);
    this.activeTo = this.formatDate(this.qrCodeList[index].activeTo);
    this.swiperDate = this.formatDate(this.qrCodeList[index].activeFrom);
    this.swiperTicketNumber = this.qrCodeList[index].qrTicketId;
    this.newTickets = true;
  }
  }

  onIndexChangeSwiper3(index) {
    this.swiperQrCode3 = this.qrlist[index].qrCode;
    this.swiperRegionName3 = this.qrlist[index].ticketRegionName;
    this.swiperTicketType3 = this.qrlist[index].ticketTypeName;
    this.swiperPrice3 = this.formatAmount(this.qrlist[index].qrCodePrice);
    this.activeTo = this.formatDate(this.qrlist[index].activeTo);
    this.swiperDate3 = this.formatDate(this.qrlist[index].activeFrom);
    this.swiperTicketNumber3 = this.qrlist[index].qrTicketId;
  }

  selectTicket(ticketType, price, typeId, regionId, regionName, commission) {
    // if (!this.gsm) {
    //   Swal.fire({
    //     title: 'Telefon Numaranızı Giriniz',
    //     input: 'text',
    //     inputAttributes: {
    //       autocapitalize: 'off'
    //     },
    //     showCancelButton: true,
    //     confirmButtonText: 'Kaydet',
    //     cancelButtonText: 'İptal',
    //     showLoaderOnConfirm: true,
    //     allowOutsideClick: () => !Swal.isLoading()
    //   }).then((result) => {
    //     if (result.value) {
    //       var phone = `${result.value.login}`;
    //       this.setGsmNumber(phone);
    //     }
    //   })
    // }
    // if (regionName == "HAYVANAT BAHÇESİ") {
    //   this.imageUrl = "/assets/img/blt1.png";
    //   this.smallImageUrl = "/assets/img/zoo.png"
    //   this.paymentImageText1 = "HAYVANAT";
    //   this.paymentImageText2 = "BAHÇESİ"
    // }
    // else if (regionName == "BURSA MERKEZ") {
    //   this.imageUrl = "/assets/img/logo/cityPayment.jpg";
    //   this.smallImageUrl = "/assets/img/logo/cami.png"
    //   this.paymentImageText1 = "Şehir";
    //   this.paymentImageText2 = "Merkezi";
    // }
    // else if (regionName == "UZUN HAT BİLET") {
    //   this.imageUrl = "/assets/img/hb1.png";
    //   this.smallImageUrl = ""
    //   this.$(".imageUrl").css("display", "none");
    //   this.paymentImageText1 = "UZUN HAT";
    //   this.paymentImageText2 = "BİLET";
    // }
    // else if (regionName == "KISA HAT BİLET") {
    //   this.imageUrl = "/assets/img/hb2.png";
    //   this.smallImageUrl = ""
    //   this.$(".imageUrl").css("display", "none");
    //   this.paymentImageText1 = "KISA HAT";
    //   this.paymentImageText2 = "BİLET";
    // }
    if(ticketType == "TAM"){
      this.imageUrl = "/assets/img/hb1.png";
      this.smallImageUrl = ""
    }
    else if(ticketType == "İNDİRİMLİ"){
      this.imageUrl = "/assets/img/hb2.png";
      this.smallImageUrl = ""
    }
    this.PaymentTicketType = ticketType;
    this.PaymentPrice = price + commission;
    this.totalPrice = this.PaymentPrice;
    this.payment = true;
    this.qrCodeBack = false;
    this.typeId = typeId;
    this.regionId = regionId;
  }

  setGsmNumber(gsm) {
        const parameters = new updateUserRequest();
        parameters.userId = this.userId;
        parameters.identity = "11111111111";
        parameters.name = this.name;
        parameters.lastName = this.surName;
        parameters.phone = gsm;
        parameters.email = this.email;
        parameters.sessionTicket = this.sessionTicket;
        this.settingsService.GetUserProfile(parameters).subscribe((res => {
          if (res) {
            if (res.updateResult == 5001)
              Swal.fire('Uyarı', 'Kullanıcı bulunamadı.', 'warning')
            else if (res.updateResult == 5003)
              Swal.fire('Başarısız', 'Bu E-Posta adresi kullanılıyor.', 'error')
            else if (res.updateResult == 5010)
              Swal.fire('Uyarı', 'Kullanıcı parametrelerini kontrol edin.', 'warning')
            else (res.updateResult == 1)
            {
              this.saveInLocal("gsm", gsm);
              this.authService.loggedProfile();
              Swal.fire('Başarılı', 'Kişisel bilgileriniz güncellenmiştir.', 'success')
            }
          }
        }));
  }

  clickOldTicket() {
    this.oldTicketsTab();
    this.oldTickets = true;
    this.newTickets = false;
    this.changeStyle("new-tickets", "old-tickets");
  }

  clickNewTicket() {
    this.readyTickets();
    this.oldTickets = false;
    this.newTickets = true;
    this.changeStyle("old-tickets", "new-tickets");

  }

  clickBack() {
    this.payment = false;
    this.qrCodeBack = true;
  }

  changeStyle(firstClass, secondClass) {
    this.$("." + firstClass).css("background-color", "#FFCD00");
    this.$("." + firstClass).css("border", "0px solid #ededed");
    this.$("." + firstClass).css("border-radius", "15px");
    this.$("." + secondClass).css("background-color", "#EDEDED");
  }

  readyTickets() {
    if (this.userId == 0 || this.userId == null || this.userId == undefined) {
      this.message = "Satın alınan biletleri görmek için giriş yapmalısınız."
    }
    else {
          this.qrCodeService.GetListUserQr(this.sessionTicket).subscribe((x) => {
            this.qrCodeList = x.result;
            if (this.qrCodeList.length == 0) {
              this.message = "Aktif QR Biletiniz Bulunmamaktadır."
            }
            else {
              this.newTicketWarning = false;
              this.onIndexChangeSwiper2(0);
              this.message = null;
            }
            this.spinnerService.hide();
          });
    }
  }

  oldTicketsTab() {
    if (this.userId == 0 || this.userId == null || this.userId == undefined) {
      this.oldTicketMessage = "Kullanılmış biletleri görmek için giriş yapmalısınız."
    }
    else {
          this.qrCodeService.GetListUserUsedQr(this.sessionTicket).subscribe((x) => {
            this.qrCodeUserUsedList = x.result;
            if (this.qrCodeUserUsedList.length > 0) {
              this.oldTicketWarning = false;
              this.oldTicketMessage = null;
            }
            else {
              this.oldTicketMessage = "Kullanılmış QR Biletiniz Bulunmamaktadır.";
              // this.oldTicketWarning = true;
            }
          });
    }
  }

  clickQrCode() {
    this.qrCodeScreen = true;
    this.payment = false;
    this.qrCodeBack = false;
    this.multipleQrCodeScreenMultiple = false;
  }

  useLater() {
    window.location.assign(this.qrCodeAddress);
  }

  QrByOrderNo(orderNo) {
        this.qrCodeService.GetQrByOrderNo(orderNo).subscribe((x) => {
          this.qrlist = x.result;
          if (x.result.length > 0) {
            this.multipleQrCodeScreenMultiple = true;
            this.success = false;
          }
          else {
            this.multipleQrCodeScreenMultiple = false;
            this.router.navigate(["/QrCode"]);
            this.qrCodeBack = true;
            Swal.fire('Başarısız', 'İşlem Başarısız.', 'error');
          }
          this.swiperQrCode3 = this.qrlist[0].qrCode;
          this.swiperRegionName3 = this.qrlist[0].ticketRegionName;
          this.swiperTicketType3 = this.qrlist[0].ticketTypeName;
          this.swiperPrice3 = this.formatAmount(this.qrlist[0].qrCodePrice);
          this.activeTo = this.formatDate(this.qrlist[0].activeTo);
          this.swiperDate3 = this.formatDate(this.qrlist[0].activeFrom);
          this.swiperTicketNumber3 = this.qrlist[0].qrTicketId;
          this.isLogin = this.qrlist[0].isLogin;
          if (this.qrlist[0].isLogin == false) {
            setTimeout(() => {
              this.downloadPdf(this.swiperRegionName3, this.swiperTicketType3, 2);
            }, 1000);
          }
        })

  }

  completePayment(name, cardNo, month, year, cvv) {
    let orderNumber;
    let EPosta = this.$(".Email").val();
    let Gsm = this.$(".Gsm").val();

    let numberofTickets = +this.$(".ticket-number").text();

    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (EPosta == undefined && Gsm == undefined) {
      if (name == "") {
        Swal.fire('Yetersiz Bilgi', 'Kredi Kartı Üzerindeki Ad Soyad Bilgisini Giriniz.', 'warning')
      }
      else if (cardNo == "") {
        Swal.fire('Yetersiz Bilgi', 'Kredi Kart Numarasını Giriniz.', 'warning')
      }
      else if (cvv == "") {
        Swal.fire('Yetersiz Bilgi', 'Cvv Numarasını Giriniz.', 'warning')
      }
      else if (this.$('.rules-class-info-qr').prop('checked') == false) {
        Swal.fire('Yetersiz Bilgi', 'Zorunlu Alanları İşaretleyiniz.', 'warning')
      }
      else if (this.$('.rules-class-KVKK-qr').prop('checked') == false) {
        Swal.fire('Yetersiz Bilgi', 'Zorunlu Alanları İşaretleyiniz.', 'warning')
      }
      else {
        this.spinnerService.show();
        this.spinnerMessage = "3D Sayfasına Yönlendirileceksiniz!";
        cardNo = this.removeSpecialCharacters(cardNo);
            let startQrOrder = new StartQrOrderParam();
            // startQrOrder.userId = this.userId == undefined ? "0" : this.userId;
            startQrOrder.sessionTicket = this.sessionTicket == undefined ? "0" : this.sessionTicket;
            startQrOrder.amount = this.totalPrice;
            startQrOrder.comission = 0;
            startQrOrder.sessionId = 1;
            startQrOrder.clientType = 1;
            startQrOrder.maskedCardNumber = cardNo;
            startQrOrder.ticketTypeId = this.typeId;
            startQrOrder.ticketRegionId = this.regionId;
            startQrOrder.ticketCount = this.ticketNumber;
            startQrOrder.mpMacroMerchantId = this.merchantId;
            this.qrCodeService.GetStartQrOrder(startQrOrder).subscribe((res) => {
              orderNumber = res.result.orderNumber;
              if (orderNumber == null || orderNumber == undefined)
                Swal.fire('Uyarı', 'Tekrar Deneyiniz.', 'warning')
              else
                this.Redirect3DScreen(cardNo, year, month, this.PaymentPrice * this.ticketNumber, cvv, orderNumber);
            });
      }
    }
    else {
      if (EPosta == 0) {
        Swal.fire('Yetersiz Bilgi', 'EPosta alanını giriniz.', 'warning')
      }
      else if (Gsm == 0) {
        Swal.fire('Yetersiz Bilgi', 'Telefon numarası alanını giriniz.', 'warning')
      }
      else if (reg.test(EPosta) == false) {
        Swal.fire('Uyarı', 'Geçerli bir E-Posta adresi giriniz.', 'warning')
      }
      else if (name == "") {
        Swal.fire('Yetersiz Bilgi', 'Kredi Kartı Üzerindeki Ad Soyad Bilgisini Giriniz.', 'warning')
      }
      else if (cardNo == "") {
        Swal.fire('Yetersiz Bilgi', 'Kredi Kart Numarasını Giriniz.', 'warning')
      }
      else if (cvv == "") {
        Swal.fire('Yetersiz Bilgi', 'Cvv Numarasını Giriniz.', 'warning')
      }
      else if (this.$('input.rules-class-info').prop('checked') == false) {
        Swal.fire('Yetersiz Bilgi', 'Zorunlu Alanları İşaretleyiniz.', 'warning')
      }
      else if (this.$('input.rules-class-KVKK').prop('checked') == false) {
        Swal.fire('Yetersiz Bilgi', 'Zorunlu Alanları İşaretleyiniz.', 'warning')
      }
      else {
        this.spinnerService.show();
        this.spinnerMessage = "3D Sayfasına Yönlendirileceksiniz!";
        cardNo = this.removeSpecialCharacters(cardNo);
            let startQrOrder = new StartQrOrderParamV2();
            startQrOrder.deviceId = Guid.create().toString();
            startQrOrder.amount = this.totalPrice;
            startQrOrder.comission = 0;
            startQrOrder.sessionId = 1;
            startQrOrder.clientType = 1;
            startQrOrder.maskedCardNumber = cardNo;
            startQrOrder.ticketTypeId = this.typeId;
            startQrOrder.ticketRegionId = this.regionId;
            startQrOrder.ticketCount = this.ticketNumber;
            startQrOrder.email = EPosta;
            startQrOrder.phone = Gsm;
            this.qrCodeService.GetStartQrOrderV2(startQrOrder).subscribe((res) => {
              orderNumber = res.result.orderNumber;
              if (orderNumber == null || orderNumber == undefined)
                Swal.fire('Uyarı', 'Tekrar Deneyiniz.', 'warning')
              else
                this.Redirect3DScreen(cardNo, year, month, this.PaymentPrice * this.ticketNumber, cvv, orderNumber);
            });
      }
    }
  }

  Redirect3DScreen(cardNo, year: string, month, amount, cvc, orderNumber) {
    let parameters = new PaymetTransactionParameters();
    parameters.cardNo = this.removeSpecialCharacters(cardNo);
    parameters.cardMonth = month;
    parameters.cardYear = year.substring(2, 4);
    parameters.cvv = cvc;
    parameters.orderNo = orderNumber;
      this.cardOperations.GetPaymentTransaction(parameters).subscribe(res => {
        this.spinnerService.hide();
        document.write(res.result.postForm);
        document.close();
      })
  }

  Bank3DSecure(cardNo, year: string, month, amount, cvc, orderNumber) {
    let PanStr = "Pan=" + cardNo;
    let ExpireDateStr = "&ExpiryDate=" + (year.substring(2) + month);
    let PurchaseAmountStr = "&PurchaseAmount=" + amount + ".00";
    let CurrencyStr = "&Currency=949"
    let BrandNameStr = "&BrandName=100"
    let VerifyEnrollmentRequestIdStr = "&VerifyEnrollmentRequestId=" + orderNumber;
    let SessionInfoStr = "&SessionInfo=" + cvc;
    let MerchantID = "&MerchantID=000000000903310";
    let MerchantPassword = "&MerchantPassword=y9E4Dmr8"
    let SuccessUrl = "&SuccessUrl=https://bursaapi.abys-web.com/Order/CheckBankOrder"
    let FailureUrl = "&FailureUrl=https://bursaapi.abys-web.com/Order/CheckBankOrder"
    let InstallmentCountStr = "&InstallmentCount="
    // let PanStr = "Pan%3D" + cardNo;
    // let ExpireDateStr = "%26ExpiryDate%3D" + (year.substring(2) + month);
    // let PurchaseAmountStr = "%26PurchaseAmount%3D" + amount + ".00";
    // let CurrencyStr = "%26Currency%3D949"
    // let BrandNameStr = "%26BrandName%3D100"
    // let VerifyEnrollmentRequestIdStr = "%26VerifyEnrollmentRequestId%3D" + orderNumber;
    // let SessionInfoStr = "%26SessionInfo%3D" + cvc;
    // let MerchantID = "%26MerchantID%3D000000000903534";
    // let MerchantPassword = "%26MerchantPassword%3Dy8K2Hta9"
    // let SuccessUrl = "%26SuccessUrl%3Dhttps://webservice.akillibiletim.com/AbysCoreApiv2/Order/CheckBankOrder"
    // let FailureUrl = "%26FailureUrl%3Dhttps://webservice.akillibiletim.com/AbysCoreApiv2/Order/CheckBankOrder"
    // let InstallmentCountStr = "%26InstallmentCount%3D"
    let all = PanStr + ExpireDateStr + PurchaseAmountStr + CurrencyStr + BrandNameStr + VerifyEnrollmentRequestIdStr + SessionInfoStr + MerchantID + MerchantPassword + SuccessUrl + FailureUrl + InstallmentCountStr

    var req = this.userpaymentService.GetBankService(all);

    req.subscribe((res => {
      this.xmlParse(res);
      if (this.status == 'Y') {
        this.print();
      }
      else if (this.status == 'N') {
        Swal.fire('Uyarı', 'Hatalı İşlem.', 'warning')
      }
      else {
        Swal.fire('Uyarı', 'Kredi kartı bilgileri hatalıdır, kontrol ediniz.', 'warning')
      }

    }));
  }

  print(): void {
    var postBackForm = `
    <html>
    <head>
    <title>GET724 MPI 3D-Secure Processing Page</title>
    </head>
    <body >
    <form name="downloadForm" action="%=ACSUrl%" method="POST">
    <noscript>
    <br>
    <br>
    <center>
    <h1>Processing your 3-D Secure Transaction</h1>
    <h2>
    JavaScript is currently disabled or is not supported
    by your browser.<br></h2>
    <h3>Please click Submit to continue
    the processing of your 3-D Secure
    transaction. Enrolled Returns true
    </h3>
    <input type="submit" value="Submit">
    </center>
    </noscript>
    <input type="hidden" name="PaReq" value="%=PAReq%">
    <input type="hidden" name="TermUrl" value="%=TermUrl%">
    <input type="hidden" name="MD" value="%=MD%">
    </form>
    <SCRIPT LANGUAGE="Javascript" >
    document.downloadForm.submit();
    </SCRIPT>
    </body>
    </html>`;

    postBackForm = postBackForm.replace("%=ACSUrl%", this.ACSUrl);
    postBackForm = postBackForm.replace("%=PAReq%", this.PAReq);
    postBackForm = postBackForm.replace("%=TermUrl%", this.TermUrl);
    postBackForm = postBackForm.replace("%=MD%", this.MD);
    this.spinnerService.hide();
    document.write(postBackForm);
    document.close();
  }

  xmlParse(xmlData: any) {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlData, 'text/xml');
    const obj = this.ngxXml2jsonService.xmlToJson(xml);
    this.jsonData = obj as string[];
    this.status = this.jsonData["IPaySecure"]["Message"]["VERes"]["Status"];
    this.ACSUrl = this.jsonData["IPaySecure"]["Message"]["VERes"]["ACSUrl"];
    this.PAReq = this.jsonData["IPaySecure"]["Message"]["VERes"]["PaReq"];
    this.TermUrl = this.jsonData["IPaySecure"]["Message"]["VERes"]["TermUrl"];
    this.MD = this.jsonData["IPaySecure"]["Message"]["VERes"]["MD"];
    return obj;
  }

  removeSpecialCharacters(characters: string) {
    for (var i = 0; i < characters.length; i++) {
      characters = characters.replace("-", "");
    }
    return characters;
  }

  formatDate(date) {
    return this.datepipe.transform(date, 'dd.MM.yyyy HH:mm')
  }

  openPopup() {
    event.preventDefault();
    window.open(this.$("#popup").attr("href"), "popupWindow", "width=600,height=600,scrollbars=yes");
  }

  formatDate2(date) {
    return this.datepipe.transform(date, 'dd/MM/yyyy')
  }

  formatAmount(amount) {
    this.spinnerService.hide();
    return this.utilities.replaceAmount((Math.round(amount * 100) / 100).toFixed(2)) + "  ₺";
  }

  formatAmount2(amount) {
    this.spinnerService.hide();
    return this.utilities.replaceAmount((Math.round(amount * 100) / 100).toFixed(2));
  }

  getFromLocal(key): void {
    return this.data[key] = this.storage.get(key);
  }

  onChange($event) {
    this.totalPrice = (this.ticketNumber * this.PaymentPrice);
  }
  downloadPdf(regionName, ticketType, value) {
    if (value == 1)
      var data = this.$(document.getElementById("contentToConvert"));
    else if (value == 2)
      var data = this.$(document.getElementById("contentToConvert"));
    this.$(".use-later-button").css("visibility", "hidden");
    this.$(".send-by-mail-button").css("visibility", "hidden");
    html2canvas(data[0]).then(canvas => {


      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      const bufferX = 40;
      const bufferY = 40;
      const imgProps = (<any>pdf).getImageProperties(contentDataURL);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      pdf.save(regionName + " " + ticketType + " " + this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm') + '.pdf');
    });
    this.$(".use-later-button").css("visibility", "visible");
    this.$(".send-by-mail-button").css("visibility", "visible");
  }

  plusClick(param: number) {
    let number = +this.$(".count").text();
    if (number > 9) {
      return;
    }
    else {
      this.$(".count").text(number + param);
      this.ticketNumber = number + param;
      var value = +this.$(".count").text();
      this.totalPrice = this.formatAmount(value * this.PaymentPrice);
    }
  }

  minusClick(param: number) {
    let number = +this.$(".count").text();
    if (number < 2) {
      return;
    }
    else {
      this.$(".count").text(number - param);
      this.ticketNumber = number - param;
      var value = +this.$(".count").text();
      this.totalPrice = this.formatAmount(value * this.PaymentPrice);
    }
  }

  getBackgroundColor(ticketType) {
    let color = '';
    if (ticketType == "İNDİRİMLİ") {
      color = '#0000008f'
    }
    else if (ticketType == "TAM") {
      color = "#0000008f";
    }

    return color;
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  changePicture(type) {
    if (type == "UZUN HAT BİLET") {
      return { "background": "url(/assets/img/cuma.jpg)0% 0% / cover" };
    }
    else if (type == "KISA HAT BİLET") {
      return { "background": "url(/assets/img/hacivat.jpg)0% 0% / cover" };
    }
  }

  selectTicketBackground(type, region) {
    if (type == "İNDİRİMLİ") {
      return { "background-image": "url(/assets/img/blt2.png)" };
    }
    else if (type == "TAM") {
      return { "background-image": "url(/assets/img/blt1.png)" };
    }
  }

  selectStyle(type) {
    if (type == "UZUN HAT BİLET") {
      return { "display": "none" };
    }
    else if (type == "KISA HAT BİLET") {
      return { "display": "none" };
    }
    else {
      return { "display": "block" };
    }
  }
  selectStyleForPicture(typeName) {
    if (typeName == "İNDİRİMLİ") {
      return { "width": "6rem" };
    }

  }
}