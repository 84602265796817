
export interface From {
    latitude: number;
    longitude: number;
    name: string;
}

export interface To {
    latitude: number;
    longitude: number;
    name: string;
}

export interface Stop {
    id: string;
    code: string;
}

export interface From2 {
    latitude: number;
    longitude: number;
    name: string;
    stop: Stop;
}

export interface Stop2 {
    id: string;
    code: string;
}

export interface To2 {
    latitude: number;
    longitude: number;
    name: string;
    stop: Stop2;
}

export interface Geometry {
    points: string;
    Levels: string;
    Length: number;
}

export interface Leg {
    mode: string;
    type: number;
    startTime: any;
    endTime: any;
    from: From2;
    to: To2;
    duration: number;
    waitingDuration: number;
    distance: number;
    geometry: Geometry;
    price?: number;
    route: string;
    steps: any[];
}

export interface Itinerary {
    startTime: any;
    endTime: any;
    duration: number;
    transitTime: number;
    transitDistance: number;
    walkTime: number;
    waitingTime: number;
    walkDistance: number;
    transfer: number;
    price: number;
    legs: Leg[];
}

export interface Plan {
    date: number;
    from: From;
    to: To;
    itineraries: Itinerary[];
}

export interface RootObject {
    Plan: Plan;
}

export interface PlanResponse {
    plan: any;
    Plan: Plan;
}

export class LegParam {
    lat: any;
    lng: any;
}

export class TramParam {
    lat: any;
    lng: any;
}

export class MetroParam {
    lat: any;
    lng: any;
}

export class LegBusItem {
    Id: string;
    fromlat: number;
    fromlng: number;
    routeCode: string;
    fromstationName: string;
    tolat: number;
    tolng: number;
    tostationName: string;
}

export class LegWalkItem {
    Id: string;
    lat: number;
    lng: number;
    routeCode: string;
    stationName: string;
}

export class LegTramItem {
    Id: string;
    lat: number;
    lng: number;
    routeCode: string;
    stationName: string;
}

export class LegMetroItem {
    Id: string;
    lat: number;
    lng: number;
    routeCode: string;
    stationName: string;
}

export class WalkData {
    fromLatitude: number;
    fromLongitude: number;
    toLatitude: number;
    toLongitude: number;
}

export class RouteCoordinate {
    Version: number;
    StatusCode: number;
    Message: string;
    Result: RouteCoordinateResult[];
}

export class RouteCoordinateResult {
    routeCode: string;
    routeCoordinates: deneme[];
}

export class deneme {
    Latitude: string;
    Logitude: string;
    Sequence: number;
    Route: number;
    RouteDirection: string;
}

export class Coordinates {
    startLat: string;
    startLon: string;
    endLat: string;
    endLon: string;
}


export class Walk {
    WalkData: WalkDataResult[];
}

export class WalkDataResult {
    Latitude: number;
    Longitude: number;
}


export class Stops {
    [x: string]: any;
    version: number;
    statusCode: number;
    message: string;
    result: StopsResult[];
}

export class StopsResult {
    stationId: number;
    description: string;
    stationType: number;
    altitude: string;
    isActive: number;
    latitude: string;
    longitude: string;
    panelId: number;
}





export class param {

    constructor() {
        this.From = new From();
        this.To = new To();
    }

    From: From;
    To: To;
    IsArriveBy: boolean;
    NumItineraries: number;
    Mode: string;
    Time: any;
    MaxWalkDistance: number;
    IsOrderBy: number;
    CardTypeId: number;
}

export class From {
    Latitude: string;
    Longitude: string;
}

export class To {
    Latitude: string;
    Longitude: string;
}

export class parameters {
    enlem: number;
    boylam: number;
}

export class cardTypes {
    version: number;
    satusCode: number;
    message: string;
    result: CardTypesResult[];
}

export class CardTypesResult {
    kartTipiId: number;
    aciklama: string;
}




