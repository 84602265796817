import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PostToken, Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { CardoperationsService } from './cardoperations.service';
import { JQ_TOKEN } from '../shared/jQuery.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServices {

  jsonData: string[];
  serviceUrl: any;
  cityCode: string;
  userName: string;
  password: string;
  grant_type: string;
  city: string;

  

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private cardOperationsService: CardoperationsService, private httpService: HttpClient, @Inject(JQ_TOKEN) private $: any) { }

    
    
    public data: any = [];
  

  public isAuthenticated(): boolean {
    var token = this.getFromLocal("userName");
    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

  public isStatusCode(): any {
    var statusCode = this.getFromLocalString("statusCode");
    if (statusCode == '200') {
      return true;
    }
    else {
      return false;
    }
  }

  loggedProfile() {
    var userName = this.getFromLocalString("userName") + " " + this.getFromLocalString("userLName");
    return userName;
  }

  isLoggedIn(): boolean {
    return this.getFromLocal("controlLogon") && (this.getFromLocal("statusCode"));
  }

  getAmount() {
    return this.getFromLocalString("amount");
  }

  getLowBalance() {
    return this.getFromLocalString("isLowBalance");
  }

  getFromLocal(key): boolean {
    return this.data[key] = this.storage.get(key);
  }

  getFromLocalString(key): string {
    return this.data[key] = this.storage.get(key);
  }

  changeMenu() {
    this.$("#nav-icon2").removeClass("open"),
      this.$("body").removeClass("menu-open"),
      this.$(".black-layer").removeClass("active");
  }

  getCityInformation(){
    this
  }
}
