import { Component, OnInit, Inject } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { updatePass, updatePassResult } from 'src/app/shared/classes/Profile';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  updatePassResponse: Observable<updatePassResult>;

  public data : any = [];

  constructor(private authService: AuthServices, private loginService: LoginService,
              @Inject(SESSION_STORAGE) private storage: WebStorageService) { }

  ngOnInit() {
  }

  UpdatePass(pass: any, passAgain: any) {
    if (pass == passAgain) {
          this.updatePassResponse = this.loginService.GetUpdatePassword(pass, this.getFromLocal("userId"), this.getFromLocal("sessionTicket"));
          this.updatePassResponse.subscribe((request) => {
            if(request.result = 1){
            Swal.fire('Başarılı', 'Şifreniz güncellenmiştir.', 'success')
            }
            else if(request.result = 901){
              Swal.fire('Uyarı', 'En son girdiğiniz 5 şifre dışında şifre girmelisiniz.', 'warning')
            }
            else if(request.result = 902){
              Swal.fire('Uyarı', 'Kullanıcı bulunamadı.', 'warning')
            }
          })
    }
    else {
      Swal.fire('Uyarı', 'Girdiğiniz şifreler birbirinden farklıdır.', 'warning')
    }
  }

  getFromLocal(key): any {
    return this.data[key] = this.storage.get(key);
  }


}
