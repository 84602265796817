import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token, FillingCenter2 } from '../shared/auth';
import { Observable } from 'rxjs';
import { balance } from '../shared/classes/balance';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {
  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetBalanceData(QueryParam: string): Observable<balance> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<balance>(this.localUrl + "card/usercardinfocore", {"queryParam": QueryParam}, { headers: headers });
  }
}
