import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Token, FillingCenter2, FillingCenterParameters } from '../shared/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FillingcenterService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetAll(parameters: FillingCenterParameters) : Observable<FillingCenter2> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<FillingCenter2>(this.localUrl + "dealer/dealer", parameters, { headers: headers });
  }

  
}
