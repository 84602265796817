import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QrCodeParam, GetQrCode, QrCodeTicketTypesResult, QrCodeTicketTypes, StartQrOrder, StartQrOrderParam, UserQrList, StartQrOrderParamV2 } from '../shared/classes/QrCode';
import { Observable } from 'rxjs';
import { Token } from '../shared/auth';


@Injectable({
  providedIn: 'root'
})
export class QrCodeService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) {}

  GetQrCodeTicketTypes(): Observable<QrCodeTicketTypes>{
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<QrCodeTicketTypes>(this.localUrl + "qr/qrtickettypes", {"keyword": ""}, { headers: headers });
  }

  GetStartQrOrder(parameter: StartQrOrderParam): Observable<StartQrOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartQrOrder>(this.localUrl + "qr/startqrordercore", parameter, { headers: headers });
  }

  GetStartQrOrderV2(parameter: StartQrOrderParamV2): Observable<StartQrOrder> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<StartQrOrder>(this.localUrl + "qr/startqrorder2", parameter, { headers: headers });
  }

  GetListUserQr(sessionTicket): Observable<UserQrList> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<UserQrList>(this.localUrl + "qr/listuserqrcore", {"sessionTicket": sessionTicket}, { headers: headers });
  }

  GetListUserUsedQr(sessionTicket): Observable<UserQrList> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<UserQrList>(this.localUrl + "qr/listuserusedqrcore", {"sessionTicket": sessionTicket}, { headers: headers });
  }

  GetQrByOrderNo(orderNo): Observable<UserQrList> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<UserQrList>(this.localUrl + "qr/qrlistbyorderno", {"orderNo": orderNo}, { headers: headers });
  }
}
