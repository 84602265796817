import { Component, OnInit, Inject } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { Observable } from 'rxjs';
import { login } from 'src/app/shared/classes/Login';
import { SESSION_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SocialLoginService } from 'src/app/services/sociallogin.service';
import { social, socialLoginResult, SocialLoginModel, socialLogin } from 'src/app/shared/classes/SocialLogin';
import { createUser, createUserValue } from 'src/app/shared/classes/CreateUser';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Guid } from "guid-typescript";

import {
  AuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angular5-social-login';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
// import { ToasterService } from 'src/app/services/toaster.service';
import { CardoperationsService } from 'src/app/services/cardoperations.service';
import { ModalService } from 'src/app/services/modal.services';
import { forgotPassValue } from 'src/app/shared/classes/ForgotPassword';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  routeUrl;
  url;
  loginResponse: Observable<login>;
  social: Observable<social>;
  socialLoginRes: Observable<socialLogin>;
  public data: any = [];
  private user: SocialUser;
  public authorized: boolean = false;
  facebookSocialId: number;
  googleSocialId: number;
  loginModel: SocialLoginModel;
  crtuser: Observable<createUser>;
  signIn: boolean = true;
  forgotmypass: boolean = false;
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  forgotPassIcon: boolean = true;
  userId: any;

  private model: SocialLoginModel;


  constructor(private authService: AuthServices, private loginService: LoginService, private modalService: ModalService,
    private socialLogin: SocialLoginService, private cardOperationsService: CardoperationsService,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    @Inject(JQ_TOKEN) private $: any,
    private route: ActivatedRoute, private router: Router,
    private socialAuthService: AuthService) {
    this.model = new SocialLoginModel();
  }

  ngOnInit() {
    this.authService.changeMenu();
    this.route.params.subscribe(p => {
      this.url = p["params"];
    });
    // this.toaster.hide("", "", "");
    this.$(".open").css("display", "block");
      this.social = null;
      // this.social.subscribe((request) => {
      //   this.googleSocialId = request.Result[0]["Id"];
      //   this.facebookSocialId = request.Result[1]["Id"];
      // })
    if (this.url == 'login') {
      this.$('#login').trigger('click');
    }
    else if (this.url == 'myCards') {
      this.$('#login').trigger('click');
    }
    else {
      this.$('#register').trigger('click');
    }
  }

  loginLabel() {
    this.$(".label-login").css("color", "#D5D7DE");
    this.$(".label-register").css("font-size", "15px");
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".login-back").css("height","100%");
    }

    this.$(".label-register").css("color", "#D5D7DE");
    this.$(".label-login").css("font-size", "21px");
    this.signIn = true;
    this.forgotmypass = false;

  }

  registerLabel() {
    this.clickForgotPassIcon();
    this.$(".label-register").css("color", "#D5D7DE");
    this.$(".label-login").css("font-size", "15px");
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".login-back").css("height","120%");
    }

    this.$(".label-login").css("color", "#D5D7DE");
    this.$(".label-register").css("font-size", "21px");
    this.signIn = false;
  }

  clickLogin(userName, password) {
        let deviceId = Guid.create().toString();

        this.loginResponse = this.loginService.GetLogin(password, userName, deviceId);
        if (userName == "") {
          Swal.fire('Başarısız', 'Kullanıcı bulunamadı, lütfen bilgilerinizi kontrol edin.', 'warning')
        }
        else if (password == "") {
          Swal.fire('Başarısız', 'Şifrenizi Giriniz.', 'warning')
        }
        else {
          this.loginResponse.subscribe((request) => {
            if (request.statusCode == '200') {
              request.result.forEach(control => {
                if (control.controlLogon == 0) {
                  this.saveInLocal("userId", control.user_UID);
                  this.saveInLocal("sessionTicket", control.sessionTicket);
                  this.saveInLocal("userMobile", control.user_mobile);
                  this.saveInLocal("userName", control.user_FName);
                  this.saveInLocal("userLName", control.user_LName);
                  this.saveInLocal("controlLogon", control.controlLogon);
                  this.saveInLocal("statusCode", request.statusCode);
                  this.saveInLocal("email", control.user_email);
                  this.saveInLocal("gsm", control.user_mobile);
                  this.userId = this.getFromLocal("user_UID");
                  // this.getCardInfo();
                  this.authService.getAmount();
                  if (this.url == "myCards") {
                    this.router.navigate(['/cardoperations/myCards']);
                  }
                  else if(this.url == "QrCode"){
                    this.router.navigate(['/QrCode']);
                  }
                  else {
                    this.router.navigate(['/wheremybus']);
                  }
                }
                else {
                  Swal.fire('Başarısız', 'Lütfen geçerli bir e-posta adresi ve şifre giriniz.', 'error')
                }

              })
            }
            else {
              Swal.fire('Başarısız', 'Lütfen geçerli bir e-posta adresi ve şifre giriniz.', 'error')
            }
          })
        }
  }

  getCardInfo() {
        this.cardOperationsService.GetFavoriteCard(this.userId).subscribe(res => {
          if (res.statusCode == "200") {
            this.saveInLocal("amount", res.result.amount + "₺");
            this.saveInLocal("isLowBalance", res.result.isLowBalance);
          }
          else {
            this.saveInLocal("amount", "");
            this.saveInLocal("isLowBalance", "");
          }
        });
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  clickRegister(UserName, Surname, Email, PhoneNumber, Password, PassAgain) {
        PhoneNumber = PhoneNumber.replace(/[^a-z0-9]/gi, '')
        PhoneNumber = "90"+PhoneNumber;
        let createUserParam = new createUserValue();
        // createUserParam.tcKimlikNo = TcNumber;
        createUserParam.userCode = Email;
        createUserParam.firstName = UserName;
        createUserParam.secondName = "";
        createUserParam.lastName = Surname;
        createUserParam.pass = Password;
        createUserParam.email = Email;
        createUserParam.mobile = PhoneNumber;
        createUserParam.mifareID = "";
        createUserParam.passRe = PassAgain;

        if (Password != PassAgain) {
          Swal.fire('Başarısız', 'Girdiğiniz şifreler birbirine benzemelidir.', 'warning')
        }
        else if (Email == "") {
          Swal.fire('Başarısız', 'Email Adresinizi Giriniz.', 'warning')
        }
        else if (UserName == "") {
          Swal.fire('Başarısız', 'Adınızı Giriniz.', 'warning')
        }
        else if (Surname == "") {
          Swal.fire('Başarısız', 'Soyadınızı Giriniz.', 'warning')
        }
        else if (PhoneNumber == "" || PhoneNumber.length < 10) {
          Swal.fire('Başarısız', 'Telefon Numaranızı Giriniz.', 'warning')
        }
        // else if (TcNumber == "") {
        //   Swal.fire('Başarısız', 'Tc Kimlik Numaranızı Giriniz.', 'warning')
        // }
        // else if (TcNumber.length < 11) {
        //   Swal.fire('Başarısız', 'Tc Kimlik Numaranızı Doğru Giriniz.', 'warning')
        // }
        else {
          this.crtuser = this.loginService.GetCreateUser(createUserParam);

          this.crtuser.subscribe((request) => {
            if (request.statusCode == "200") {
              Swal.fire('Başarılı', 'Giriş yapabilirsiniz.', 'Success')
            }
            else if (request.message == "The same user cannot be defined to the system") {
              Swal.fire('Başarısız', 'Bu e-posta adresi daha önce kullanılmış.', 'warning')
            }
            else if (request.statusCode != "200") {
              Swal.fire('Başarısız', request.message , 'warning')
            }
          })
        }
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  getFromLocal(key): void {
    return this.data[key] = this.storage.get(key);
  }

  clickForgotPassIcon() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      this.$(".facebookButton").css("display", "block");
      this.$(".googleButton").css("display", "block");
      this.$(".orPicture").css("display", "block");
    }
    else{
      this.$(".login-back").css("height", "60%");
    }
    this.forgotmypass = false;
    this.forgotPassIcon = true;
    
  }

  clickSendPass(Email: string) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(Email) == false) {
      Swal.fire('Başarısız', 'Geçerli bir e-posta adresi giriniz', 'error')
    }
    else {
      if (Email == "") {
        Swal.fire('Başarısız', 'Lütfen zorunlu alanları doldurunuz', 'error')
      }
      // else if(CitizenNumber == ""){
      //   Swal.fire('Başarısız', 'Lütfen zorunlu alanları doldurunuz', 'error')
      // }
      // else if(CitizenNumber.length < 11){
      //   Swal.fire('Başarısız', 'Tc kimlik numarasını kontrol edin', 'error')
      // }
      else {
            let param = new forgotPassValue();
            param.UserName = Email;
            this.loginService.GetForgotPasswordToken(param).subscribe((result) => {
              var statu = result.statusCode;
              if (statu == "200")
              Swal.fire('Başarılı', 'Şifre sıfırlama mailiniz gönderilmiştir.', 'success')
              else
              Swal.fire('Başarısız', 'İşlem başarısız olmuştur', 'error')
            })
      }
    }
  }

  forgotMyPassword() {
    this.$(".forget-password-area").css("display", "block");
  }

  closeForgotMyPass() {
    this.$(".forget-password-area").css("display", "none");
  }

  clickEye() {
    this.$(".password .pass-eye .eye").toggleClass("icon-eye icon-eye-closed");

    var input = this.$(".password input");
    input.attr('type') === 'password' ? input.attr('type', 'text') : input.attr('type', 'password')
  }

}
