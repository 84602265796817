import { Component, OnInit, Inject } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { updateUserRequest } from 'src/app/shared/classes/Settings';
import { WebStorageService, SESSION_STORAGE } from 'angular-webstorage-service';
import { SettingsService } from 'src/app/services/settings.service';
import { JQ_TOKEN } from 'src/app/shared/jQuery.service';
import { SocialLoginModel } from 'src/app/shared/classes/SocialLogin';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public data: any = [];
  userId: any;
  sessionTicket: any;
  name: any;
  surName: any;
  eMail: any;
  gsm: any;
  public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private model: SocialLoginModel;
  
  constructor(private authService: AuthServices, private settingsService: SettingsService, @Inject(SESSION_STORAGE) private storage: WebStorageService,
     @Inject(JQ_TOKEN) private $: any) {
      this.model = new SocialLoginModel();
     }

  ngOnInit() {
    this.authService.changeMenu();
    this.userId = this.getFromLocal("userId");
    this.sessionTicket = this.getFromLocal("sessionTicket");
    this.name = this.getFromLocal("userName");
    this.surName = this.getFromLocal("userLName");
    this.eMail = this.getFromLocal("email");
    this.gsm = this.getFromLocal("gsm");
  }

  changeUserInformation(name, surname, email, phone) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    
    if (reg.test(email) == false) {
      Swal.fire('Uyarı', 'Geçerli bir E-Posta adresi giriniz.', 'warning')
    }
    else if (name == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (surname == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (email == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (phone == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else {
          const parameters = new updateUserRequest();
          // parameters.userId = this.userId;
          parameters.sessionTicket = this.sessionTicket;
          parameters.identity = "11111111111";
          parameters.name = name;
          parameters.lastName = surname;
          parameters.phone = phone;
          parameters.email = email;
          this.settingsService.GetUserProfile(parameters).subscribe((res => {
            if (res) {
              if (res.updateResult == 5001)
              Swal.fire('Uyarı', 'Kullanıcı bulunamadı.', 'warning')
              else if (res.updateResult == 5003)
              Swal.fire('Başarısız', 'Bu E-Posta adresi kullanılıyor.', 'error')
              else if (res.updateResult == 5010)
              Swal.fire('Uyarı', 'Kullanıcı parametrelerini kontrol edin.', 'warning')
              else (res.updateResult == 1)
              {
                this.saveInLocal("userName", name);
                this.saveInLocal("userLName", surname);
                this.saveInLocal("gsm", phone);
                this.authService.loggedProfile();
                Swal.fire('Başarılı', 'Kişisel bilgileriniz güncellenmiştir.', 'success')
              }
            }
          }));
    }
  }


  changeUserPassword(availablePass: string, newPass: string, newPassAgain: string) {
    if (availablePass == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (newPass == "")
    Swal.fire('Uyarı', 'Lütfen zorunlu alanları doldurunuz.', 'warning')
    else if (newPassAgain == "")
    Swal.fire('Uyarı', 'Şifreniz en az 6 karakter olmalıdır. Büyük harf, küçük harf ve rakam içermelidir.', 'warning')
    else if (newPass != newPassAgain)
    Swal.fire('Uyarı', 'Lütfen şifre tekrar alanını kontrol ediniz.', 'warning')
    else if (newPass.length < 6 || newPassAgain.length < 6)
    Swal.fire('Uyarı', 'Şifreniz en az 6 karakter olmalıdır. Büyük harf, küçük harf ve rakam içermelidir.', 'warning')
    else {
          this.settingsService.GetChangeUserPassword(this.userId, newPass, availablePass, this.sessionTicket).subscribe((res => {
            if (res.result[0]["result"] == 901)
            Swal.fire('Uyarı', 'En son kullandığınız 5 şifre dışında bir şifre belirlemelisiniz.', 'warning')
            else if (res.result[0]["result"] == 902)
            Swal.fire('Uyarı', 'Kullanıcı bulunamadı.', 'warning')
            else if (res.result[0]["result"] == 903)
            Swal.fire('Uyarı', 'Şuan kullandığınız şifreyi hatalı girdiniz.', 'warning')
            else if (res.result[0]["result"] == 1) {
              Swal.fire('başarılı', 'Şifre değiştirme başarılı.', 'success')
              this.$(".input-settings-password").val("");
            }
          }))
    }
  }

  changeuserInformations() {
    this.$(".tab-header-settings").css("background-color", "black");
    this.$(".tab-header-change-password").css("background-color", "#EDEDED");
    this.$(".tab-header-change-password").css("height", "100%");
    this.$(".label-tab-settings").css("color", "white");
    this.$(".label-tab-change-password").css("color", "black");
    this.$(".change-user-information").css("display", "block");
    this.$(".change-password").css("display", "none");
  }

  changePassword() {
    this.$(".input-settings-password").val("");
    this.$(".tab-header-change-password").css("background-color", "black");
    this.$(".tab-header-settings").css("background-color", "#EDEDED");
    this.$(".tab-header-change-password").css("height", "100%");
    this.$(".label-tab-settings").css("color", "black");
    this.$(".label-tab-change-password").css("color", "white");
    this.$(".change-password").css("display", "block");
    this.$(".change-user-information").css("display", "none");
  }

  saveInLocal(key, val): void {
    this.storage.set(key, val);
    this.data[key] = this.storage.get(key);
  }

  getFromLocal(key): void {
    return this.data[key] = this.storage.get(key);
  }
}