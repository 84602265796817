export class lostPropertyParams{
    IdentityNumber: string;
    NameSurname: string;
    Phone: string;
    Email: string;
    LineNo: string;
    GetOnDate: any;
    LeaveStation: string;
    DefinedStuff: string;
    Notes: string;
}

export class lostProperty{
    version: number;
    statusCode: number;
    message: string;
    result: lostPropertyResult[];
}

export class lostPropertyResult{
    nameSurname: string;
    email: string;
}