import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { login } from '../shared/classes/Login';
import { createUserValue, createUser } from '../shared/classes/CreateUser';
import { forgotPass, forgotPassValue } from '../shared/classes/ForgotPassword';
import { updatePassResult } from '../shared/classes/Profile';
import { activationResult, activation, activationPassword } from '../shared/classes/Activation';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetLogin(Password: string, UserCode: string, deviceId: string): Observable<login> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<login>(this.localUrl + "account/logoncore", {"userPasswrd": Password, "userCode": UserCode,"channelId":1, "deviceId": deviceId},  { headers: headers });
  }

  GetCreateUser(userValue: createUserValue): Observable<createUser> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<createUser>(this.localUrl + "account/newusercore", userValue, { headers: headers });
  }

  GetForgotPasswordToken(param: forgotPassValue): Observable<forgotPass> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<forgotPass>(this.localUrl + "account/forgetpasswordtoken", param, { headers: headers });
  }

  GetUpdatePassword(Pass: string, UserId: number, sessionTicket: string): Observable<updatePassResult> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<updatePassResult>(this.localUrl + "account/updatepasswordcore", {"userId": UserId, "newPassword": Pass, "oldPassword": "", sessionTicket:"sessionTicket"} , { headers: headers });
  }

  ActivationRequest(token: string): Observable<activation> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<activation>(this.localUrl + "account/activateuser", {"token": token}, { headers: headers });
  }

  ForgetPassword(token: string): Observable<activationPassword> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<activationPassword>(this.localUrl + "ForgetPassword", {"token": token}, { headers: headers });
  }
}
