import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Token, FillingCenter2 } from '../shared/auth';
import { Observable } from 'rxjs';
import { Plan, PlanResponse, RouteCoordinate, LegParam, Stops, RootObject, param, StopsResult, parameters, cardTypes } from '../shared/classes/Howtogo';
import { map } from 'rxjs/operators';
import { KeyValue } from '../shared/classes/KeyValue';
import { routeStationResult } from '../shared/classes/wherebus';
import { PoiZoneTypes, PoiZoneTypesResult } from '../shared/classes/PoiZoneTypes';

@Injectable({
  providedIn: 'root'
})
export class HowtoGoService {
  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetRouteCoordinateData(response: Token, legParams: LegParam[]) : Observable<RouteCoordinate> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer '+ response.access_token });
    return this.httpClient.post<RouteCoordinate>(this.url + "RouteCoordinateBetweenTwoStation", legParams,{ headers: headers });
  }

  // GetData(Coordinate: string) : Observable<PlanResponse> {
  //   return this.httpClient.get<PlanResponse>("https://cors-anywhere.herokuapp.com/http://asis-services.com/OtpApi/api/61/plans?from=" + Coordinate);
  // }

  GetData(parameters: param) : Observable<PlanResponse> {
    // return this.httpClient.post<PlanResponse>("https://asis-services.com/OtpApi/api/31/plan", parameters);
    return this.httpClient.post<PlanResponse>("https://asis-services.com/OtpApi/api/23/plan", parameters);
  }

  GetStops() : Observable<Stops> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<Stops>(this.localUrl + "static/activestation", "{}",{ headers: headers });
  }

  GetLikeStops(searchValue: string) : Observable<Stops> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<Stops>(this.localUrl + "static/stationsandpoi", {"keyword": searchValue}, { headers: headers });
  }

  GetCardTypes(response: Token) : Observable<cardTypes> {
    const headers = new HttpHeaders({ 'Authorization': 'Bearer '+ response.access_token });
    return this.httpClient.get<cardTypes>(this.url + "card/otp/getcardtypes" ,{ headers: headers });
  }

  
}
