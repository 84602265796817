import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MapDirective } from 'src/app/shared/classes/wherebus';

@Injectable({
  providedIn: 'root'
})
export class MapCommunicationService {

  constructor() { }

  private directiveToMap$ = new Subject<MapDirective>();
  private parentPage$ = new BehaviorSubject<string>("");
  private defaultCoordinates$ = new Subject<number[]>();

  addDirective(mapDirective: MapDirective) {
    return this.directiveToMap$.next(mapDirective);
  }

  getDirective(): Observable<any> {
    return this.directiveToMap$.asObservable();
  }

  addParent(parentPage: string) {
    return this.parentPage$.next(parentPage);
  }

  getParent(): Observable<any> {
    return this.parentPage$.asObservable();
  }

  addCoordinates(coordinatesEqury: number[]) {
    return this.defaultCoordinates$.next(coordinatesEqury);
  }

  getCoordinates(): Observable<any> {
    return this.defaultCoordinates$.asObservable();
  }

}
