import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token } from '../shared/auth';
import { Observable } from 'rxjs';
import { lostPropertyParams, lostProperty } from '../shared/classes/LostProperty';

@Injectable({
  providedIn: 'root'
})
export class LostpropertyService {

  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }
  
  GetLineHours(Body: lostPropertyParams): Observable<lostProperty> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<lostProperty>(this.localUrl + "static/lostpropertynotification", Body, { headers: headers });
  }
}
