export class station {
    version: number;
    statusCode: string;
    message: string;
    result: stationResult[]
}

export class stationResult {
    stationId : number;
    description: string;
    stationType: string;
    altitude: string;
    isActive: boolean;
    latitude: string;
    longitude: string;
}


export class smartStations {
    version: number;
    statusCode: string;
    message: string;
    result: smartStationResult[]
}

export class smartStationResult{
    // routeId: number;
    // routeCode: string;
    // routeTitle: string;
    // passTime: string;
    // realTime: string;
    busLineCode: string;
    busLineNo: number;
    busLineShortName: string;
    panelId: number;
    remainingTimeCurr: number;
    remainingTimeNext: number;
    isAccordingToTimeSchedule: string;
    busStatusCurr: number;
    busStatusNext: number;
}


export class stationRoutes {
    version: number;
    statusCode: string;
    message: string;
    result: stationRoutesResult[]
}

export class stationRoutesResult{
    routeNo: number;
    routeCode: string;
    lRouteName: string;
    sRouteName: string;
}

export class nearStations {
    version: number;
    statusCode: string;
    message: string;
    result: nearStationsResult[]
}

export class nearStationsResult{
    id: number;
    name: string;
    latitude: string;
    longitude: string;
    distance: number;
}


export class nearStationsParameters{
    latitude: string;
    longitude: string;
    maxDistance: string;
}