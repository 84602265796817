import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html'
})

export class FooterComponent implements OnInit {

    jsonData: string[];
    cityCode: string;
    cityName: string;
    appStoreAddress: string;
    googlePlayAddress: string;
    facebookAddress: string;
    instagramAddress: string;
    twitterAddress: string;
    linkednAddress: string;
    hatay: boolean = false;
    elazig: boolean = false;
    kutahya: boolean = false;
    van: boolean = false;
    sakarya:boolean = false;

    constructor(public authService: AuthServices, private httpService: HttpClient) { }

    ngOnInit() {
        this.httpService.get('../assets/GeneralInformation.json').subscribe(
            data => {
                this.jsonData = data as string[];
                this.cityCode = this.jsonData[0]['cityCode'];
                this.cityName = this.jsonData[0]['cityName'];
                this.appStoreAddress = this.jsonData[0]['appStoreAddress'];
                this.googlePlayAddress = this.jsonData[0]['googlePlayAddress'];
                this.facebookAddress = this.jsonData[0]['facebookAddress'];
                this.instagramAddress = this.jsonData[0]['instagramAddress'];
                this.twitterAddress = this.jsonData[0]['twitterAddress'];
                this.linkednAddress = this.jsonData[0]['linkednAddress'];

                if (this.cityName == "Hatay") {
                    this.hatay = true;
                }
                if (this.cityName == "Sakarya") {
                    this.sakarya = true;
                }
                else if (this.cityName == "Elazığ") {
                    this.elazig = true;
                }
                else if (this.cityName == "Kütahya") {
                    this.kutahya = true;
                }
                else if (this.cityName == "Van") {
                    this.van = true;
                }
            });
    }
    onActivate(value) {
        window.scrollTo(0, 0);
    }
}