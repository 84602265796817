import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import XYZ from 'ol/source/XYZ';
import { OSM } from 'ol/source.js';
import { MapCommunicationService } from '../../services/communication-services/map-communication.service';
import { Subject, pipe } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Icon, Style, Stroke } from 'ol/style.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import VectorSource from 'ol/source/Vector.js';
import { LineString } from 'ol/geom.js';
import Overlay from 'ol/Overlay.js';
import { fromLonLat } from 'ol/proj.js';
import { toLonLat } from 'ol/proj.js';
import { getLength } from 'ol/sphere';
import * as ContextMenu from 'ol-contextmenu';

// const defaultZoom = 11;

export class ShowStopOnMapModel {
  icon: string;
  location: ShowStopOnMapLocationModel
}

export class ShowStopOnMapLocationModel {
  longitude: number;
  latitude: number;
  stationId: number;
  stationName: string
}

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss']
})
export class OlMapComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() defaultZoom = 11;
  @Input() defaultCenter = [29.061008, 40.188637];
  @Input() userLocation = [];

  map: Map;
  selectedBaseMap: string = 'Google Road Map';
  destroyed$ = new Subject();
  baseMaps: any[] = [

    {
      name: 'Open Street Map', value: new TileLayer({
        name: 'Open Street Map',
        source: new OSM({
          crossOrigin: 'Anonymous',
        })
      })
    },
    {
      name: 'Google Road Map', value: new TileLayer({
        name: 'Google Road Map',
        source: new XYZ({
          url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        }),
      })
    },
  ];
  contextMenu: any;
  startIcon: string = '/assets/img/logo/start1.png';
  endIcon: string = '/assets/img/logo/bayrak.png';
  parentPage: string;
  /**
 * Elements that make up the popup.
 */
  overlay: Overlay;
  _popupCloser;
  _popupContainer;
  _popupContent;
  busInfoList: any[];

  pathOptions = ['WALK', 'BUS', 'TRAM', 'SUBWAY']

  stopInfo;
  busInfo;
  stopName;
  stopId;
  distance;
  fillingCenterId;


  constructor(
    private mapCommunicationService: MapCommunicationService,
    private cdr: ChangeDetectorRef
  ) {

  }


  ngOnInit() {
    this.mapCommunicationService.getParent()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.parentPage = res;
      })

    this.mapCommunicationService.getCoordinates()
      .pipe(take(1))
      .subscribe(coordinatesEqury => {
        this.defaultCenter = coordinatesEqury;
        if (this.map) {
          this.map.getView().setCenter(this.convertCoordinatesToWGS(this.defaultCenter));
          // this.cdr.detectChanges();
        }
      })

    this.mapCommunicationService.getDirective()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        switch (res.directive) {
          case "showStopOnMap":
            this.showStopOnMap(res.directiveNeeds);
            break;

          case "drawLine":
            this.drawLine(res.directiveNeeds);
            break;

          case "clearMap":
            this.clearMap(res.directiveNeeds);
            break;

          case "showRouteStops":
            this.showRouteStops(res.directiveNeeds);
            break;

          case "showLiveBus":
            this.showLiveBus(res.directiveNeeds);
            this.busInfoList = res.directiveNeeds;
            break;

          case "showStartPoint":
            this.showHowToGoPoint(res.directiveNeeds, 'startPoint');
            break;

          case "showEndPoint":
            this.showHowToGoPoint(res.directiveNeeds, 'endPoint');
            break;

          case "changeHowToGoStops":
            this.changeHowToGoStops();
            break;

          case "clearHowToGoPoint":
            this.clearHowToGoPoint(res.directiveNeeds);
            break;

          case "showRoutesHowToGo":
            this.showRoutesHowToGo(res.directiveNeeds);
            break;

          case "setVisibleDirection":
            this.setVisibleDirection(res.directiveNeeds);
            break;

          case "showSmartStops":
            this.showSmartStops(res.directiveNeeds);
            break;

          case "panTo":
            const coordinates = res.directiveNeeds.longitude && res.directiveNeeds.latitude ? [+res.directiveNeeds.longitude, +res.directiveNeeds.latitude] : [+res.directiveNeeds.boylamFStr, +res.directiveNeeds.enlemFStr]
            this.panTo(this.convertCoordinatesToWGS(coordinates), 18);
            break;

          case "showCentersOnMap":
            this.showCentersOnMap(res.directiveNeeds);
            break;

          default:
            // console.log("default")
            break;
        }
      })

  }
  showCentersOnMap(directiveNeeds: any) {
    let hasFillingCenterLayer: boolean = this.hasLayer('FillingCenter');
    if (hasFillingCenterLayer) {
      const _fillingLayers = this.getLayers('FillingCenter');
      _fillingLayers.forEach(layer => {
        this.map.removeLayer(layer);
      });
    }
    let _showStopOnMap: ShowStopOnMapModel = new ShowStopOnMapModel();
    let _showStopOnMapLocation: ShowStopOnMapLocationModel = new ShowStopOnMapLocationModel();
    directiveNeeds.map(x => {
      _showStopOnMap.icon = this.getFillingCenterIcon(x.tip);
      _showStopOnMapLocation.longitude = +x.boylamFStr;
      _showStopOnMapLocation.latitude = +x.enlemFStr;
      _showStopOnMapLocation.stationId = x.servisId;
      _showStopOnMapLocation.stationName = x.aciklama;
      _showStopOnMap.location = _showStopOnMapLocation;
      this.showStopOnMap(_showStopOnMap, false, true, 'FillingCenter');
    })
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.map = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            name: 'Open Street Map',
            source: new OSM({
              crossOrigin: 'Anonymous',
            }),
          }),
        ],
        view: new View({
          center: this.convertCoordinatesToWGS(this.defaultCenter),
          zoom: this.defaultZoom,
          smoothResolutionConstraint: false
        })
      });

      this._popupCloser = document.getElementById('popup-closer');
      this._popupContainer = document.getElementById('popup');
      this._popupContent = document.getElementById('popup-content');


      switch (this.parentPage) {
        case "howToGo":
          this.initContextMenu();
          break;
        case "whereMyBus":
          break;
        default:
          break;
      }

      this.addPointerMoveToMap();
      // Create a popup overlay which will be used to display feature info
      this.generateOverlay();
      this.map.addOverlay(this.overlay);
      this.hideOverlay();
      this.initPopup();

    }, 0);
  }

  ngOnDestroy(): void {
    if (this.parentPage == 'howToGo') {
      this.mapCommunicationService.addParent('')
    }
    this.map = null;
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  generateOverlay() {
    this.overlay = new Overlay({
      element: this._popupContainer,
      positioning: 'top-center',
    });
  }

  convertCoordinatesToWGS(coordinates: any[]) {
    return fromLonLat(coordinates, 'EPSG:3857')
  }
  convertWGSToCoordinates(WGSCoords: any[]) {
    return toLonLat(WGSCoords, 'EPSG:3857');
  }

  showSmartStops(directiveNeeds: any) {
    directiveNeeds[0].stations.forEach((station, index) => {
      let showStopOnMapNeeds: any;
      showStopOnMapNeeds = {
        icon: directiveNeeds[0].icon,
        location: station
      }
      this.showStopOnMap(showStopOnMapNeeds, false, true)
    });

    this.panTo(this.convertCoordinatesToWGS(this.defaultCenter), 22)

  }
  setVisibleDirection(directiveNeeds: any) {
    const vectorLayers = this.map.getLayers().array_.filter(x => x.type == 'VECTOR')
    const directionLayers = this.map.getLayers().array_.filter(x => x.get('direction') == directiveNeeds)

    vectorLayers.forEach(_layer => {
      _layer.setVisible(false);
    });

    directionLayers.forEach(_directionLayer => {
      _directionLayer.setVisible(true)
    });
  }
  showRoutesHowToGo(directiveNeeds: any) {
    if (this.getLayer('HowToGoRoutes')) {
      this.map.removeLayer(this.getLayer('HowToGoRoutes'));
    }

    let _walkStyle = this.generateRouteStyle("#2196f3", 4, [.1, 5]);
    let _busStyle = this.generateRouteStyle('#FF9900', 4);
    let _tramStyle = this.generateRouteStyle('#404040', 4);
    let _subwayStlye = this.generateRouteStyle('#02AEAF', 4);

    let _howToGoFeatures: Feature[] = [];

    this.pathOptions.forEach(option => {
      if (directiveNeeds.legs.some(x => x.mode == option)) {
        _howToGoFeatures = [..._howToGoFeatures, ...this.generateHowToGoFeature(directiveNeeds.legs.filter(x => x.mode == option))];
      }
    })

    _howToGoFeatures.map(feature => {
      switch (feature.get('mode')) {
        case 'WALK':
          feature.setStyle(_walkStyle);
          break;
        case 'BUS':
          feature.setStyle(_busStyle);
          break;
        case 'TRAM':
          feature.setStyle(_tramStyle);
          break;
        case 'SUBWAY':
          feature.setStyle(_subwayStlye);
          break;
      }
    })
    let _routeSource = this.generateVectorSource(_howToGoFeatures);
    let _routeLayer = this.generateRouteLayer('HowToGoRoutes', _routeSource);
    this.map.addLayer(_routeLayer);
    this.panTo(this.convertCoordinatesToWGS([+directiveNeeds.legs[0].from.longitude, +directiveNeeds.legs[0].from.latitude]), this.map.getView().getZoom() - 1)

    this.cdr.markForCheck();
  }
  generateHowToGoFeature(legs: any) {
    const decodePolyline = require('decode-google-map-polyline');
    let _features: Feature[] = [];
    legs = legs.map(leg => {
      return {
        decodedPolyline: decodePolyline(leg.geometry.points),
        ...leg,
      }
    });

    legs.forEach((leg, index) => {
      _features.push(this.generateRouteFeatureFromDecodedPolyline(leg.decodedPolyline, leg.mode));
      switch (leg.mode) {
        case 'BUS':
          // let _busStopFeature = this.generatePointFeature([+leg.to.longitude, +leg.to.latitude]);
          // let _busStopStyle = this.generatePointStyle("/assets/img/logo/windowbus.png", undefined);
          // _busStopFeature.setStyle(_busStopStyle);
          // _features.push(_busStopFeature);
          break;
        // case 'TRAM':
        //   let _tramStopFeature = this.generatePointFeature([+leg.from.longitude, +leg.from.latitude]);
        //   let _tramStopStyle = this.generatePointStyle("/assets/img/logo/tramvay.png", undefined);
        //   _tramStopFeature.setStyle(_tramStopStyle);
        //   _features.push(_tramStopFeature);
        //   break;
        // case 'SUBWAY':
        //   let _subwayStopFeature = this.generatePointFeature([+leg.from.longitude, +leg.from.latitude]);
        //   let _subwayStopStyle = this.generatePointStyle("/assets/img/logo/metro.png", undefined);
        //   _subwayStopFeature.setStyle(_subwayStopStyle);
        //   _features.push(_subwayStopFeature);
        //   break;
        case 'WALK':
          if (index != legs.length - 1) {
            let _walkStopFeature = this.generatePointFeature([+leg.to.longitude, +leg.to.latitude]);
            let _walkStopStyle = this.generatePointStyle("/assets/img/logo/bursaDurak2.png", undefined);
            _walkStopFeature.setStyle(_walkStopStyle);
            _walkStopFeature.set('StopName', leg.to.name)
            _features.push(_walkStopFeature);
          }
          break;
      }
    });
    return _features;
  }
  generateRouteFeatureFromDecodedPolyline(decodedPolyline: any, mode: string) {
    const coordinates = this.generateCoordinateArrayFromDecodedPolyline(decodedPolyline);
    let _feature = new Feature(new LineString(coordinates, 'XY'));
    _feature.set('mode', mode)
    return _feature;
  }
  generateCoordinateArrayFromDecodedPolyline(decodedPolyline: any) {
    let _coordinateArray: any[] = [];
    decodedPolyline.map(x => {
      _coordinateArray.push(this.convertCoordinatesToWGS([+x.lng, +x.lat]))
    })
    return _coordinateArray;
  }
  clearHowToGoPoint(directiveNeeds: any) {
    let _pointLayer = this.getLayer('HowToGoPointLayer');
    let _pointSource = _pointLayer.getSource();

    _pointSource.getFeatures().forEach(feature => {
      if (feature.get('pointOrder') == directiveNeeds) {
        feature.setStyle(null);
      }
    });
    _pointSource.refresh();
  }
  changeHowToGoStops() {
    let _pointLayer = this.getLayer('HowToGoPointLayer');
    let _pointSource = _pointLayer.getSource();
    let _storedPointFeatures: Feature[] = [];

    _pointSource.getFeatures().forEach(feature => {
      _storedPointFeatures.push(feature);
    });

    let _startPoint = _storedPointFeatures.find(x => x.get('pointOrder') == 'startPoint');
    let _endPoint = _storedPointFeatures.find(x => x.get('pointOrder') == 'endPoint');



    let _startIcon = _startPoint ? _startPoint.getStyle().image_.getSrc() : "";
    let _endIcon = _endPoint ? _endPoint.getStyle().image_.getSrc() : "";
    let _startCoord = _startPoint ? _startPoint.get('coordXY') : "";
    let _endCoord = _endPoint ? _endPoint.get('coordXY') : "";
    let _startStopId = _startPoint ? _startPoint.get('StopId') : "";
    let _endStopId = _endPoint ? _endPoint.get('StopId') : "";
    let _startStopTitle = _startPoint ? _startPoint.get('StopTitle') : "";
    let _endStopTitle = _endPoint ? _endPoint.get('StopTitle') : "";
    let _zoom = this.map.getView().getZoom();

    this.map.removeLayer(_pointLayer);

    _storedPointFeatures.map(x => {
      let _directiveNeeds = {
        icon: x.get('pointOrder') == 'startPoint' ? _startIcon : _endIcon,
        coordinates: x.get('pointOrder') == 'startPoint' ? _endCoord : _startCoord,
        StopId: x.get('pointOrder') == 'startPoint' ? _endStopId : _startStopId,
        StopTitle: x.get('pointOrder') == 'startPoint' ? _endStopTitle : _startStopTitle,
        zoom: _zoom,
        pointOrder: x.get('pointOrder') == 'startPoint' ? 'endPoint' : 'startPoint'
      }

      this.showHowToGoPoint(_directiveNeeds, _directiveNeeds.pointOrder);

    })

  }
  showHowToGoPoint(directiveNeeds: any, pointOrder: string) {
    let _pointLayer: VectorLayer;
    let _pointSource: VectorSource;
    let _startPointFeature: Feature;
    let _endPointFeature: Feature;
    let _PointStyle = this.generatePointStyle(directiveNeeds.icon, undefined);
    let _pointFeatures: Feature[] = [];
    let hasMapAlreadyPointLayer = this.getLayer('HowToGoPointLayer');

    if (hasMapAlreadyPointLayer) {
      _pointLayer = this.getLayer('HowToGoPointLayer');
      _pointSource = _pointLayer.getSource();
      _pointSource.getFeatures().forEach(feature => {

        if (feature.get('pointOrder') == pointOrder) {

          _endPointFeature = feature;
          _endPointFeature.setStyle(_PointStyle);
          _endPointFeature.set('StopId', directiveNeeds.StopId);
          _endPointFeature.set('StopTitle', directiveNeeds.StopTitle);
          _endPointFeature.set('coordXY', directiveNeeds.coordinates);
          _endPointFeature.setGeometry(new Point(this.convertCoordinatesToWGS(directiveNeeds.coordinates)))

        }
      });

      _pointSource.refresh();
    }
    else {
      _startPointFeature = pointOrder == 'startPoint' ? this.generatePointFeature(directiveNeeds.coordinates) : new Feature();
      _startPointFeature.setStyle(pointOrder == 'startPoint' ? _PointStyle : null);
      _startPointFeature.set('StopId', pointOrder == 'startPoint' ? directiveNeeds.StopId : "");
      _startPointFeature.set('StopTitle', pointOrder == 'startPoint' ? directiveNeeds.StopTitle : "");
      _startPointFeature.set('coordXY', pointOrder == 'startPoint' ? directiveNeeds.coordinates : "");
      _startPointFeature.set('pointOrder', 'startPoint');
      _endPointFeature = pointOrder == 'endPoint' ? this.generatePointFeature(directiveNeeds.coordinates) : new Feature();
      _endPointFeature.setStyle(pointOrder == 'endPoint' ? _PointStyle : null);
      _endPointFeature.set('StopId', pointOrder == 'endPoint' ? directiveNeeds.StopId : "");
      _endPointFeature.set('StopTitle', pointOrder == 'endPoint' ? directiveNeeds.StopTitle : "");
      _endPointFeature.set('coordXY', pointOrder == 'endPoint' ? directiveNeeds.coordinates : "");
      _endPointFeature.set('pointOrder', 'endPoint');

      _pointFeatures = [_startPointFeature, _endPointFeature];
      _pointSource = this.generateVectorSource(_pointFeatures);
      _pointLayer = this.generatePointLayer(_pointSource, 'HowToGoPointLayer');
      this.map.addLayer(_pointLayer);
      this.map.getView().setZoom(directiveNeeds.zoom);
      this.map.getView().setCenter(this.convertCoordinatesToWGS(directiveNeeds.coordinates));
    }
  }
  hasLayer(layerName: string) {
    return this.map.getLayers().array_.some(x => x.values_.name == layerName);
  }
  getLayer(layerName: string) {
    if (this.hasLayer(layerName)) {
      return this.map.getLayers().array_.find(x => x.values_.name == layerName);
    }
  }

  getLayers(layerName: string) {
    if (this.hasLayer(layerName)) {
      return this.map.getLayers().array_.filter(x => x.values_.name == layerName);
    }
  }
  showLiveBus(directiveNeeds: any) {
    let hasMapAlreadyBusLayer: boolean = this.hasLayer('LiveBusLayer');
    let _busLayer;
    let _busSource: VectorSource = null;
    let _busFeatures: Feature[] = [];
    let _busFeature;

    if (hasMapAlreadyBusLayer) {
      _busLayer = this.getLayer('LiveBusLayer');
      this.map.removeLayer(_busLayer);
    }

    directiveNeeds.map(x => {
      let _busStyle = this.generatePointStyle(x.ImageUrl, undefined);
      _busFeature = this.generatePointFeature([+x.boylam, +x.enlem]);
      _busFeature.setStyle(_busStyle);
      _busFeature.set('plate', x.plaka);
      _busFeature.set('validatorNo', x.validatorNo);
      _busFeature.set('istikamet', x.istikamet);
      _busFeatures.push(_busFeature)
    })
    _busSource = this.generateVectorSource(_busFeatures);
    _busLayer = this.generatePointLayer(_busSource, 'LiveBusLayer');
    this.map.addLayer(_busLayer);
  }
  showRouteStops(directiveNeeds: any) {
    directiveNeeds.map(x => {
      let _stopStyle;
      let _stopFeatures: Feature[] = [];
      x.stations.forEach((station, index) => {
        let _stopFeature = this.generatePointFeature([+station.logitude, +station.latitude]);
        _stopFeature.set('StopId', station.StopId);
        _stopFeature.set('StopName', station.stopName);

        switch (index) {
          case 0:
            _stopStyle = this.generatePointStyle(this.startIcon, undefined);
            break;
          case x.stations.length - 1:
            _stopStyle = this.generatePointStyle(this.endIcon, undefined);
            break;
          default:
            _stopStyle = this.generatePointStyle(x.icon, undefined);
            break;
        }

        _stopFeature.setStyle(_stopStyle);
        _stopFeatures.push(_stopFeature)
      });
      let _stopSource = this.generateVectorSource(_stopFeatures);
      let _stopLayer = this.generatePointLayer(_stopSource, 'RouteStopsLayer');
      _stopLayer.set('direction', x.direction);
      this.map.addLayer(_stopLayer);
    })
  }
  drawLine(directiveNeeds: any) {
    directiveNeeds.map((x, index) => {
      let _routeStyle = this.generateRouteStyle(x.color, x.strokeWeight);
      let _routeFeature = this.generateRouteFeature(x.result);
      _routeFeature.setStyle(_routeStyle);
      let _routeSource = this.generateVectorSource([_routeFeature]);
      let _routeLayer = this.generateRouteLayer("RouteLine", _routeSource);
      _routeLayer.set('direction', x.direction)
      let _pointLayer = this.getLayer('RouteStopsLayer');

      if (_pointLayer) {
        this.map.removeLayer(_pointLayer);
      }

      this.map.addLayer(_routeLayer);

      if (_pointLayer) {
        this.map.addLayer(_pointLayer);
      }
      if (index == directiveNeeds.length - 1) {
        this.panTo(this.convertCoordinatesToWGS([+x.result[0].logitude, +x.result[0].latitude]), x.zoom)
      }
    })
  }
  generateRouteLayer(name: string, source: VectorSource) {
    return new VectorLayer({
      name: name,
      source: source,
    })
  }
  generateRouteFeature(result) {
    const coordinates = this.generateCoordinateArray(result)
    return new Feature(new LineString(coordinates, 'XY'))
  }
  generateRouteStyle(color, strokeWeigth, lineDash = undefined) {
    return new Style({
      stroke: new Stroke({
        color: color,
        width: strokeWeigth,
        lineDash: lineDash
      })
    })
  }
  generateCoordinateArray(directionResult: any) {
    let _coordinateArray: any[] = [];
    directionResult.map(x => {
      _coordinateArray.push((this.convertCoordinatesToWGS([+x.logitude, +x.latitude])))
    })
    return _coordinateArray
  }
  clearMap(directiveNeeds: any) {
    const _vectorLayers: VectorLayer[] = this.map.getLayers().array_.filter(x => x.type == 'VECTOR');
    _vectorLayers.map(vectorLayer => {
      this.map.removeLayer(vectorLayer)
    })
    if (directiveNeeds != 'noAnimation') {
      this.panTo(this.convertCoordinatesToWGS(this.defaultCenter), this.defaultZoom)
    }
    this.hideOverlay();
  }
  showStopOnMap(showStopOnMap: ShowStopOnMapModel, hasAnimation = true, isSmartStop: boolean = false, layerName = 'StopLayer') {
    let _stopStyle = this.generatePointStyle(showStopOnMap.icon, undefined);
    let _stopFeature = this.generatePointFeature([+showStopOnMap.location.longitude, +showStopOnMap.location.latitude]);
    _stopFeature.setStyle(_stopStyle);
    if (isSmartStop) {
      _stopFeature.set('StopName', showStopOnMap.location.stationName);
      _stopFeature.set('isSmartStop', isSmartStop)
      layerName == 'StopLayer' ? _stopFeature.set('StopId', showStopOnMap.location.stationId) : _stopFeature.set('fillingCenterId', showStopOnMap.location.stationId)
    }
    let _stopSource = this.generateVectorSource([_stopFeature])
    let _stopLayer = this.generatePointLayer(_stopSource, layerName);
    this.map.addLayer(_stopLayer);
  }
  generateVectorSource(features: Feature[]) {
    return new VectorSource({ features: features })
  }
  generatePointLayer(vectorSource: VectorSource, layerName: string) {
    return new VectorLayer({
      source: vectorSource,
      name: layerName
    })
  }
  generatePointFeature(coordinates: [number, number]) {
    if (typeof coordinates[0] == 'string' || typeof coordinates[1]) {
      coordinates[0] = +coordinates[0];
      coordinates[1] = +coordinates[1];
    }
    return new Feature(new Point(this.convertCoordinatesToWGS(coordinates)));
  }

  generatePointStyle(src, img, color = undefined) {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.96],
        crossOrigin: 'anonymous',
        src: src,
        img: img,
        imgSize: img ? [img.width, img.height] : undefined,
        color: color,
      }),
    });
  }

  initContextMenu() {
    let contextmenuItems = [
      {
        text: 'Başlangıç Noktası',
        callback: this.addStartPointOnMap.bind(this)
      },
      {
        text: 'Bitiş Noktası',
        callback: this.addEndPointOnMap.bind(this)
      },
    ];

    // CONTEXTITEMS A EKLEYEREK YENI BUTONLAR OLUSTURABILIRSIN
    this.contextMenu = new ContextMenu({
      width: 180,
      defaultItems: false,
      items: contextmenuItems,
    });
    this.map.addControl(this.contextMenu);
    this.contextMenu.on('open', (evt) => {
    });
  }

  addStartPointOnMap(item) {
    let _directiveNeeds = {
      icon: this.startIcon,
      coordinates: this.convertWGSToCoordinates(item.coordinate),
      StopId: null,
      StopTitle: "Başlangıç Noktası",
      zoom: this.map.getView().getZoom(),
      pointOrder: 'startPoint'
    }
    this.showHowToGoPoint(_directiveNeeds, _directiveNeeds.pointOrder);
    this.mapCommunicationService.addDirective({
      directive: 'rightClickStart',
      directiveNeeds: {
        coords: { lat: _directiveNeeds.coordinates[1], lng: _directiveNeeds.coordinates[0] },
        placeId: undefined
      }
    })
  }

  addEndPointOnMap(item) {
    let _directiveNeeds = {
      icon: this.endIcon,
      coordinates: this.convertWGSToCoordinates(item.coordinate),
      StopId: null,
      StopTitle: "Bitiş Noktası",
      zoom: this.map.getView().getZoom(),
      pointOrder: 'endPoint'
    }
    this.showHowToGoPoint(_directiveNeeds, _directiveNeeds.pointOrder);
    this.mapCommunicationService.addDirective({
      directive: 'rightClickEnd',
      directiveNeeds: {
        coords: { lat: _directiveNeeds.coordinates[1], lng: _directiveNeeds.coordinates[0] },
        placeId: undefined
      }
    })
  }

  addPointerMoveToMap() {
    this.map.on('pointermove', event => {

      // harita cursor'u default değere çekiyor.
      if (event) {
        this.map.getViewport().style.cursor = '';
      }

      this.map.forEachFeatureAtPixel(event.pixel, (f, l) => {
        if ((f.get('plate') || f.get('StopName') || f.get('isSmartStop'))) {
          this.map.getViewport().style.cursor = 'pointer';
        }
      })
    })
  }

  hideOverlay() {
    // Hide existing popup and reset it's offset
    this.overlay.setPosition(undefined);
    this._popupCloser.blur();
    this.addPointerMoveToMap();
    return false;
  }
  isSmartStop;
  initPopup() {
    // Add an event handler for the map "singleclick" event
    const _self = this;
    this.map.on('click', function (evt) {
      // Attempt to find a feature in one of the visible vector layers
      let _coordinate = evt.coordinate;

      _self.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        if ((feature.get('plate') || feature.get('StopName'))) {
          _coordinate = feature.getGeometry().getCoordinates();

          if (feature.get('StopName') && !feature.get('isSmartStop')) {
            _self._popupContainer.style.backgroundColor = '#404040';
            _self._popupContainer.style.color = 'white';
            _self.stopInfo = feature.get('StopName');
            _self.busInfo = null;
          }
          if (feature.get('plate')) {
            _self._popupContainer.style.backgroundColor = '#ebebeb';
            _self._popupContainer.style.color = '#404040';
            let _plate = _self.stopInfo = feature.get('plate');
            _self.stopInfo = null;
            _self.busInfo = _self.busInfoList.find(bus => bus.plaka == _plate);
          }

          if (feature.get('isSmartStop')) {
            _self._popupContainer.style.backgroundColor = '#404040';
            _self._popupContainer.style.color = 'white';
            _self.isSmartStop = feature.get('isSmartStop')
            _self.busInfo = null;
            _self.stopInfo = null;
            _self.stopName = feature.get('StopName');

            let hasFillingCenterLayer: boolean = _self.hasLayer('FillingCenter');

            if (hasFillingCenterLayer) {
              let userCoordinate = _self.convertCoordinatesToWGS(_self.userLocation)
              const line = new LineString([userCoordinate, _coordinate]);
              const _distance = getLength(line);
              _self.distance = _self.yuvarla(_distance / 1000, 2)
              _self.fillingCenterId = feature.get('fillingCenterId');
            }
            else {
              _self.stopId = feature.get('StopId');
            }
          }
          _self.overlay.setPosition(_coordinate);
        }
      });
    });
  }

  flyTo(map, zoom = undefined, location, done) {
    const view = map.getView();
    let duration = 3000;
    let parts = 2;
    let called = false;

    function callback(complete) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        done(complete);
      }
    }

    view.animate({
      center: location,
      duration: duration
    }, callback);
    view.animate({
      zoom: (zoom ? zoom : view.getZoom()) - 1,
      duration: duration / 2
    }, {
      zoom: zoom ? zoom : view.getZoom(),
      duration: duration / 2
    }, callback);
  }

  panTo(center, zoom) {
    this.map.getView().animate({
      center: center,
      duration: 2000,
      zoom: zoom
    });
  }

  clickDetail() {
    this.mapCommunicationService.addDirective({
      directive: 'onClickStopDetail',
      directiveNeeds: this.stopId
    })
  }

  clickNavigation(id) {
    this.mapCommunicationService.addDirective({
      directive: 'onClickStopNavigation',
      directiveNeeds: id
    })
  }
  getFillingCenterIcon(value) {
    switch (value) {
      case "B":
        return '/assets/img/logo/card-shop-blue_pin_dealer.png';
      case "K":
        return '/assets/img/logo/KartMerkezleri.png';
      case "V":
        return '/assets/img/logo/card-shop-blue_pin_kiosk.png';
    }
  }

  yuvarla(sayi, basamak) {
    basamak = Math.pow(10, basamak);
    return Math.round(sayi * basamak) / basamak;
  }

}


