import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Token, FillingCenter2 } from '../shared/auth';
import { Observable } from 'rxjs';
import { station, stationRoutes, nearStations, nearStationsParameters, smartStations } from '../shared/classes/smartstop';

@Injectable({
  providedIn: 'root'
})
export class SmartstopService {
  constructor(private httpClient: HttpClient, @Inject("url") private url: string, @Inject("local") private localUrl: string) { }

  GetAll(param: nearStationsParameters): Observable<nearStations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<nearStations>(this.localUrl + "smartstop/nearstations", param, { headers: headers });

  }

  GetSmartStationDataService(StopId: number): Observable<smartStations> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<smartStations>(this.localUrl + "static/stationremainingtime", {"stopId": StopId}, { headers: headers });
  }

  GetSmartStationRoutes(StopId: number): Observable<stationRoutes> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post<stationRoutes>(this.localUrl + "smartstop/stationroutes", {"stationId": StopId}, { headers: headers });
  }

}
